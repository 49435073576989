import {apiUrl} from '../helpers'
export const venta_promocionService = {
    guardar,
    actualizar,
    getAll,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/merchandising/v1/venta_promocion/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/merchandising/v1/venta_promocion/obtener/${id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    // formData.token = user.access_token;
    // formData.empleado_id = user.id;
    console.log(formData);
    let Data = new FormData();
    Data.append('empleado_id',user.id);
    Data.append('cadena',formData.cadena);
    Data.append('tienda',formData.tienda);
    Data.append('marca',formData.marca);
    Data.append('categoria',formData.categoria);
    Data.append('subcategoria',formData.subcategoria);
    Data.append('item',formData.item);
    Data.append('producto',formData.producto);
    Data.append('tamano',formData.tamano);
    if(formData.precio){
        Data.append('precio',formData.precio);
    }
    if(formData.campana !== undefined){
        Data.append('campana',formData.campana);
    }
    if(formData.promocional_descripcion !== undefined){
        Data.append('promocional_descripcion',formData.promocional_descripcion);
    }
    if(formData.producto !== undefined){
        Data.append('producto',formData.producto);
    }
    if(formData.cantidad_entrega !== null){
        Data.append('cantidad_entrega',formData.cantidad_entrega);
    }
    Data.append('modelo',formData.modelo);
    Data.append('cantidad',formData.cantidad);
    Data.append('factura',formData.factura);
    Data.append('observaciones',formData.observaciones);
    Data.append('promocional',formData.promocional ? 1 : 0);
    Data.append('observaciones_entrega',formData.observaciones_entrega);
    Data.append('latitud',formData.latitud);
    Data.append('longitud',formData.longitud);
    if(formData.fotografia){
        Data.append('fotografia',formData.fotografia);
    }
    if(formData.fotografia_entrega){
        Data.append('fotografia_entrega',formData.fotografia_entrega);
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/venta_promocion/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('id',id);
    Data.append('empleado_id',user.id);
    Data.append('cadena',formData.cadena);
    Data.append('tienda',formData.tienda);
    Data.append('marca',formData.marca);
    Data.append('categoria',formData.categoria);
    Data.append('subcategoria',formData.subcategoria);
    Data.append('item',formData.item);
    Data.append('producto',formData.producto);
    Data.append('tamano',formData.tamano);
    if(formData.precio){
        Data.append('precio',formData.precio);
    }
    if(formData.campana!== undefined){
        Data.append('campana',formData.campana);
    }
    if(formData.promocional_descripcion !== undefined){
        Data.append('promocional_descripcion',formData.promocional_descripcion);
    }
    if(formData.producto !== undefined){
        Data.append('producto',formData.producto);
    }
    if(formData.cantidad_entrega !== null){
        Data.append('cantidad_entrega',formData.cantidad_entrega);
    }
    Data.append('modelo',formData.modelo);
    Data.append('cantidad',formData.cantidad);
    Data.append('factura',formData.factura);
    Data.append('observaciones',formData.observaciones);
    Data.append('promocional',formData.promocional ? 1 : 0);
    Data.append('observaciones_entrega',formData.observaciones_entrega);
    if(formData.fotografia && formData.fotografia.name !== undefined){
        Data.append('fotografia',formData.fotografia);
    }
    if(formData.fotografia_entrega && formData.fotografia_entrega.name !== undefined){
        Data.append('fotografia_entrega',formData.fotografia_entrega);
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/venta_promocion/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}