import { venta_promocionConstants } from '../constants';
import { venta_promocionService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const venta_promocionActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        venta_promocionService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/venta_promocion/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(venta_promocion) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_REQUEST, venta_promocion } }
    function success(venta_promocion) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_SUCCESS, venta_promocion } }
    function failure(error) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        venta_promocionService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/venta_promocion/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(venta_promocion) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_REQUEST, venta_promocion } }
    function success(venta_promocion) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_SUCCESS, venta_promocion } }
    function failure(error) { return { type: venta_promocionConstants.SAVE_VENTA_PROMOCION_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        venta_promocionService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: venta_promocionConstants.FETCH_VENTA_PROMOCION_REQUEST, data } }
    function success(data) { return { type: venta_promocionConstants.FETCH_VENTA_PROMOCION_SUCCESS, data } }
    function failure(error) { return { type: venta_promocionConstants.FETCH_VENTA_PROMOCION_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        venta_promocionService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: venta_promocionConstants.GET_VENTA_PROMOCION_REQUEST, data } }
    function success(data) { return { type: venta_promocionConstants.GET_VENTA_PROMOCION_SUCCESS, data } }
    function failure(error) { return { type: venta_promocionConstants.GET_VENTA_PROMOCION_FAILURE, error } }
}