import { certificadoigssConstants } from '../constants';
import { certificadoigssService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const certificadoigssActions = {
    guardar,
    actualizar,
    getAll,
    getMotivos,
    getCertificados,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        certificadoigssService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/recursoshumanos/certificadoigss/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(certificadoigss) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_REQUEST, certificadoigss } }
    function success(certificadoigss) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_SUCCESS, certificadoigss } }
    function failure(error) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        certificadoigssService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.certificado));
                        history.push(`/recursoshumanos/certificadoigss`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(certificadoigss) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_REQUEST, certificadoigss } }
    function success(certificadoigss) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_SUCCESS, certificadoigss } }
    function failure(error) { return { type: certificadoigssConstants.SAVE_CERTIFICADOIGSS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        certificadoigssService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: certificadoigssConstants.FETCH_CERTIFICADOIGSS_REQUEST, data } }
    function success(data) { return { type: certificadoigssConstants.FETCH_CERTIFICADOIGSS_SUCCESS, data } }
    function failure(error) { return { type: certificadoigssConstants.FETCH_CERTIFICADOIGSS_FAILURE, error } }
}

function getMotivos() {
    return dispatch => {
        dispatch(request({}));

        certificadoigssService.getMotivos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: certificadoigssConstants.FETCH_MOTIVOS_REQUEST, data } }
    function success(data) { return { type: certificadoigssConstants.FETCH_MOTIVOS_SUCCESS, data } }
    function failure(error) { return { type: certificadoigssConstants.FETCH_MOTIVOS_FAILURE, error } }
}

function getCertificados() {
    return dispatch => {
        dispatch(request({}));

        certificadoigssService.getCertificados()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: certificadoigssConstants.FETCH_CERTIFICADOS_REQUEST, data } }
    function success(data) { return { type: certificadoigssConstants.FETCH_CERTIFICADOS_SUCCESS, data } }
    function failure(error) { return { type: certificadoigssConstants.FETCH_CERTIFICADOS_FAILURE, error } }
}


function getOne(certificadoigss) {
    return dispatch => {
        dispatch(request());

        certificadoigssService.getOne(certificadoigss)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: certificadoigssConstants.GET_CERTIFICADOIGSS_REQUEST, data } }
    function success(data) { return { type: certificadoigssConstants.GET_CERTIFICADOIGSS_SUCCESS, data } }
    function failure(error) { return { type: certificadoigssConstants.GET_CERTIFICADOIGSS_FAILURE, error } }
}