// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.data.asunto}
          </td>
          <td>
            {this.props.data.fecha_inicio}
          </td>
          <td>
            {this.props.data.fecha_fin}
          </td>
          <td>
            {
              this.props.data.ver === 1 &&
              <Link to={{pathname:"/finanzas/salario/ver", state:{id:this.props.data.id}}} id={this.props.data.id} className="btn btn-primary">Ver</Link>
            }
          </td>
        </tr>
    );
  }
}

export default TableRow;