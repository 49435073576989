import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { actividadesActions, alertActions } from '../../actions'
import { connect } from 'react-redux'
import Carousel from '../../components/carousel/Carousel'
import EncuestaForm from './EncuestaForm'
import { actividadesService } from '../../services'
import { apiUrl } from '../../helpers'
import ReactPlayer from "react-player"
class ActividadesDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            comentario: "",
            comentarios: {},
        }
        this.props.dispatch(actividadesActions.getOne(this.props.location.state.id));
        this.props.dispatch(actividadesActions.getComentarios(this.props.location.state.id));
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.loadComents = this.loadComents(this);
    }
    createMarkup(html) {
        return {__html: html};
    }

    handleChange(evt) {
        this.setState({comentario: evt.target.value});
    }

    handleSubmit(evt){
        if(this.state.comentario!== ""){
            let Data = new FormData();
            Data.append('blog_id',this.props.location.state.id);
            Data.append('comentario',this.state.comentario);
            this.props.dispatch(actividadesActions.guardarComentario(Data));
            this.props.dispatch(actividadesActions.getComentarios(this.props.location.state.id));
            this.setState({comentario:''});
            this.loadComents(this.state.comentarios.first_page_url);
        }
        evt.preventDefault();
    }

    componentDidMount(){
        let {dispatch} = this.props;
        actividadesService.getComentarios(this.props.location.state.id,0,10).then(
            response => {
                if(response.status){
                    this.setState({comentarios: response.data});
                }else{
                    dispatch(alertActions.error(response.mensaje));
                }
            }, error => {
                dispatch(alertActions.error(error));
            }
        );
    }

    loadComents(url){
        let {dispatch} = this.props;
        actividadesService.getComentariosURL(url).then(
            response => {
                if(response.status){
                    this.setState({comentarios: response.data});
                }else{
                    dispatch(alertActions.error(response.mensaje));
                }
            }, error => {
                dispatch(alertActions.error(error));
            }
        )
    }

    tabRow(data){
        if(data instanceof Array){
            return data.map(function(object, i){
                return <div className="row" key={i} >
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-4">
                                <img height='50px'  alt="profile img" className="border rounded-circle img-profile" src={(object.usuario.fotografia !== undefined && object.usuario.fotografia !== null && object.usuario.fotografia !== '') ? apiUrl + 'storage/uploads/images/'+object.usuario.fotografia:'/assets/img/user.png'} />
                            </div>
                            <div className="col-md-8">
                                <p>
                                    {object.usuario.nombre} {object.usuario.apellido}
                                </p>
                                <p>
                                    Fecha: {object.created_at}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {object.comentario}
                    </div>
                </div>;
            })
        }
    }
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Actividades</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row pb-3">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/desarrollohumano/actividades"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                        {!this.props.loading  && 
                                        <div className="">
                                            <div className="row pb-3">
                                                    <div className="col-md-8 col-12">
                                                        <h3>{this.props.actividad.actividad ? this.props.actividad.actividad.nombre : ''}</h3>
                                                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.actividad.actividad ? this.props.actividad.actividad.descripcion : '')} />
                                                        <div>
                                                            {this.props.actividad.actividad !== undefined && this.props.actividad.actividad.youtube !== undefined &&  <ReactPlayer url={this.props.actividad.actividad.youtube} />}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <Carousel data={this.props.actividad.actividad ? this.props.actividad.actividad.galeria : '[]'} extra={this.props.actividad.actividad ? this.props.actividad.actividad.imagen: ''}/>
                                                    </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-md-12 col-12">
                                                    {this.props.actividad.encuesta && this.props.actividad.encuesta.respondido === 0 && <EncuestaForm preguntas={this.props.actividad.preguntas} actividad={this.props.actividad.actividad.id} encuesta={this.props.actividad.encuesta}/>}
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <h3>Comentarios</h3>
                                                {
                                                    this.state.comentarios !== undefined && this.state.comentarios.data !== undefined &&
                                                    <div>
                                                        {this.tabRow(this.state.comentarios.data)}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <button className="btn btn-primary" disabled={this.state.comentarios.current_page !== 1 ? false:true} onClick={this.loadComents.bind(this,this.state.comentarios.prev_page_url)}> Recientes </button>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <button className="btn btn-primary" disabled={this.state.comentarios.current_page !== this.state.comentarios.last_page ? false:true} onClick={this.loadComents.bind(this,this.state.comentarios.next_page_url)}> Antiguos </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div style={{'marginTop':'1em'}}>
                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className="row">
                                                                <div className="col-md-9">
                                                                    <textarea className="form-control" placeholder="Escribe tu comentario aquí..." onChange={this.handleChange} value={this.state.comentario} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <button className="btn btn-primary">Enviar</button>
                                                                </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { actividades} = state;
    return {
        actividad: actividades.actividad,
        loading: actividades.loading,
        comentarios: actividades.comentarios,
    };
}
ActividadesDisplay = connect(mapStateToProps)(ActividadesDisplay);
export {ActividadesDisplay};