import { btlConstants } from '../constants';
import { btlService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const btlActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        btlService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/btl/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(btl) { return { type: btlConstants.SAVE_BTL_REQUEST, btl } }
    function success(btl) { return { type: btlConstants.SAVE_BTL_SUCCESS, btl } }
    function failure(error) { return { type: btlConstants.SAVE_BTL_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        btlService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/btl/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(btl) { return { type: btlConstants.SAVE_BTL_REQUEST, btl } }
    function success(btl) { return { type: btlConstants.SAVE_BTL_SUCCESS, btl } }
    function failure(error) { return { type: btlConstants.SAVE_BTL_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        btlService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: btlConstants.FETCH_BTL_REQUEST, data } }
    function success(data) { return { type: btlConstants.FETCH_BTL_SUCCESS, data } }
    function failure(error) { return { type: btlConstants.FETCH_BTL_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        btlService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: btlConstants.GET_MONITOREO_REQUEST, data } }
    function success(data) { return { type: btlConstants.GET_MONITOREO_SUCCESS, data } }
    function failure(error) { return { type: btlConstants.GET_MONITOREO_FAILURE, error } }
}