import { salariosConstants } from '../constants';

export function salarios(state = {salarios: [], salario:[] ,loading:false}, action) {
  switch (action.type) {
    case salariosConstants.FETCH_SALARIO_REQUEST:
      return {
        ...state,
        loading: true,
        salarios: [],
      };
    case salariosConstants.FETCH_SALARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        salarios: action.data,
      };
    case salariosConstants.FETCH_SALARIO_FAILURE:
      return { 
        error: action.error
      };
    case salariosConstants.GET_SALARIO_REQUEST:
      return {
        ...state,
        loading: true,
        salario: [],
      };
    case salariosConstants.GET_SALARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        salario: action.data,
      };
    case salariosConstants.GET_SALARIO_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}