import React, { useState } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import './Buzon.css'
import { buzonService } from '../../services'
import { connect } from 'react-redux'
import { alertActions } from '../../actions'

var Buzon = (props) => {
    const [asunto, setAsunto] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [attachment, setAttachment] = useState();
    
    const handleSubmit = (evt) => {
        evt.preventDefault();
        const {dispatch} = props;
        let Data = new FormData();
        Data.append('asunto', asunto);
        Data.append('mensaje', mensaje);
        if(attachment !== undefined){
            Data.append('attachment',attachment);
        }
        buzonService.guardar(Data).then( 
            respose => {
                if(respose.status){
                    setAsunto('');
                    setMensaje('');
                    setAttachment();
                    dispatch(alertActions.success(respose.mensaje));
                }

            }, error => {
                dispatch(alertActions.success(error));
            } 
        );
    }

    return (
        <div id="wrapper">
            <Sidebar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <Alert />
                        <div className="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 className="text-dark mb-0">Buzón de Sugerencias</h3>
                        </div>
                        <div className="row justify-content-md-center">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-10"></div>
                                    <div className="col-md-2">
                                        <Link to="/desarrollohumano"  className="btn btn-success">Regresar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-3 col-12">* Asunto:</div>
                                        <div className="col-md-9 col-12"> <input className="form-control" name="asunto" value={asunto} onChange={event => setAsunto(event.target.value)}/> </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12">* Mensaje:</div>
                                        <div className="col-md-9 col-12"> <textarea className="form-control" name="mensaje" value={mensaje} onChange={event => setMensaje(event.target.value)}/> </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12">Archivo Adjunto:</div>
                                        <div className="col-md-9 col-12"> <input type="file"  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" className="form-control" name="attachment" onChange={event => setAttachment(event.target.files[0])}/> </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 offset-md-9">
                                            <button type="submit" className="btn btn-success">Enviar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
            <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
}

function mapStateToProps(state) {
    const { alert} = state;
    return {
        alert
    };
}
Buzon = connect(mapStateToProps)(Buzon);
export {Buzon} 