// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {

  createMarkup(html) {
    return {__html: html};
  }
  render() {
    return (
        <tr>
          <td>
            {this.props.data.id}
          </td>
          <td>
            {this.props.data.nombre}
          </td>
          <td>
            {this.props.data.fecha_inicio}
          </td>
          <td>
            {this.props.data.fecha_fin}
          </td>
          <td>
            <Link to={"/recursoshumanos/encuestas/ver/"+this.props.data.id} id={this.props.data.id} className="btn btn-primary">Ver</Link>
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;