import { vacacionesConstants } from '../constants';
import { vacacionesService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const vacacionesActions = {
    guardar,
    actualizar,
    getAll,
    getMotivos,
    getTipos,
    getLicencias,
    getDias,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        vacacionesService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/recursoshumanos/vacaciones/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(vacaciones) { return { type: vacacionesConstants.SAVE_VACACIONES_REQUEST, vacaciones } }
    function success(vacaciones) { return { type: vacacionesConstants.SAVE_VACACIONES_SUCCESS, vacaciones } }
    function failure(error) { return { type: vacacionesConstants.SAVE_VACACIONES_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        vacacionesService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.vacaciones));
                        history.push(`/recursoshumanos/vacaciones/actualizar/${response.vacaciones.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(vacaciones) { return { type: vacacionesConstants.SAVE_VACACIONES_REQUEST, vacaciones } }
    function success(vacaciones) { return { type: vacacionesConstants.SAVE_VACACIONES_SUCCESS, vacaciones } }
    function failure(error) { return { type: vacacionesConstants.SAVE_VACACIONES_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        vacacionesService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.FETCH_VACACIONES_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.FETCH_VACACIONES_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.FETCH_VACACIONES_FAILURE, error } }
}

function getMotivos() {
    return dispatch => {
        dispatch(request({}));

        vacacionesService.getMotivos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.FETCH_MOTIVOS_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.FETCH_MOTIVOS_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.FETCH_MOTIVOS_FAILURE, error } }
}

function getTipos() {
    return dispatch => {
        dispatch(request({}));

        vacacionesService.getTipos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.FETCH_TIPOS_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.FETCH_TIPOS_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.FETCH_TIPOS_FAILURE, error } }
}

function getLicencias() {
    return dispatch => {
        dispatch(request({}));

        vacacionesService.getLicencias()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.FETCH_TIPOS_LICENCIA_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.FETCH_TIPOS_LICENCIA_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.FETCH_TIPOS_LICENCIA_FAILURE, error } }
}


function getDias() {
    return dispatch => {
        dispatch(request({}));

        vacacionesService.getDias()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.dias_disponibles));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.FETCH_DIAS_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.FETCH_DIAS_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.FETCH_DIAS_FAILURE, error } }
}



function getOne(vacaciones) {
    return dispatch => {
        dispatch(request());

        vacacionesService.getOne(vacaciones)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: vacacionesConstants.GET_VIATICO_REQUEST, data } }
    function success(data) { return { type: vacacionesConstants.GET_VIATICO_SUCCESS, data } }
    function failure(error) { return { type: vacacionesConstants.GET_VIATICO_FAILURE, error } }
}