import { solicitudirtraConstants } from '../constants';

export function solicitudirtra(state = {solicitudes: [], tramites: [] ,loading:true}, action) {
  switch (action.type) {
    case solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_REQUEST:
      return {
        loading: true,
        solicitudes: [],
        tramites: state.tramites
      };
    case solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_SUCCESS:
      return {
        loading: false,
        solicitudes: action.data,
        tramites: state.tramites
      };
    case solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_FAILURE:
      return { 
        error: action.error
      };
    case solicitudirtraConstants.FETCH_TRAMITES_REQUEST:
      return {
        loading: true,
        tramites: [],
        solicitudes: state.solicitudes
      };
    case solicitudirtraConstants.FETCH_TRAMITES_SUCCESS:
      return {
        loading: false,
        tramites: action.data,
        solicitudes: state.solicitudes
      };
    case solicitudirtraConstants.FETCH_TRAMITES_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}