
import React from 'react';
import { apiUrl } from '../../helpers';
import './Carousel.css'
class Carousel extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }
    componentDidMount(){
        let data = [];
        if(this.props.extra !== ""){
            data.push(this.props.extra);
        }
        if(this.props.data !== undefined && this.props.data !== null){
            let gal = JSON.parse(this.props.data);
            data = data.concat(gal);
        }
        this.setState({data:data});
    }
    render() {
        return (

            <div id="galeria" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    {(this.state.data instanceof Array)?(
                        this.state.data.map(function(object, i){
                            return <li data-target="#galeria" key={"slide"+i} data-slide-to={i} className={(i===0)?'active':''}></li>;
                        })
                    ):(
                        ""
                    )}
                </ol>

                <div className="carousel-inner">
                {(this.state.data instanceof Array)?(
                        this.state.data.map(function(object, i){
                            return (
                                <div className={(i===0)?"carousel-item active":"carousel-item"} key={"img"+i}>
                                    <img className="d-block w-100" src={apiUrl+"storage/uploads/images/"+object} alt={i}/>
                                </div>
                            );
                        })
                    ):(
                        ""
                )}
                </div>
                <a className="carousel-control-prev" href="#galeria" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#galeria" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            // <div>
            //     {this.tabRow()}
            // </div>
        );
    }
}

export default Carousel;