import { encuestasConstants } from '../constants';

export function encuestas(state = {encuestas: [], encuesta: {}, preguntas: [], loading:false}, action) {
  switch (action.type) {
    case encuestasConstants.FETCH_ENCUESTAS_REQUEST:
      return {
        ...state,
        loading: true,
        encuestas: [],
      };
    case encuestasConstants.FETCH_ENCUESTAS_SUCCESS:
      return {
        ...state,
        loading: false,
        encuestas: action.data,
      };
    case encuestasConstants.FETCH_ENCUESTAS_FAILURE:
      return { 
        error: action.error
      };
    case encuestasConstants.GET_ENCUESTA_REQUEST:
      return {
        ...state,
        loading: true,
        encuesta: {},
        preguntas: [],
      };
    case encuestasConstants.GET_ENCUESTA_SUCCESS:
      return {
        ...state,
        loading: false,
        encuesta: action.data.encuesta,
        preguntas: action.data.preguntas,
      };
    case encuestasConstants.GET_ENCUESTA_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}