import React from "react";
import Tree from "react-d3-tree";

// const debugData = [
//   {
//     name: "1",
//     children: [
//       {
//         name: "2"
//       },
//       {
//         name: "2"
//       }
//     ]
//   }
// ];

const containerStyles = {
  width: '100%',
  height: '100vh',
}

export default class CenteredTreeGraph extends React.PureComponent {
  state = {}

  componentDidMount() {
    const dimensions = this.treeContainer.getBoundingClientRect();
    this.setState({
      translate: {
        x: dimensions.width / 2,
        y: dimensions.height / 2
      }
    });
  }

  render() {
    return (
      <div style={containerStyles}  ref={tc => (this.treeContainer = tc)}>
        <Tree 
          data={this.props.data} 
          translate={this.state.translate} 
          orientation={'vertical'}
          nodeSvgShape={{shape: 'rect',shapeProps: {
            width: 20,
            height: 20,
            x: -10,
            y: -10,
          }}}
        />
      </div>
    );
  }
}
