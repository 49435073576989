import { reposicionherramientaConstants } from '../constants';
import { reposicionherramientaService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const reposicionherramientaActions = {
    guardar,
    actualizar,
    getAll,
    getTipos,
    getMotivos,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        reposicionherramientaService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/seguridadcorporativa/reposicionherramienta/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reposicionherramienta) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_REQUEST, reposicionherramienta } }
    function success(reposicionherramienta) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_SUCCESS, reposicionherramienta } }
    function failure(error) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        reposicionherramientaService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.reposicionherramienta));
                        history.push(`/seguridadcorporativa/reposicionherramienta/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reposicionherramienta) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_REQUEST, reposicionherramienta } }
    function success(reposicionherramienta) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_SUCCESS, reposicionherramienta } }
    function failure(error) { return { type: reposicionherramientaConstants.SAVE_REPOSICIONHERRAMIENTA_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        reposicionherramientaService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_REQUEST, data } }
    function success(data) { return { type: reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_SUCCESS, data } }
    function failure(error) { return { type: reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_FAILURE, error } }
}

function getTipos() {
    return dispatch => {
        dispatch(request({}));

        reposicionherramientaService.getTipos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_REQUEST, data } }
    function success(data) { return { type: reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_SUCCESS, data } }
    function failure(error) { return { type: reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_FAILURE, error } }
}

function getMotivos() {
    return dispatch => {
        dispatch(request({}));

        reposicionherramientaService.getMotivos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: reposicionherramientaConstants.FETCH_MOTIVO_REQUEST, data } }
    function success(data) { return { type: reposicionherramientaConstants.FETCH_MOTIVO_SUCCESS, data } }
    function failure(error) { return { type: reposicionherramientaConstants.FETCH_MOTIVO_FAILURE, error } }
}

function getOne(reposicionherramienta) {
    return dispatch => {
        dispatch(request());

        reposicionherramientaService.getOne(reposicionherramienta)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: reposicionherramientaConstants.GET_REPOSICIONHERRAMIENTA_REQUEST, data } }
    function success(data) { return { type: reposicionherramientaConstants.GET_REPOSICIONHERRAMIENTA_SUCCESS, data } }
    function failure(error) { return { type: reposicionherramientaConstants.GET_REPOSICIONHERRAMIENTA_FAILURE, error } }
}