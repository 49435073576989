import { solicitudirtraConstants } from '../constants';
import { solicitudirtraService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const solicitudirtraActions = {
    guardar,
    actualizar,
    getAll,
    getTramites,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        solicitudirtraService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/recursoshumanos/solicitudirtra/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(solicitudirtra) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_REQUEST, solicitudirtra } }
    function success(solicitudirtra) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_SUCCESS, solicitudirtra } }
    function failure(error) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        solicitudirtraService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.certificado));
                        history.push(`/recursoshumanos/solicitudirtra`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(solicitudirtra) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_REQUEST, solicitudirtra } }
    function success(solicitudirtra) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_SUCCESS, solicitudirtra } }
    function failure(error) { return { type: solicitudirtraConstants.SAVE_SOLICITUDESIRTRA_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        solicitudirtraService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_REQUEST, data } }
    function success(data) { return { type: solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_SUCCESS, data } }
    function failure(error) { return { type: solicitudirtraConstants.FETCH_SOLICITUDESIRTRA_FAILURE, error } }
}

function getTramites() {
    return dispatch => {
        dispatch(request({}));

        solicitudirtraService.getTramites()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicitudirtraConstants.FETCH_TRAMITES_REQUEST, data } }
    function success(data) { return { type: solicitudirtraConstants.FETCH_TRAMITES_SUCCESS, data } }
    function failure(error) { return { type: solicitudirtraConstants.FETCH_TRAMITES_FAILURE, error } }
}


function getOne(solicitudirtra) {
    return dispatch => {
        dispatch(request());

        solicitudirtraService.getOne(solicitudirtra)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicitudirtraConstants.GET_SOLICITUDESIRTRA_REQUEST, data } }
    function success(data) { return { type: solicitudirtraConstants.GET_SOLICITUDESIRTRA_SUCCESS, data } }
    function failure(error) { return { type: solicitudirtraConstants.GET_SOLICITUDESIRTRA_FAILURE, error } }
}