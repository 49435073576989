export const solicitudirtraConstants = {
    SAVE_SOLICITUDESIRTRA_REQUEST: 'SAVE_SOLICITUDESIRTRA_REQUEST',
    SAVE_SOLICITUDESIRTRA_SUCCESS: 'SAVE_SOLICITUDESIRTRA_SUCCESS',
    SAVE_SOLICITUDESIRTRA_FAILURE: 'SAVE_SOLICITUDESIRTRA_FAILURE',

    FETCH_SOLICITUDESIRTRA_REQUEST: 'FETCH_SOLICITUDESIRTRA_REQUEST',
    FETCH_SOLICITUDESIRTRA_SUCCESS: 'FETCH_SOLICITUDESIRTRA_SUCCESS',
    FETCH_SOLICITUDESIRTRA_FAILURE: 'FETCH_SOLICITUDESIRTRA_FAILURE',


    FETCH_TRAMITES_REQUEST: 'FETCH_TRAMITES_REQUEST',
    FETCH_TRAMITES_SUCCESS: 'FETCH_TRAMITES_SUCCESS',
    FETCH_TRAMITES_FAILURE: 'FETCH_TRAMITES_FAILURE',

    GET_SOLICITUDESIRTRA_REQUEST: 'GET_SOLICITUDESIRTRA_REQUEST',
    GET_SOLICITUDESIRTRA_SUCCESS: 'GET_SOLICITUDESIRTRA_SUCCESS',
    GET_SOLICITUDESIRTRA_FAILURE: 'GET_SOLICITUDESIRTRA_FAILURE',

};
