import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { viaticosService } from '../../services'
import { apiUrl } from '../../helpers'

class ViaticosDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            viaticos: {},
            loaded: false,
        }
        viaticosService.getOne(this.props.match.params.id).then(
            response => {
                if(response.status){
                    this.setState({viaticos: response.viatico, loaded: true});
                }else{
                }
            },
        );
    }

    subirConstancia(e) {

        let Data = new FormData();
        Data.append('id',this.props.match.params.id);
        if(e.target.files[0]){
            Data.append('archivo_firmado',e.target.files[0]);
        }
        this.setState({loaded:false});
        viaticosService.guardarArchivo(Data).then(response => {
            if(response.status){
                this.setState({viaticos: response.viatico, loaded: true});
                
            }else{
                this.setState({viaticos: {}, loaded: false});
            }
        },
        error => {
            console.log(error)
        });
    }

    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Viaticos</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/finanzas/viaticos"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                    { this.state.loaded &&
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <h4>Lugar a visitar</h4>
                                            <p>{this.state.viaticos.motivo}</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <p>Lugar a visitar: {this.state.viaticos.lugar_visitar} </p>
                                            <p>Viaje presupuestado: {this.state.viaticos.viaje_presupuestado ? 'Sí' : 'No'} </p>
                                            <p>Forma Remota: {this.state.viaticos.forma_remota ? 'Sí' : 'No'} </p>
                                            { this.state.viaticos.forma_remota && <p>Explicación de forma remota: {this.state.viaticos.lugar_visitar} </p>}
                                            <p>Moneda: {this.state.viaticos.moneda} </p>
                                            <p>Transporte: {this.state.viaticos.transporte} </p>
                                            <p>Hospedaje: {this.state.viaticos.hospedaje} </p>
                                            <p>Alimentación: {this.state.viaticos.alimentacion} </p>
                                            <p>Otros: {this.state.viaticos.otros} </p>
                                            <p>Explicación de otros gastos: {this.state.viaticos.explique} </p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <p>Fecha de creado: {this.state.viaticos.created_at} </p>
                                            <p>Fecha de Salida: {this.state.viaticos.fecha_salida} </p>
                                            <p>Fecha de Regreso: {this.state.viaticos.fecha_llegada} </p>
                                            <p>Días: {this.state.viaticos.dias} </p>
                                        </div>
                                        <div className="col-md-6 col-12">

                                        {
                                            this.state.viaticos.acepta_admin && this.state.viaticos.acepta_supervisor && this.state.viaticos.archivo_por_firmar !== null &&
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <a href={apiUrl+`storage/uploads/images/${this.state.viaticos.archivo_por_firmar}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                                </div>
                                                {
                                                    this.state.viaticos.archivo_firmado &&
                                                    <div className="col-md-3">
                                                        <a href={apiUrl+`storage/uploads/images/${this.state.viaticos.archivo_firmado}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo firmado</a>
                                                    </div>
                                                }
                                                <div className="col-md-3">
                                                    <label htmlFor="archivo_firmado">Subir archivo para firmar</label>
                                                    <input type="file" id="archivo_firmado" name="archivo_firmado" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" onChange={this.subirConstancia.bind(this)} />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

export default ViaticosDisplay;