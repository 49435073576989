import { dataConstants } from '../constants';

export function users(state = {}, action) {
  switch (action.type) {
    case dataConstants.FETCH_LIQUIDACIONES_REQUEST:
      return {
        ...state,
        loading: true,
        liquidaciones: action.data
      };
    case dataConstants.FETCH_LIQUIDACIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        liquidaciones: action.data
      };
    case dataConstants.FETCH_LIQUIDACIONES_FAILURE:
      return { 
        ...state,
        errorLiquidaciones: action.error
      };
    
    case dataConstants.SAVE_LIQUIDACIONES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dataConstants.SAVE_LIQUIDACIONES_SUCCESS:
      return {
        ...state,
        items: action.data
      };
    case dataConstants.SAVE_LIQUIDACIONES_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    
    case dataConstants.SAVE_GASTOS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dataConstants.SAVE_GASTOS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case dataConstants.SAVE_GASTOS_FAILURE:
      return { 
        ...state,
        error: action.error
      };
  
    case dataConstants.GET_LIQUIDACION_REQUEST:
      return {
        ...state,
        loading: true,
        liquidacion:  state.liquidacion
      };
    case dataConstants.GET_LIQUIDACION_SUCCESS:
      return {
        ...state,
        liquidacion: action.data
      };
    case dataConstants.GET_LIQUIDACION_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case dataConstants.FETCH_CONCEPTOS_REQUEST:
      return {
        ...state,
        loading: true,
        conceptos: action.data
    };
    case dataConstants.FETCH_CONCEPTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        conceptos: action.data
    };
    case dataConstants.FETCH_CONCEPTOS_FAILURE:
      return { 
        ...state,
        errorLiquidaciones: action.error
    };

    case dataConstants.FETCH_TIPOSGASOLINA_REQUEST:
      return {
        ...state,
        loading: true,
        tipo_gasolina: action.data
    };
    case dataConstants.FETCH_TIPOSGASOLINA_SUCCESS:
      return {
        ...state,
        loading: false,
        tipo_gasolina: action.data
    };
    case dataConstants.FETCH_TIPOSGASOLINA_FAILURE:
      return { 
        ...state,
        errorLiquidaciones: action.error
    };

    case dataConstants.DELETE_GASTO_REQUEST:
      return {
        ...state,
        loading: true,
    };
    case dataConstants.DELETE_GASTO_SUCCESS:
      return {
        ...state,
        loading: false,
    };
    case dataConstants.DELETE_GASTO_FAILURE:
      return { 
        ...state,
        errorLiquidaciones: action.error
    };

    case dataConstants.FETCH_GASTOS_REQUEST:
      return {
        ...state,
        loading: true,
        gastos: action.data
    };
    case dataConstants.FETCH_GASTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        gastos: action.data
    };
    case dataConstants.FETCH_GASTOS_FAILURE:
      return { 
        ...state,
        errorLiquidaciones: action.error
    };
    default:
      return state
  }
}