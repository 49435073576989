import { promocional_materialConstants } from '../constants';
import { promocional_materialService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const promocional_materialActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        promocional_materialService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/promocional_material/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(promocional_material) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_REQUEST, promocional_material } }
    function success(promocional_material) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_SUCCESS, promocional_material } }
    function failure(error) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        promocional_materialService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/promocional_material/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(promocional_material) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_REQUEST, promocional_material } }
    function success(promocional_material) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_SUCCESS, promocional_material } }
    function failure(error) { return { type: promocional_materialConstants.SAVE_PROMOCIONAL_MATERIAL_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        promocional_materialService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_REQUEST, data } }
    function success(data) { return { type: promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_SUCCESS, data } }
    function failure(error) { return { type: promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        promocional_materialService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: promocional_materialConstants.GET_PROMOCIONAL_MATERIAL_REQUEST, data } }
    function success(data) { return { type: promocional_materialConstants.GET_PROMOCIONAL_MATERIAL_SUCCESS, data } }
    function failure(error) { return { type: promocional_materialConstants.GET_PROMOCIONAL_MATERIAL_FAILURE, error } }
}