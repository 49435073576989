import { cartaingresosConstants } from '../constants';
import { cartaingresosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const cartaingresosActions = {
    guardar,
    actualizar,
    getAll,
    getTramites,
    getBancos,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        cartaingresosService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/recursoshumanos/cartaingresos/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(cartaingresos) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_REQUEST, cartaingresos } }
    function success(cartaingresos) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_SUCCESS, cartaingresos } }
    function failure(error) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        cartaingresosService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.cartaingresos));
                        history.push(`/recursoshumanos/cartaingresos/actualizar/${response.cartaingresos.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(cartaingresos) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_REQUEST, cartaingresos } }
    function success(cartaingresos) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_SUCCESS, cartaingresos } }
    function failure(error) { return { type: cartaingresosConstants.SAVE_CARTAINGRESOS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        cartaingresosService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: cartaingresosConstants.FETCH_CARTAINGRESOS_REQUEST, data } }
    function success(data) { return { type: cartaingresosConstants.FETCH_CARTAINGRESOS_SUCCESS, data } }
    function failure(error) { return { type: cartaingresosConstants.FETCH_CARTAINGRESOS_FAILURE, error } }
}

function getTramites() {
    return dispatch => {
        dispatch(request({}));

        cartaingresosService.getTramites()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: cartaingresosConstants.FETCH_TRAMITES_REQUEST, data } }
    function success(data) { return { type: cartaingresosConstants.FETCH_TRAMITES_SUCCESS, data } }
    function failure(error) { return { type: cartaingresosConstants.FETCH_TRAMITES_FAILURE, error } }
}

function getBancos() {
    return dispatch => {
        dispatch(request({}));

        cartaingresosService.getBancos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: cartaingresosConstants.FETCH_BANCOS_REQUEST, data } }
    function success(data) { return { type: cartaingresosConstants.FETCH_BANCOS_SUCCESS, data } }
    function failure(error) { return { type: cartaingresosConstants.FETCH_BANCOS_FAILURE, error } }
}


function getOne(cartaingresos) {
    return dispatch => {
        dispatch(request());

        cartaingresosService.getOne(cartaingresos)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: cartaingresosConstants.GET_CARTAINGRESOS_REQUEST, data } }
    function success(data) { return { type: cartaingresosConstants.GET_CARTAINGRESOS_SUCCESS, data } }
    function failure(error) { return { type: cartaingresosConstants.GET_CARTAINGRESOS_FAILURE, error } }
}