import React, { Component } from 'react'
import { capacitacionActions } from '../../actions';
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';
import { dropdownsService, capacitacionService } from '../../services';
import getPermissions from '../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    capacitador_id: Yup.string()
        .required("Campo requerido"),
    tipo_id: Yup.string()
        .required("Campo requerido"),   
    tema_id: Yup.string()
        .required("Campo requerido"),
    medio_id: Yup.string()
        .required("Campo requerido"),
    asistencia_id: Yup.string()
        .nullable("Campo requerido"),
});

const emptyOption = { value: "", label: "" };
class CapacitacionForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            capacitacion: {
                capacitador_id: "",
                tipo_id: "",
                tema_id: "",
                medio_id: "",
                asistencia_id: "",
                lugar: "",
                fecha: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
            capacitadores: [],
            tipos: [],
            temas: [],
            medios: [],
            asistencias: [],
            prevTipo: "",

        };
        this.getTemas = this.getTemas.bind(this);

        dropdownsService.getAll('Capacitadores Merchandising').then(
            response => {
                if(response.status){
                    this.setState({capacitadores: response.data})
                }
            }
        );
        dropdownsService.getAll('Tipos Capacitación Merchandising').then(
            response => {
                if(response.status){
                    this.setState({tipos: response.data})
                }
            }
        );
        dropdownsService.getAll('Medios Merchandising').then(
            response => {
                if(response.status){
                    this.setState({medios: response.data})
                }
            }
        );
        dropdownsService.getAll('Asistencias Merchandising').then(
            response => {
                if(response.status){
                    this.setState({asistencias: response.data})
                }
            }
        );

    }
    getTemas(){
        dropdownsService.getChilds(this.state.prevTipo).then(
            response => {
                if(response.status){
                    this.setState({temas: response.data});
                }
            }
        );
    }
    componentDidMount(){  
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ 
                    capacitacion: {
                        ...this.state.capacitacion,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }
        if(this.props.id){
            let capacitacion_temp = this.state.capacitacion;
            capacitacionService.getOne(this.props.id).then(
                response => {
                    if(response.data.capacitador_id !== undefined){
                        for (const result of this.state.capacitadores) {
                            if(response.data.capacitador_id === result.value){
                                response.data.capacitador_id = result;
                            }
                        }
                    }
                    if(response.data.tipo_id !== undefined){
                        this.setState({prevTipo:response.data.tipo_id});
                        this.getTemas();
                        for (const result of this.state.tipos) {
                            if(response.data.tipo_id === result.value){
                                response.data.tipo_id = result;
                            }
                        }
                    }
                    if(response.data.medio_id !== undefined){
                        for (const result of this.state.medios) {
                            if(response.data.medio_id === result.value){
                                response.data.medio_id = result;
                            }
                        }
                    }
                    if(response.data.asistencia_id !== undefined){
                        for (const result of this.state.asistencias) {
                            if(response.data.asistencia_id === result.value){
                                response.data.asistencia_id = result;
                            }
                        }
                    }
                    if(response.data.tema_id !== undefined){
                        for (const result of this.state.temas) {
                            if(response.data.tema_id === result.value){
                                response.data.tema_id = result;
                            }
                        }
                    }
                    capacitacion_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({capacitacion: capacitacion_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.capacitacion}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.capacitador_id = values.capacitador_id.value;
                    values.tipo_id = values.tipo_id.value;
                    values.tema_id = values.tema_id.value;
                    values.medio_id = values.medio_id.value;
                    values.asistencia_id = values.asistencia_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(capacitacionActions.guardar(values));
                    }else{
                        dispatch(capacitacionActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="capacitador_id">Capacitador</label>
                                <MySelect
                                    value={values.capacitador_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.capacitador_id}
                                    touched={touched.capacitador_id}
                                    field={'capacitador_id'}
                                    options={this.state.capacitadores}
                                />
                                <ErrorMessage component="div" name="capacitador_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tipo_id">Tipo</label>
                                <MySelect
                                    value={values.tipo_id||''}
                                    // onChange={setFieldValue}
                                    onChange={(field, value)=>{
                                        const newTema = value.value;
                                        const shoulResetTipos =
                                        newTema !== this.state.prevTipo;
                                        this.setState({ prevTipo: newTema }, () => {
                                            setFieldValue(field, value);
                                            if (shoulResetTipos) {
                                                setFieldValue("tema_id", emptyOption);
                                                this.setState({temas: emptyOption});
                                                this.getTemas();
                                            }
                                        });
                                    }}
                                    onBlur={setFieldTouched}
                                    error={errors.tipo_id}
                                    touched={touched.tipo_id}
                                    field={'tipo_id'}
                                    options={this.state.tipos}
                                />
                                <ErrorMessage component="div" name="tipo_id" className="invalid-feedback" />
                            </div>
                        </div>
            
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tema_id">Tema</label>
                                <MySelect
                                    value={values.tema_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tema_id}
                                    touched={touched.tema_id}
                                    field={'tema_id'}
                                    options={this.state.temas}
                                />
                                <ErrorMessage component="div" name="tema_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="medio_id">Medios</label>
                                <MySelect
                                    value={values.medio_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.medio_id}
                                    touched={touched.medio_id}
                                    field={'medio_id'}
                                    options={this.state.medios}
                                />
                                <ErrorMessage component="div" name="medio_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="asistencia_id">Asistencia</label>
                                <MySelect
                                    value={values.asistencia_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.asistencia_id}
                                    touched={touched.asistencia_id}
                                    field={'asistencia_id'}
                                    options={this.state.asistencias}
                                />
                                <ErrorMessage component="div" name="asistencia_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="lugar">Lugar</label>
                                <Field type="text" name="lugar" value={values.lugar || ''} placeholder="Lugar" className={`form-control ${touched.lugar && errors.lugar ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="lugar" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fecha">Fecha</label>
                                <Field type="date" name="fecha" value={values.fecha || ''} placeholder="Fecha" className={`form-control ${touched.fecha && errors.fecha ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(CapacitacionForm);