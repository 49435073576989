// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../helpers';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.carta.id}
          </td>
          <td>
            {this.props.carta.fecha_solicitud}
          </td>
          <td>
            {this.props.carta.tramite}
          </td>
          <td>
            {this.props.carta.entregada ? 'Si':'No'}
          </td>
          <td>
            {this.props.carta.fecha_aprobada}
          </td>
          <td>
            {
              this.props.carta.archivo && 
            <a href={`${apiUrl}/storage/uploads/images/${this.props.carta.archivo}`} rel="noopener noreferrer"  target="_blank">Ver</a>
            }
          </td>
          <td>
            {this.props.carta.bitacora}
          </td>
          <td>
            {
              this.props.carta.entregada &&
              <Link to={"/recursoshumanos/cartaingresos/actualizar/"+this.props.carta.id} id={this.props.carta.id} className="btn btn-primary">Editar</Link>
            }
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;