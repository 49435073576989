import React, { Component } from 'react'
import { reposicionherramientaActions } from '../../actions';
import { connect } from 'react-redux'
import './ReposicionHerramienta'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    tipo_herramienta_id: Yup.string()
        .required("Campo requerido"),
    motivo_id: Yup.string()
        .required("Campo requerido")
});

class ReposicionHerramientaForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            seguro: {
                tipo_herramienta_id: "",
                motivo_id: "",
                fecha_solicitud: "",
                observaciones: "",
                denuncia_mp: "",
                fotografia: ""
            },
            motivo : false
        };

        const { dispatch } = this.props;
        dispatch(reposicionherramientaActions.getMotivos());
        dispatch(reposicionherramientaActions.getTipos());
        this.ifPresent = this.ifPresent.bind(this);
        this.validateOpt = this.validateOpt.bind(this);
    }

    ifPresent(value, input){
        let error;
        if(value === "" && (input !== undefined || input !== "")){
            error = "Campo Requerido";
        }
        return error;
    }

    validateOpt(value, option,selected){
        let error;
        const found = selected.includes(option.label);
        if(found){
            this.setState({motivo: true});
            if(value === '' || value === null){
                error = 'Requerido';
            }
        }else{
            this.setState({motivo: false});
        }
        return error;
    }
    
    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/seguridad-corporativa/v1/reposicionherramienta/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.data.tipo_herramienta_id !== undefined){
                    for (const result of this.props.tipo_herramienta) {
                        if(response.data.tipo_herramienta_id === result.value){
                            response.data.tipo_herramienta_id = result;
                        }
                    }
                }
                if(response.data.motivo_id !== undefined){
                    for (const result of this.props.motivos) {
                        if(response.data.motivo_id === result.value){
                            response.data.motivo_id = result;
                        }
                    }
                }
                this.setState({seguro: response.data});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.seguro}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.tipo_herramienta_id = values.tipo_herramienta_id.value;
                    values.motivo_id = values.motivo_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(reposicionherramientaActions.guardar(values));
                    }else{
                        dispatch(reposicionherramientaActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tipo_herramienta_id">Tipo de Herramienta</label>

                                <MySelect
                                    value={values.tipo_herramienta_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tipo_herramienta_id}
                                    touched={touched.tipo_herramienta_id}
                                    field={'tipo_herramienta_id'}
                                    options={this.props.tipo_herramienta}
                                />
                                <ErrorMessage component="div" name="tipo_herramienta_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="motivo_id">Motivo</label>

                                <MySelect
                                    value={values.motivo_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.motivo_id}
                                    touched={touched.motivo_id}
                                    field={'motivo_id'}
                                    options={this.props.motivos}
                                />
                                <ErrorMessage component="div" name="motivo_id" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className={this.state.motivo ? "row" : "row d-none"}>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fecha_perdida">Fecha de Perdida o Robo</label>
                                <Field type="date" name="fecha_perdida" value={values.fecha_perdida || ''} validate={(value) => this.validateOpt(value,values.motivo_id, ["Robo","Perdida"])} placeholder="Observaciones" className={`form-control ${touched.fecha_perdida && errors.fecha_perdida ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha_perdida" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="denuncia_mp">Denuncia del MP</label>
                                <Field type="text" name="denuncia_mp" value={values.denuncia_mp || ''} validate={(value) => this.validateOpt(value,values.motivo_id, ["Robo","Perdida"])} placeholder="Denuncia del MP" className={`form-control ${touched.denuncia_mp && errors.denuncia_mp ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="denuncia_mp" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia </label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.seguro.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, reposicionherramienta } = state;
    return {
        alert,
        tipo_herramienta: reposicionherramienta.tipo_herramienta,
        motivos: reposicionherramienta.motivos,
    };
}


export default connect(mapStateToProps)(ReposicionHerramientaForm);