import { salariosConstants } from '../constants';
import { salarioService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const salarioActions = {
    actualizar,
    getAll,
    getOne
};

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        salarioService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.salario));
                        history.push(`/desarrollohumano/salario/actualizar/${response.salario.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(salario) { return { type: salariosConstants.SAVE_SALARIO_REQUEST, salario } }
    function success(salario) { return { type: salariosConstants.SAVE_SALARIO_SUCCESS, salario } }
    function failure(error) { return { type: salariosConstants.SAVE_SALARIO_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        salarioService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: salariosConstants.FETCH_SALARIO_REQUEST, data } }
    function success(data) { return { type: salariosConstants.FETCH_SALARIO_SUCCESS, data } }
    function failure(error) { return { type: salariosConstants.FETCH_SALARIO_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        salarioService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: salariosConstants.GET_SALARIO_REQUEST, data } }
    function success(data) { return { type: salariosConstants.GET_SALARIO_SUCCESS, data } }
    function failure(error) { return { type: salariosConstants.GET_SALARIO_FAILURE, error } }
}