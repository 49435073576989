import React , { Component } from 'react'
import { connect } from 'react-redux'

class Alert extends Component{

    createMarkup(html) {
        return {__html: html};
    }
    render(){
        const { alert } = this.props;
        return(
            <div>
                {alert.message && 
                <div className={`alert ${alert.type}`} role="alert">
                    <div dangerouslySetInnerHTML={this.createMarkup(alert.message ? alert.message : '')} />
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
  }
  
  export default connect(mapStateToProps)(Alert);
  