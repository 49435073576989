import React, { Component } from 'react'
import { solicitudirtraActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    tipo_tramite_id: Yup.string()
        .required("Campo requerido"),
    conyugue: Yup.string()
        .notRequired("Campo requerido").nullable(),
    fotografia: Yup.string().notRequired("Campo requerido").nullable()
});

class SolicitudIrtraForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            solicitud: {
                tipo_tramite_id: "",
                descuento: false,
                conyugue: "",
                fotografia: ""
            },reposicion: false
        };

        const { dispatch } = this.props;
        dispatch(solicitudirtraActions.getTramites());
        this.validateOpt = this.validateOpt.bind(this);
    }

    validateOpt(value, option,selected){
        let error;
        if(option.label === selected){
            this.setState({reposicion: true});
            // error = 'Requerido';
        }else{
            this.setState({reposicion: false});
        }
        return error;
    }
    
    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/recursoshumanos/v1/solicitudirtra/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.solicitud.tipo_tramite_id !== undefined){
                    for (const result of this.props.tramites) {
                        if(response.solicitud.tipo_tramite_id === result.value){
                            response.solicitud.tipo_tramite_id = result;
                        }
                    }
                }
                this.setState({solicitud: response.solicitud});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.solicitud}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.tipo_tramite_id = values.tipo_tramite_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(solicitudirtraActions.guardar(values));
                    }else{
                        dispatch(solicitudirtraActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting, isValidating }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tipo_tramite_id">Tramite</label>
                                <MySelect
                                    value={values.tipo_tramite_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tipo_tramite_id}
                                    touched={touched.tipo_tramite_id}
                                    
                                    field={'tipo_tramite_id'}
                                    options={this.props.tramites}
                                />
                                <ErrorMessage component="div" name="tipo_tramite_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className={!this.state.reposicion?'col-12 col-md-6 d-none':'col-12 col-md-6'}>
                            <div className="form-group">
                                <label htmlFor="descuento">Solicitar Descuento</label>
                                <div className="form-group">
                                    <Field type="checkbox" id="descuento" checked={values.descuento === true} validate={(value) => this.validateOpt(value,values.tipo_tramite_id, "Reposición")} name="descuento" onChange={() => {setFieldValue("descuento", !values.descuento);}} value={true} className={`form-control no-style ${touched.descuento && errors.descuento ? "is-invalid" : "" }`} />  <label htmlFor="descuento">Si</label>
                                    {/* <Field type="date" value={values.descuento || ''} name="descuento" placeholder="dd/mm/yy" className={`form-control ${touched.descuento && errors.descuento ? "is-invalid" : ""}`} /> */}
                                    <ErrorMessage component="div" name="descuento" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="conyugue">Conyugue (Si la opción es Actualización Anual escribir N/A) </label>
                                <Field type="text" name="conyugue" value={values.conyugue || ''} placeholder="Nombre del Conyugue" className={`form-control ${touched.conyugue && errors.conyugue ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="conyugue" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia (con fondo blanco, no selfie)</label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, solicitudirtra } = state;
    return {
        alert,
        tramites: solicitudirtra.tramites
    };
}


export default connect(mapStateToProps)(SolicitudIrtraForm);