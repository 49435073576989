import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { vacacionesService } from '../../services';

import './Dashboard.css'
import { NavLink } from 'react-router-dom';
function Dashboard(){

    const [vacaciones, setVacas] = useState({"status":false,"dias_disponibles":0})

    useEffect(() => {
        vacacionesService.getDias()
        .then((res) => {
            setVacas(res)
        })
    },[])

    return (
    <>
        <div id="wrapper">
            <Sidebar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <Alert />
                        <div className="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 className="text-dark mb-0">Dashboard</h3>
                            <h5 style={{color:"#111111"}}> 
                                Días disponibles de vacaciones: 
                                <strong> {vacaciones.dias_disponibles}</strong>
                            </h5>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="w_overlay">
                                        <h3>Organigrama</h3>
                                        <NavLink to='/organigrama' className="btn btn-success" activeClassName='active'><span>Ir a Organigrama</span></NavLink>
                                    </div>
                                    <img className="card-img-top" src="assets/img/Organigrama.gif" alt="Organigrama"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="w_overlay">
                                        <h3>Finanzas</h3>
                                        <NavLink to='/finanzas' className="btn btn-success" activeClassName='active'><span>Ir a Finanzas</span></NavLink>
                                    </div>
                                    <img className="card-img-top" src="assets/img/Finanza.gif" alt="Finazas"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="w_overlay">
                                        <h3>Gestión de Personal</h3>
                                        <NavLink to='/recursoshumanos' className="btn btn-success" activeClassName='active'><span>Ir a Gestión de Personal</span></NavLink>
                                    </div>
                                    <img className="card-img-top" src="assets/img/RRHH.gif" alt="Gestión de Personal"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="w_overlay">
                                        <h3>Seguridad Corporativa</h3>
                                        <NavLink to='/seguridadcorporativa' className="btn btn-success" activeClassName='active'><span>Ir a Seguridad Corporativa</span></NavLink>
                                    </div>
                                    <img className="card-img-top" src="assets/img/Seguridad.gif" alt="Seguridad Corporativa"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="w_overlay">
                                        <h3>Desarrollo Humano</h3>
                                        <NavLink to='/desarrollohumano' className="btn btn-success" activeClassName='active'><span>Ir a Desarrollo Humano</span></NavLink>
                                    </div>
                                    <img className="card-img-top" src="assets/img/Desarrollo.gif" alt="Desarrollo Humano"/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <img className="card-img-top" src="assets/img/Merchandising.gif" alt="Merchandising"/>
                                    <div className="w_overlay">
                                        <h3>Merchandising</h3>
                                        <NavLink to='/merchandising' className="btn btn-success" activeClassName='active'><span>Ir a Merchandising</span></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
            </a>
        </div>
    </>
    );
}

export default Dashboard;