export const capacitacionConstants = {
    SAVE_CAPACITACION_REQUEST: 'SAVE_CAPACITACION_REQUEST',
    SAVE_CAPACITACION_SUCCESS: 'SAVE_CAPACITACION_SUCCESS',
    SAVE_CAPACITACION_FAILURE: 'SAVE_CAPACITACION_FAILURE',

    FETCH_CAPACITACION_REQUEST: 'FETCH_CAPACITACION_REQUEST',
    FETCH_CAPACITACION_SUCCESS: 'FETCH_CAPACITACION_SUCCESS',
    FETCH_CAPACITACION_FAILURE: 'FETCH_CAPACITACION_FAILURE',


    SET_CAPACITACION_REQUEST: 'SET_CAPACITACION_REQUEST',
    SET_CAPACITACION_SUCCESS: 'SET_CAPACITACION_SUCCESS',
    SET_CAPACITACION_FAILURE: 'SET_CAPACITACION_FAILURE',

    GET_CAPACITACION_REQUEST: 'GET_CAPACITACION_REQUEST',
    GET_CAPACITACION_SUCCESS: 'GET_CAPACITACION_SUCCESS',
    GET_CAPACITACION_FAILURE: 'GET_CAPACITACION_FAILURE',

};
