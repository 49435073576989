import { herramientanuevaConstants } from '../constants';
import { herramientanuevaService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const herramientanuevaActions = {
    guardar,
    actualizar,
    getAll,
    getTipos,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        herramientanuevaService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/seguridadcorporativa/herramientanueva/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(herramientanueva) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_REQUEST, herramientanueva } }
    function success(herramientanueva) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_SUCCESS, herramientanueva } }
    function failure(error) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        herramientanuevaService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.herramientanueva));
                        history.push(`/seguridadcorporativa/herramientanueva/actualizar/${response.herramientanueva.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(herramientanueva) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_REQUEST, herramientanueva } }
    function success(herramientanueva) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_SUCCESS, herramientanueva } }
    function failure(error) { return { type: herramientanuevaConstants.SAVE_HERRAMIENTANUEVA_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        herramientanuevaService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_REQUEST, data } }
    function success(data) { return { type: herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_SUCCESS, data } }
    function failure(error) { return { type: herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_FAILURE, error } }
}

function getTipos() {
    return dispatch => {
        dispatch(request({}));

        herramientanuevaService.getTipos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_REQUEST, data } }
    function success(data) { return { type: herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_SUCCESS, data } }
    function failure(error) { return { type: herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_FAILURE, error } }
}


function getOne(herramientanueva) {
    return dispatch => {
        dispatch(request());

        herramientanuevaService.getOne(herramientanueva)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: herramientanuevaConstants.GET_HERRAMIENTANUEVA_REQUEST, data } }
    function success(data) { return { type: herramientanuevaConstants.GET_HERRAMIENTANUEVA_SUCCESS, data } }
    function failure(error) { return { type: herramientanuevaConstants.GET_HERRAMIENTANUEVA_FAILURE, error } }
}