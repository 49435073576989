import {apiUrl} from '../helpers'
export const buzonService = {
    guardar
};

const USER_API_BASE_URL = apiUrl;

function guardar(Data) {
    let user = JSON.parse(localStorage.getItem('user'));
    Data.append('empleado_id',user.id);
    Data.append('email_from',user.email);
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/desarrollohumano/v1/buzon/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
