import { competenciaConstants } from '../constants';

export function competencia(state = {competencias: [] ,loading:false}, action) {
  switch (action.type) {
    case competenciaConstants.FETCH_COMPETENCIA_REQUEST:
      return {
        loading: true,
        competencias: []
      };
    case competenciaConstants.FETCH_COMPETENCIA_SUCCESS:
      return {
        loading: false,
        competencias: action.data
      };
    case competenciaConstants.FETCH_COMPETENCIA_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}