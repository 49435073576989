import { beneficiosConstants } from '../constants';

export function beneficios(state = {beneficios: [], loading:true}, action) {
  switch (action.type) {
    case beneficiosConstants.FETCH_BENEFICIOS_REQUEST:
      return {
        loading: true,
        beneficios: [],
        motivos: state.motivos
      };
    case beneficiosConstants.FETCH_BENEFICIOS_SUCCESS:
      return {
        loading: false,
        beneficios: action.data,
        motivos: state.motivos
      };
    case beneficiosConstants.FETCH_BENEFICIOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}