export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    REFRESH_REQUEST: 'USERS_REFRESH_REQUEST',
    REFRESH_SUCCESS: 'USERS_REFRESH_SUCCESS',
    REFRESH_FAILURE: 'USERS_REFRESH_FAILURE',

    TOKENEXPIRED: 'USERS_TOKENEXPIRED',
    REFRESHING_TOKEN: 'USERS_REFRESHING_TOKEN',
    TOKENREFRESHED: 'USERS_TOKENREFRESHED',
};
