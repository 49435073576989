// TableRow.js

import React, { Component } from 'react';
import { apiUrl } from '../../helpers';
class TableRowDescargas extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.data.nombre}
          </td>
          <td>
            <a href={apiUrl + 'storage/uploads/files/' + this.props.data.archivo} target="_blank" rel="noopener noreferrer" download="download"> <i className="fas fa-download"></i> </a>
          </td>
        </tr>
    );
  }
}


export default TableRowDescargas;