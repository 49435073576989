import { vacacionesConstants } from '../constants';

export function vacaciones(state = {vacaciones: [], motivos: [], tipos: [], licencias: [], dias: [] ,loading:true}, action) {
  switch (action.type) {
    case vacacionesConstants.FETCH_VACACIONES_REQUEST:
      return {
        loading: true,
        vacaciones: [],
        motivos: state.motivos
      };
    case vacacionesConstants.FETCH_VACACIONES_SUCCESS:
      return {
        loading: false,
        vacaciones: action.data,
        motivos: state.motivos
      };
    case vacacionesConstants.FETCH_VACACIONES_FAILURE:
      return { 
        error: action.error
      };
    case vacacionesConstants.FETCH_MOTIVOS_REQUEST:
      return {
        ...state,
        loading: true,
        motivos: [],
      };
    case vacacionesConstants.FETCH_MOTIVOS_SUCCESS:
      return {
        ...state,
        loading: false,
        motivos: action.data,
      };
    case vacacionesConstants.FETCH_MOTIVOS_FAILURE:
      return { 
        error: action.error
    };
    case vacacionesConstants.FETCH_TIPOS_REQUEST:
      return {
        ...state,
        loading: true,
        tipos: [],
      };
    case vacacionesConstants.FETCH_TIPOS_SUCCESS:
      return {
        ...state,
        loading: false,
        tipos: action.data,
      };
    case vacacionesConstants.FETCH_TIPOS_FAILURE:
      return { 
        error: action.error
      };
    case vacacionesConstants.FETCH_TIPOS_LICENCIA_REQUEST:
      return {
        ...state,
        loading: true,
        licencias: [],
      };
    case vacacionesConstants.FETCH_TIPOS_LICENCIA_SUCCESS:
      return {
        ...state,
        loading: false,
        licencias: action.data,
      };
    case vacacionesConstants.FETCH_TIPOS_LICENCIA_FAILURE:
      return { 
        error: action.error
      };
    case vacacionesConstants.FETCH_DIAS_REQUEST:
      return {
        ...state,
        loading: true,
        dias: [],
      };
    case vacacionesConstants.FETCH_DIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        dias: action.data,
      };
    case vacacionesConstants.FETCH_DIAS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}