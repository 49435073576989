import { actividadesConstants } from '../constants';
import { actividadesService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const actividadesActions = {
    guardar,
    actualizar,
    getAll,
    getEncuestas,
    meInteresa,
    getComentarios,
    guardarComentario,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        actividadesService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/desarrollohumano/actividades/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actividades) { return { type: actividadesConstants.SAVE_ACTIVIDADES_REQUEST, actividades } }
    function success(actividades) { return { type: actividadesConstants.SAVE_ACTIVIDADES_SUCCESS, actividades } }
    function failure(error) { return { type: actividadesConstants.SAVE_ACTIVIDADES_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        actividadesService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.actividades));
                        history.push(`/desarrollohumano/actividades/actualizar/${response.actividades.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actividades) { return { type: actividadesConstants.SAVE_ACTIVIDADES_REQUEST, actividades } }
    function success(actividades) { return { type: actividadesConstants.SAVE_ACTIVIDADES_SUCCESS, actividades } }
    function failure(error) { return { type: actividadesConstants.SAVE_ACTIVIDADES_FAILURE, error } }
}

function getAll(tipo) {
    return dispatch => {
        dispatch(request({}));

        actividadesService.getAll(tipo)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: actividadesConstants.FETCH_ACTIVIDADES_REQUEST, data } }
    function success(data) { return { type: actividadesConstants.FETCH_ACTIVIDADES_SUCCESS, data } }
    function failure(error) { return { type: actividadesConstants.FETCH_ACTIVIDADES_FAILURE, error } }
}

function getEncuestas() {
    return dispatch => {
        dispatch(request({}));

        actividadesService.getEncuestas()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: actividadesConstants.FETCH_ACTIVIDADES_REQUEST, data } }
    function success(data) { return { type: actividadesConstants.FETCH_ACTIVIDADES_SUCCESS, data } }
    function failure(error) { return { type: actividadesConstants.FETCH_ACTIVIDADES_FAILURE, error } }
}

function meInteresa(blog_id) {
    return dispatch => {
        dispatch(request({}));

        actividadesService.meInteresa(blog_id)
            .then(
                response => {
                    if(response.status){
                        dispatch(actividadesActions.getAll());
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: actividadesConstants.SET_MEINTERESA_REQUEST, data } }
    function success(data) { return { type: actividadesConstants.SET_MEINTERESA_SUCCESS, data } }
    function failure(error) { return { type: actividadesConstants.SET_MEINTERESA_FAILURE, error } }
}


function getOne(id) {
    return dispatch => {
        dispatch(request());

        actividadesService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: actividadesConstants.GET_ACTIVIDAD_REQUEST, data } }
    function success(data) { return { type: actividadesConstants.GET_ACTIVIDAD_SUCCESS, data } }
    function failure(error) { return { type: actividadesConstants.GET_ACTIVIDAD_FAILURE, error } }
}

function getComentarios(id,page = 0,limit = 10) {
    return dispatch => {
        dispatch(request());

        actividadesService.getComentarios(id,page,limit)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: actividadesConstants.GET_COMENTARIOS_REQUEST, data } }
    function success(data) { return { type: actividadesConstants.GET_COMENTARIOS_SUCCESS, data } }
    function failure(error) { return { type: actividadesConstants.GET_COMENTARIOS_FAILURE, error } }
}


function guardarComentario(formData) {
    return dispatch => {

        actividadesService.guardarComentario(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(alertActions.error(response.mensaje));
                    }
                    // dispatch(actividadesActions.getComentarios(formData.blog_id));
                },
                error => dispatch(alertActions.error(error))
            );
    };
}