export const vacacionesConstants = {
    SAVE_VACACIONES_REQUEST: 'SAVE_VACACIONES_REQUEST',
    SAVE_VACACIONES_SUCCESS: 'SAVE_VACACIONES_SUCCESS',
    SAVE_VACACIONES_FAILURE: 'SAVE_VACACIONES_FAILURE',

    FETCH_VACACIONES_REQUEST: 'FETCH_VACACIONES_REQUEST',
    FETCH_VACACIONES_SUCCESS: 'FETCH_VACACIONES_SUCCESS',
    FETCH_VACACIONES_FAILURE: 'FETCH_VACACIONES_FAILURE',


    FETCH_MOTIVOS_REQUEST: 'FETCH_MOTIVOS_REQUEST',
    FETCH_MOTIVOS_SUCCESS: 'FETCH_MOTIVOS_SUCCESS',
    FETCH_MOTIVOS_FAILURE: 'FETCH_MOTIVOS_FAILURE',

    FETCH_TIPOS_REQUEST: 'FETCH_TIPOS_REQUEST',
    FETCH_TIPOS_SUCCESS: 'FETCH_TIPOS_SUCCESS',
    FETCH_TIPOS_FAILURE: 'FETCH_TIPOS_FAILURE',

    FETCH_TIPOS_LICENCIA_REQUEST: 'FETCH_TIPOS_LICENCIA_REQUEST',
    FETCH_TIPOS_LICENCIA_SUCCESS: 'FETCH_TIPOS_LICENCIA_SUCCESS',
    FETCH_TIPOS_LICENCIA_FAILURE: 'FETCH_TIPOS_LICENCIA_FAILURE',

    FETCH_DIAS_REQUEST: 'FETCH_DIAS_REQUEST',
    FETCH_DIAS_SUCCESS: 'FETCH_DIAS_SUCCESS',
    FETCH_DIAS_FAILURE: 'FETCH_DIAS_FAILURE',

    GET_VACACIONES_REQUEST: 'GET_VACACIONES_REQUEST',
    GET_VACACIONES_SUCCESS: 'GET_VACACIONES_SUCCESS',
    GET_VACACIONES_FAILURE: 'GET_VACACIONES_FAILURE',

};
