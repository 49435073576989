import React, { Component } from 'react';
import './App.css';
import Routes from './Router'

class App extends Component{
  render(){
    return (
        <Routes />
    );
  }
}

export default App;