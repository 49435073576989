import {apiUrl} from '../helpers'
export const encuestasService = {
    guardar,
    getAll,
    actualizar,
    getComentarios,
    guardarComentario,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/recursoshumanos/v1/encuestas/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/recursoshumanos/v1/encuestas/obtener/${id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}


function getComentarios(id,page,limit) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/recursoshumanos/v1/encuestas/comentarios/${id}?page=${page}&limit=${limit}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function guardarComentario(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.append('usuario_id',user.id);
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: formData
    };
    
    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/encuestas/guardarComentario', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
    });
}


function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.token = user.access_token;
    formData.empleado_id = user.id;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/encuestas/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.id = id;
    formData.token = user.access_token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/encuestas/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
