import React, { Component } from 'react'
import { exhibicionesActions } from '../../../actions';
import { connect } from 'react-redux'
import './Exhibicion'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import {  exhibicionesService } from '../../../services';


import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
     cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),   
    marca: Yup.string()
        .required("Campo requerido"), 
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    item: Yup.string()
        .required("Campo requerido"),
    producto: Yup.string()
        .required("Campo requerido"),
    tamano: Yup.string()
        .required("Campo requerido"),
    tipo: Yup.string()
        .required("Campo requerido"),
    cantidad: Yup.string()
        .required("Campo requerido"), 
    // codigo_producto: Yup.string()
    //     .required("Campo requerido"),
    // productos: Yup.string()
    //     .required("Campo requerido"),
    negociada_marca: Yup.boolean()
        .required("Campo requerido"),
    negociada_promotor: Yup.string()
        .when('negociada_marca', { is: true, then:  Yup.string().required('Requerido') }),
    fecha_inicio: Yup.string()
        .required("Campo requerido"),
    fecha_fin: Yup.string()
        .required("Campo requerido"),
    fotografia: Yup.string()
        .required("Campo requerido"),
    observaciones: Yup.string()
        .required("Campo requerido"), 
});

const emptyOption = { value: "", label: "" };
class ExhibicionForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            exhibicion: {
                cadena: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                tipo: "",
                cantidad: "",
                //codigo_producto: "",
                productos: "",
                negociada_marca: 0,
                negociada_promotor: "",
                fecha_inicio: "",
                fecha_fin: "",
                fotografia: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
        };

    }

    componentDidMount(){
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ exhibicion: {...this.state.exhibicion,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }  
        if(this.props.id){
            let exhibicion_temp = this.state.exhibicion;
            exhibicionesService.getOne(this.props.id).then(
                response => {
                    exhibicion_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({exhibicion: exhibicion_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.exhibicion}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                     const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(exhibicionesActions.guardar(values));
                    }else{
                        dispatch(exhibicionesActions.actualizar(values,this.props.id));
                    }
 
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaños</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tipo">Tipo</label>
                                <Field type="text" name="tipo" value={values.tipo || ''} placeholder="Ingrese el Tipo" className={`form-control ${touched.tipo && errors.tipo ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tipo" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cantidad">Cantidad</label>
                                <Field type="number" name="cantidad" value={values.cantidad || ''} placeholder="0" min="0" className={`form-control ${touched.cantidad && errors.cantidad ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="negociada_marca">Negociada por la marca</label>

                                <div className="form-group">
                                    <label htmlFor="negociada_marca1"> <Field id="negociada_marca1" type="radio" checked={values.negociada_marca === 1} name="negociada_marca" onChange={() => {setFieldValue("negociada_marca", !values.negociada_marca);}} value={1} className={`form-control no-style ${touched.negociada_marca && errors.negociada_marca ? "is-invalid" : "" }`} /> Si</label>
                                    <label htmlFor="negociada_marca2"> <Field id="negociada_marca2" type="radio" checked={values.negociada_marca === 0} name="negociada_marca" onChange={() => {setFieldValue("negociada_marca", !values.negociada_marca);}} value={0} className={`form-control no-style ${touched.negociada_marca && errors.negociada_marca ? "is-invalid" : "" }`} /> No</label>
                                </div>
                                <ErrorMessage component="div" name="negociada_marca"  className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="negociada_promotor">Negociada por promotor(a)</label>
                                <Field type="text" name="negociada_promotor" value={values.negociada_promotor || ''} placeholder="Ingresa tu nombre y apellido" className={`form-control ${touched.negociada_promotor && errors.negociada_promotor ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="negociada_promotor" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio" value={values.fecha_inicio || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio && errors.fecha_inicio ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin" value={values.fecha_fin || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin && errors.fecha_fin ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(ExhibicionForm);