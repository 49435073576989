import { descargasConstants } from '../constants';
import { descargasService } from '../services';

export const descargasActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request({}));

        descargasService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: descargasConstants.FETCH_DESCARGAS_REQUEST, data } }
    function success(data) { return { type: descargasConstants.FETCH_DESCARGAS_SUCCESS, data } }
    function failure(error) { return { type: descargasConstants.FETCH_DESCARGAS_FAILURE, error } }
}
