import { monitoreosConstants } from '../constants';
import { monitoreosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const monitoreosActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        monitoreosService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/monitoreos/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(monitoreos) { return { type: monitoreosConstants.SAVE_MONITOREOS_REQUEST, monitoreos } }
    function success(monitoreos) { return { type: monitoreosConstants.SAVE_MONITOREOS_SUCCESS, monitoreos } }
    function failure(error) { return { type: monitoreosConstants.SAVE_MONITOREOS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        monitoreosService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/monitoreos/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(monitoreos) { return { type: monitoreosConstants.SAVE_MONITOREOS_REQUEST, monitoreos } }
    function success(monitoreos) { return { type: monitoreosConstants.SAVE_MONITOREOS_SUCCESS, monitoreos } }
    function failure(error) { return { type: monitoreosConstants.SAVE_MONITOREOS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        monitoreosService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: monitoreosConstants.FETCH_MONITOREOS_REQUEST, data } }
    function success(data) { return { type: monitoreosConstants.FETCH_MONITOREOS_SUCCESS, data } }
    function failure(error) { return { type: monitoreosConstants.FETCH_MONITOREOS_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        monitoreosService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: monitoreosConstants.GET_MONITOREO_REQUEST, data } }
    function success(data) { return { type: monitoreosConstants.GET_MONITOREO_SUCCESS, data } }
    function failure(error) { return { type: monitoreosConstants.GET_MONITOREO_FAILURE, error } }
}