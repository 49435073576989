import { actividadesConstants } from '../constants';

export function actividades(state = {actividades: [], actividad:[] ,loading:false, comentarios: {}}, action) {
  switch (action.type) {
    case actividadesConstants.FETCH_ACTIVIDADES_REQUEST:
      return {
        ...state,
        loading: true,
        actividades: [],
      };
    case actividadesConstants.FETCH_ACTIVIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        actividades: action.data,
      };
    case actividadesConstants.FETCH_ACTIVIDADES_FAILURE:
      return { 
        error: action.error
      };
    case actividadesConstants.GET_ACTIVIDAD_REQUEST:
      return {
        ...state,
        loading: true,
        actividad: [],
      };
    case actividadesConstants.GET_ACTIVIDAD_SUCCESS:
      return {
        loading: false,
        actividad: action.data,
      };
    case actividadesConstants.GET_ACTIVIDAD_FAILURE:
      return { 
        error: action.error
      };
    case actividadesConstants.GET_COMENTARIOS_REQUEST:
      return {
        ...state,
        loading: true,
        comentarios: {},
      };
    case actividadesConstants.GET_COMENTARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        comentarios: action.data,
      };
    case actividadesConstants.GET_COMENTARIOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}