export const beneficiosConstants = {

    FETCH_BENEFICIOS_REQUEST: 'FETCH_BENEFICIOS_REQUEST',
    FETCH_BENEFICIOS_SUCCESS: 'FETCH_BENEFICIOS_SUCCESS',
    FETCH_BENEFICIOS_FAILURE: 'FETCH_BENEFICIOS_FAILURE',

    APLICAR_REQUEST: 'APLICAR_REQUEST',
    APLICAR_SUCCESS: 'APLICAR_SUCCESS',
    APLICAR_FAILURE: 'APLICAR_FAILURE',

    GET_BENEFICIOS_REQUEST: 'GET_BENEFICIOS_REQUEST',
    GET_BENEFICIOS_SUCCESS: 'GET_BENEFICIOS_SUCCESS',
    GET_BENEFICIOS_FAILURE: 'GET_BENEFICIOS_FAILURE',

};
