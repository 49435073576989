
import { alertActions } from '../actions'
async function getPermissions (dispatch) {
    if(navigator.geolocation){
        let permissions = await navigator.permissions.query({name: "geolocation"});
        if(permissions.state === "denied"){
            dispatch( alertActions.error(
                "Lo sentimos, la geolocalización no esta disponible actualmente "+
                "</br>"+
                "Puedes ir a este enlace: <a target='_blank' href='https://support.skrill.com/Spanish/CUENTA/Verificaciones/952527282/-C%C3%B3mo-compartir-ubicaci%C3%B3n-en-mi-navegador.htm'>aquí</a> y conceder los permisos de geolocalización"
            ) )
            return false;
        }else{
            navigator.geolocation.getCurrentPosition(() => {});
            return true;
        }
    } else {
        dispatch( alertActions.error("Lo sentimos, la geolocalización no esta disponible actualmente") )
        return false;
    }
}

export default getPermissions