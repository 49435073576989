// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.viatico.id}
          </td>
          <td>
            {this.props.viatico.motivo}
          </td>
          <td>
            {this.props.viatico.fecha_salida}
          </td>
          <td>
            {this.props.viatico.fecha_llegada}
          </td>
          <td>
            {this.props.viatico.lugar_visitar}
          </td>
          <td>
            {this.props.viatico.total}
          </td>
          <td>
            <div className="row">
              <div className="col-md-6 col-12">
                <Link to={"/finanzas/viaticos/ver/"+this.props.viatico.id} id={this.props.viatico.id} className="btn btn-success">Ver</Link>
              </div>

              {
                (!this.props.viatico.acepta_admin || !this.props.viatico.acepta_supervisor) &&
              <div className="col-md-6 col-12">
                <Link to={"/finanzas/viaticos/actualizar/"+this.props.viatico.id} id={this.props.viatico.id} className="btn btn-primary">Editar</Link>
              </div>  
              }

            </div>
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;