import { perfilConstants } from '../constants';

export function perfil(state = {perfil: [] ,loading:false}, action) {
  switch (action.type) {
    case perfilConstants.FETCH_PERFIL_REQUEST:
      return {
        loading: true,
        perfil: [],
      };
    case perfilConstants.FETCH_PERFIL_SUCCESS:
      return {
        loading: false,
        perfil: action.data,
      };
    case perfilConstants.FETCH_PERFIL_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}