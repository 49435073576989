export const perfilConstants = {
    SAVE_PERFIL_REQUEST: 'SAVE_PERFIL_REQUEST',
    SAVE_PERFIL_SUCCESS: 'SAVE_PERFIL_SUCCESS',
    SAVE_PERFIL_FAILURE: 'SAVE_PERFIL_FAILURE',

    FETCH_PERFIL_REQUEST: 'FETCH_PERFIL_REQUEST',
    FETCH_PERFIL_SUCCESS: 'FETCH_PERFIL_SUCCESS',
    FETCH_PERFIL_FAILURE: 'FETCH_PERFIL_FAILURE',

};
