import React, { Component } from 'react';


class Sidebar extends Component{
    render(){
        return (
            <footer className="bg-white sticky-footer">
                <div className="container my-auto">
                    <div className="text-center my-auto copyright"><span>Copyright © Perinola {(new Date().getFullYear())}</span></div>
                </div>
            </footer>
        );
    }
}

export default Sidebar;