import { reposicionherramientaConstants } from '../constants';

export function reposicionherramienta(state = {herramientas: [], tipo_herramienta: [], motivos: [], loading:true}, action) {
  switch (action.type) {
    case reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_REQUEST:
      return {
        loading: true,
        herramientas: [],
        tipo_herramienta: state.tipo_herramienta,
        motivos: state.motivos,
        colores: state.colores
      };
    case reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_SUCCESS:
      return {
        loading: false,
        herramientas: action.data,
        tipo_herramienta: state.tipo_herramienta,
        motivos: state.motivos,
        colores: state.colores
      };
    case reposicionherramientaConstants.FETCH_REPOSICIONHERRAMIENTA_FAILURE:
      return { 
        error: action.error
      };

    case reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_REQUEST:
    return {
        loading: true,
        tipo_herramienta: [],
        herramientas: state.herramientas,
        motivos: state.motivos,
        colores: state.colores
    };
    case reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_SUCCESS:
    return {
        loading: false,
        tipo_herramienta: action.data,
        herramientas: state.herramientas,
        motivos: state.motivos,
        colores: state.colores
    };
    case reposicionherramientaConstants.FETCH_TIPOHERRAMIENTA_FAILURE:
    return { 
        error: action.error
    };

    case reposicionherramientaConstants.FETCH_MOTIVO_REQUEST:
    return {
        loading: true,
        tipo_herramienta: state.tipo_herramienta,
        herramientas: state.herramientas,
        motivos: [],
        colores: state.colores
    };
    case reposicionherramientaConstants.FETCH_MOTIVO_SUCCESS:
    return {
        loading: false,
        tipo_herramienta: state.tipo_herramienta,
        herramientas: state.herramientas,
        motivos: action.data,
        colores: state.colores
    };
    case reposicionherramientaConstants.FETCH_MOTIVO_FAILURE:
    return { 
        error: action.error
    };

    default:
      return state
  }
}