// TableGastoRow.js

import React, { Component } from 'react';
import { liquidacionesActions } from '../../actions';
import { connect } from 'react-redux'

class TableGastoRow extends Component {
  constructor(props){
    super(props);
    this.meInteresa = this.eliminarGasto.bind(this);
  }

  eliminarGasto(gasto){
    const { dispatch } = this.props;
    dispatch(liquidacionesActions.eliminarGasto(gasto));
  }
  render() {
    return (
        <tr>
          <td>
            {this.props.gasto.id}
          </td>
          <td>
            {this.props.gasto.fecha}
          </td>
          <td>
            {this.props.gasto.nombre}
          </td>
          <td>
            {this.props.gasto.no_factura}
          </td>
          <td>
            {this.props.gasto.serie_factura}
          </td>
          <td>
            {this.props.gasto.concepto}
          </td>
          <td>
            {this.props.gasto.monto}
          </td>
          <td>
            <button  onClick={()=>this.eliminarGasto(this.props.gasto)} className="btn btn-danger"><i className="fa fa-trash" aria-hidden="true"></i></button>
          </td>
        </tr>
    );
  }
}


function mapStateToProps(state) {
  const { alert, vacaciones } = state;
  return {
      alert,
      motivos: vacaciones.motivos
  };
}
export default connect(mapStateToProps)(TableGastoRow);