import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { liquidacionesService } from '../../services'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../helpers'

class LiquidacionesDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            liquidaciones: {},
            gastos: {},
            loaded: false,
        }
        liquidacionesService.getOne(this.props.match.params.id).then(
            response => {
                if(response.status){
                    this.setState({liquidaciones: response.liquidacion, gastos: response.gastos, loaded: true});
                }else{
                }
            },
        );
    }

    subirConstancia(e) {

        let Data = new FormData();
        Data.append('id',this.props.match.params.id);
        if(e.target.files[0]){
            Data.append('archivo_firmado',e.target.files[0]);
        }
        this.setState({loaded:false});
        liquidacionesService.guardarArchivo(Data).then(response => {
            if(response.status){
                this.setState({liquidaciones: response.liquidacion, gastos: response.gastos, loaded: true});
                
            }else{
                this.setState({liquidaciones: {}, gastos:{}, loaded: false});
            }
        },
        error => {
            console.log(error)
        });
    }

    tabRow(){
        if(this.state.gastos){
            if(this.state.gastos instanceof Array){
                return this.state.gastos.map(function(object, i){
                    return (<tr>
                        <td>{object.id}</td>
                        <td>{object.fecha}</td>
                        <td>{object.nombre}</td>
                        <td>{object.no_factura}</td>
                        <td>{object.serie_factura}</td>
                        <td>{object.concepto}</td>
                        <td>{object.monto}</td>
                    </tr>);
                })
            }
        }
    }
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Liquidaciones</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                </div>
                                <div className="col-md-2">
                                    <Link to="/finanzas/liquidaciones"  className="btn btn-success">Regresar</Link>
                                </div>
                            </div>
                            {
                                this.state.loaded &&
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <h4>Concepto:</h4> <p>{this.state.liquidaciones.concepto}</p>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <p>Aprobó Supervisor: {this.state.liquidaciones.acepta_supervisor ? 'Sí':'No'}</p>
                                            <p>Aprobó Finanzas: {this.state.liquidaciones.acepta_admin ? 'Sí':'No'}</p>
                                            <p>Adelanto Recibido:  {this.state.liquidaciones.adelanto_recibido}</p>
                                            { this.state.liquidaciones.adelanto_recibido && <p>Adelanto:  {this.state.liquidaciones.adelanto}</p>}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <p>Fecha Creada:  {this.state.liquidaciones.created_at}</p>
                                            <p>Fecha de Inicio:  {this.state.liquidaciones.fecha_inicio}</p>
                                            <p>Fecha Finalizó:  {this.state.liquidaciones.fecha_fin}</p>
                                        </div>
                                        <div className="col-md-12 col-12">
                                        {
                                            this.state.liquidaciones.acepta_admin && this.state.liquidaciones.acepta_supervisor && this.state.liquidaciones.archivo_por_firmar !== null &&
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <a href={apiUrl+`storage/uploads/images/${this.state.liquidaciones.archivo_por_firmar}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                                </div>
                                                {
                                                    this.state.liquidaciones.archivo_firmado &&
                                                    <div className="col-md-3">
                                                        <a href={apiUrl+`storage/uploads/images/${this.state.liquidaciones.archivo_firmado}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo firmado</a>
                                                    </div>
                                                }
                                                <div className="col-md-3">
                                                    <label htmlFor="archivo_firmado">Subir archivo para firmar</label>
                                                    <input type="file" id="archivo_firmado" name="archivo_firmado" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" onChange={this.subirConstancia.bind(this)} />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <td>ID</td>
                                                    <td>Fecha</td>
                                                    <td>Proveedor</td>
                                                    <td>No. Factura</td>
                                                    <td>Serie de la Factura</td>
                                                    <td>Concepto</td>
                                                    <td>Monto</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {this.tabRow()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

export default LiquidacionesDisplay;