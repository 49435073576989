import {apiUrl} from '../helpers'
export const liquidacionesService = {
    guardar,
    guardarGasto,
    actualizar,
    getAll,
    getConceptos,
    getTipoGasolina,
    getAllGastos,
    eliminarGasto,
    guardarArchivo,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getAllGastos(liquidacion_id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        // body: JSON.stringify({access_token: user.access_token,empleado_id: user.id})
    };
    var url = apiUrl + `api/finanzas/v1/liquidaciones/obtener/${liquidacion_id}?`;
    var params = {token: user.access_token};
    let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');

    return fetch(url+query, requestOptions).then(response => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    return response.message;
                }
        
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    });
}

function getOne(liquidacion) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        // body: JSON.stringify({access_token: user.access_token,empleado_id: user.id})
    };
    var url = USER_API_BASE_URL + `api/finanzas/v1/liquidaciones/obtener/${liquidacion}?`;
    var params = {token: user.access_token};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.token = user.access_token;
    formData.empleado_id = user.id;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function guardarGasto(formData,id) {
    let user = JSON.parse(localStorage.getItem('user'));

    var Data = new FormData();
    Data.append('usuario_id',user.id);
    Data.append('liquidacion_id',id);
    Data.append('fotografia',formData.fotografia);
    // Data.append('concepto',formData.concepto);
    Data.append('fecha',formData.fecha);
    if(formData.galones !== undefined){
        Data.append('galones',formData.galones);
    }
    if(formData.gasolina !== undefined){
        Data.append('gasolina',formData.gasolina);
    }
    if(formData.concepto !== undefined && formData.concepto !== null){
        Data.append('concepto',formData.concepto);
    }
    Data.append('monto',formData.monto);
    Data.append('no_factura',formData.no_factura);
    Data.append('serie_factura',formData.serie_factura);
    if(formData.proveedor_id !== undefined){
        Data.append('proveedor_id',formData.proveedor_id);
    }
    if(formData.idp !== undefined){
        Data.append('idp',formData.idp);
    }
    if(formData.impuesto !== undefined){
        Data.append('impuesto',formData.impuesto);
    }
    if(formData.moneda !== undefined){
        Data.append('moneda',formData.moneda);
    }
    if(formData.tipo_cambio !== undefined){
        Data.append('tipo_cambio',formData.tipo_cambio);
    }
    if(formData.categoria !== undefined){
        Data.append('categoria',formData.categoria);
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    return fetch(USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/gasto/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.id = id;
    formData.token = user.access_token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function getConceptos() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtenerconhijos?';
    var params = {token: user.access_token, nombre: 'Conceptos de Gastos'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getTipoGasolina() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Tipo de Gasolina'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function eliminarGasto(gasto) {
    let user = JSON.parse(localStorage.getItem('user'));
    let formData = {token: user.access_token, gasto_id: gasto};
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/gasto/eliminar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function guardarArchivo(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: formData
    };
    
    return fetch(USER_API_BASE_URL + 'api/finanzas/v1/liquidaciones/guardarArchivo', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}