import React, { Component } from 'react'
import { connect } from 'react-redux'
import {apiUrl, history} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import {checkService} from '../../../services'
import { alertActions } from '../../../actions'
import getPermissions from '../../../helpers/permissions'

const LoginSchema = Yup.object().shape({
    entrada: Yup.boolean()
        .required("Campo requerido")
});

class CheckForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            entrada: this.props.checkin,
            checkin: true, 
            fotografia: "",
            latitud: "", 
            longitud: "", 
        };
        
    }
    componentDidMount() {
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({
                    latitud: position.coords.latitude, 
                    longitud: position.coords.longitude
                })
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    checkService.guardar(values).then(
                        response => {
                            const { dispatch } = this.props;
                            dispatch(alertActions.success(response.mensaje));
                        },error => {
                            const { dispatch } = this.props;
                            dispatch(alertActions.success(error));
                        }
                    );
                    history.push(`/merchandising`);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className='col-12 col-md-6'>
                            <div className="form-group">
                                <label htmlFor="checkin">Tipo</label>
                                <div className="form-group">
                                    <label htmlFor="checkin1"> <Field id="checkin1" type="radio" checked={values.checkin === true} name="checkin" onChange={() => {setFieldValue("checkin", !values.checkin);}} value={true} className={`form-control no-style ${touched.checkin && errors.checkin ? "is-invalid" : "" }`} /> Entrada</label>
                                    <label htmlFor="checkin2"> <Field id="checkin2" type="radio" checked={values.checkin === false} name="checkin" onChange={() => {setFieldValue("checkin", !values.checkin);}} value={false} className={`form-control no-style ${touched.checkin && errors.checkin ? "is-invalid" : "" }`} /> Salida</label>
                                    <ErrorMessage component="div" name="checkin" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia </label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(CheckForm);