export const promocional_materialConstants = {
    SAVE_PROMOCIONAL_MATERIAL_REQUEST: 'SAVE_PROMOCIONAL_MATERIAL_REQUEST',
    SAVE_PROMOCIONAL_MATERIAL_SUCCESS: 'SAVE_PROMOCIONAL_MATERIAL_SUCCESS',
    SAVE_PROMOCIONAL_MATERIAL_FAILURE: 'SAVE_PROMOCIONAL_MATERIAL_FAILURE',

    FETCH_PROMOCIONAL_MATERIAL_REQUEST: 'FETCH_PROMOCIONAL_MATERIAL_REQUEST',
    FETCH_PROMOCIONAL_MATERIAL_SUCCESS: 'FETCH_PROMOCIONAL_MATERIAL_SUCCESS',
    FETCH_PROMOCIONAL_MATERIAL_FAILURE: 'FETCH_PROMOCIONAL_MATERIAL_FAILURE',


    SET_PROMOCIONAL_MATERIAL_REQUEST: 'SET_PROMOCIONAL_MATERIAL_REQUEST',
    SET_PROMOCIONAL_MATERIAL_SUCCESS: 'SET_PROMOCIONAL_MATERIAL_SUCCESS',
    SET_PROMOCIONAL_MATERIAL_FAILURE: 'SET_PROMOCIONAL_MATERIAL_FAILURE',

    GET_PROMOCIONAL_MATERIAL_REQUEST: 'GET_PROMOCIONAL_MATERIAL_REQUEST',
    GET_PROMOCIONAL_MATERIAL_SUCCESS: 'GET_PROMOCIONAL_MATERIAL_SUCCESS',
    GET_PROMOCIONAL_MATERIAL_FAILURE: 'GET_PROMOCIONAL_MATERIAL_FAILURE',

};
