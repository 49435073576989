import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";

// let state_of_state = localStorage["user"];

export default function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => (
      localStorage.getItem('user')
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
  );
}
