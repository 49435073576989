export const seguromedicoConstants = {
    SAVE_SEGUROMEDICO_REQUEST: 'SAVE_SEGUROMEDICO_REQUEST',
    SAVE_SEGUROMEDICO_SUCCESS: 'SAVE_SEGUROMEDICO_SUCCESS',
    SAVE_SEGUROMEDICO_FAILURE: 'SAVE_SEGUROMEDICO_FAILURE',

    FETCH_SEGUROMEDICO_REQUEST: 'FETCH_SEGUROMEDICO_REQUEST',
    FETCH_SEGUROMEDICO_SUCCESS: 'FETCH_SEGUROMEDICO_SUCCESS',
    FETCH_SEGUROMEDICO_FAILURE: 'FETCH_SEGUROMEDICO_FAILURE',


    FETCH_TIPOSEGURO_REQUEST: 'FETCH_TIPOSEGURO_REQUEST',
    FETCH_TIPOSEGURO_SUCCESS: 'FETCH_TIPOSEGURO_SUCCESS',
    FETCH_TIPOSEGURO_FAILURE: 'FETCH_TIPOSEGURO_FAILURE',

    FETCH_ASEGURADORA_REQUEST: 'FETCH_ASEGURADORA_REQUEST',
    FETCH_ASEGURADORA_SUCCESS: 'FETCH_ASEGURADORA_SUCCESS',
    FETCH_ASEGURADORA_FAILURE: 'FETCH_ASEGURADORA_FAILURE',

    GET_SEGUROMEDICO_REQUEST: 'GET_SEGUROMEDICO_REQUEST',
    GET_SEGUROMEDICO_SUCCESS: 'GET_SEGUROMEDICO_SUCCESS',
    GET_SEGUROMEDICO_FAILURE: 'GET_SEGUROMEDICO_FAILURE',

};
