import React, { Component } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Navbar from '../../../layout/Navbar'
import Footer from '../../../layout/Footer'
import Alert from '../../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { encuestasActions } from '../../../actions'
import { connect } from 'react-redux'
import EncuestaForm from './EncuestaForm'
import { apiUrl } from '../../../helpers'
import ReactPlayer from 'react-player'

class EncuestaDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            comentario: "",
            comentarios: {},
        }
        console.log(this.props);
        this.props.dispatch(encuestasActions.getOne(this.props.match.params.id));
    }
    createMarkup(html) {
        return {__html: html};
    }


    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Actividades</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row pb-3">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/recursoshumanos/encuestas"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                        {!this.props.loading  && 
                                        <div className="">
                                            <div className="row pb-3">
                                                    <div className="col-md-8 col-12">
                                                        <h3>{this.props.encuesta ? this.props.encuesta.nombre : ''}</h3>
                                                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.encuesta ? this.props.encuesta.descripcion : '')} />
                                                        <div>
                                                            {
                                                                this.props.encuesta && this.props.encuesta.enlace !== null &&
                                                                <ReactPlayer url={this.props.encuesta.enlace} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        {
                                                            this.props.encuesta && this.props.encuesta.imagen !== null &&
                                                            <img src={apiUrl+'/storage/uploads/images/'+this.props.encuesta.imagen} alt="Imagen" width="100%" />
                                                        }
                                                    </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-md-12 col-12">
                                                    {this.props.encuesta && this.props.encuesta.respondido === 0 && <EncuestaForm preguntas={this.props.preguntas} encuesta={this.props.encuesta}/>}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { encuesta, preguntas} = state.encuestas;
    return {
        encuesta, preguntas
    };
}
EncuestaDisplay = connect(mapStateToProps)(EncuestaDisplay);
export {EncuestaDisplay};