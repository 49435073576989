// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.liquidacion.id}
          </td>
          <td>
            {this.props.liquidacion.concepto}
          </td>
          <td>
            {this.props.liquidacion.acepta_supervisor ? 'Si' : 'No'}
          </td>
          <td>
            {this.props.liquidacion.acepta_admin ? 'Si' : 'No'}
          </td>
          <td>
            <div className="row">
              <div className="col-md-6 col-12">
              <Link to={"/finanzas/liquidaciones/ver/"+this.props.liquidacion.id} id={this.props.liquidacion.id} className="btn btn-success">Ver</Link>
              </div>
              { (!this.props.liquidacion.acepta_admin || !this.props.liquidacion.acepta_supervisor) &&
              <div className="col-md-6 col-12">
              <Link to={"/finanzas/liquidaciones/actualizar/"+this.props.liquidacion.id} id={this.props.liquidacion.id} className="btn btn-primary">Editar</Link>
              </div>
              }
            </div>
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;