export const solicituduniformeConstants = {
    SAVE_SOLICITUDUNIFORME_REQUEST: 'SAVE_SOLICITUDUNIFORME_REQUEST',
    SAVE_SOLICITUDUNIFORME_SUCCESS: 'SAVE_SOLICITUDUNIFORME_SUCCESS',
    SAVE_SOLICITUDUNIFORME_FAILURE: 'SAVE_SOLICITUDUNIFORME_FAILURE',

    FETCH_SOLICITUDUNIFORME_REQUEST: 'FETCH_SOLICITUDUNIFORME_REQUEST',
    FETCH_SOLICITUDUNIFORME_SUCCESS: 'FETCH_SOLICITUDUNIFORME_SUCCESS',
    FETCH_SOLICITUDUNIFORME_FAILURE: 'FETCH_SOLICITUDUNIFORME_FAILURE',

    FETCH_TALLASCAMISA_REQUEST: 'FETCH_TALLASCAMISA_REQUEST',
    FETCH_TALLASCAMISA_SUCCESS: 'FETCH_TALLASCAMISA_SUCCESS',
    FETCH_TALLASCAMISA_FAILURE: 'FETCH_TALLASCAMISA_FAILURE',

    FETCH_TALLASPANTALON_REQUEST: 'FETCH_TALLASPANTALON_REQUEST',
    FETCH_TALLASPANTALON_SUCCESS: 'FETCH_TALLASPANTALON_SUCCESS',
    FETCH_TALLASPANTALON_FAILURE: 'FETCH_TALLASPANTALON_FAILURE',

    FETCH_COLORESUNIFORME_REQUEST: 'FETCH_COLORESUNIFORME_REQUEST',
    FETCH_COLORESUNIFORME_SUCCESS: 'FETCH_COLORESUNIFORME_SUCCESS',
    FETCH_COLORESUNIFORME_FAILURE: 'FETCH_COLORESUNIFORME_FAILURE',


    GET_SOLICITUDUNIFORME_REQUEST: 'GET_SOLICITUDUNIFORME_REQUEST',
    GET_SOLICITUDUNIFORME_SUCCESS: 'GET_SOLICITUDUNIFORME_SUCCESS',
    GET_SOLICITUDUNIFORME_FAILURE: 'GET_SOLICITUDUNIFORME_FAILURE',

};
