import { userActions } from '../actions';
// import { userConstants } from '../constants';

export default function requestMiddleware() {
    return ({ dispatch, getState }) => next => (action) => {
      const {
        request,error
      } = action;
        if(error === 'Token ha expirado'){
          dispatch(userActions.logout());
        }else if (!request) {
          return next(action);
        }
    };
  }