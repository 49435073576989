import React, { Component } from 'react';import {
    Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import Login from './views/login/Login'
import Dashboard from './views/dashbard/Dashboard'

import PrivateRoute from './PrivateRoute'
import Geolocation from './views/geolocation/Geolocation';
import { history } from './helpers';
import { alertActions } from './actions';
import { connect } from 'react-redux'
import Perfil from './views/perfil/Perfil';
import Liquidaciones from './views/liquidaciones/Liquidaciones';
import LiquidacionesCreate from './views/liquidaciones/LiquidacionesCreate';
import LiquidacionesUpdate from './views/liquidaciones/LiquidacionesUpdate';
import LiquidacionesDisplay from './views/liquidaciones/LiquidacionesDisplay';

import Viaticos from './views/viaticos/Viaticos';
import ViaticosCreate from './views/viaticos/ViaticosCreate';
import ViaticosUpdate from './views/viaticos/ViaticosUpdate';
import ViaticosDisplay from './views/viaticos/ViaticosDisplay';

import {Vacaciones, VacacionesCreate, VacacionesDisplay, VacacionesUpdate} from './views/gestion_personal';
import {CartaIngresos, CartaIngresosCreate, CartaIngresosDisplay, CartaIngresosUpdate} from './views/gestion_personal';
//import {CertificadoIgss, CertificadoIgssCreate, CertificadoIgssDisplay, CertificadoIgssUpdate} from './views/gestion_personal';
//import {SolicitudIrtra, SolicitudIrtraCreate, SolicitudIrtraDisplay, SolicitudIrtraUpdate} from './views/gestion_personal';

import HerramientaNueva from './views/herramientanueva/HerramientaNueva';
import HerramientaNuevaCreate from './views/herramientanueva/HerramientaNuevaCreate';
import HerramientaNuevaUpdate from './views/herramientanueva/HerramientaNuevaUpdate';
import HerramientaNuevaDisplay from './views/herramientanueva/HerramientaNuevaDisplay';

import SolicitudUniforme from './views/solicituduniforme/SolicitudUniforme';
import SolicitudUniformeCreate from './views/solicituduniforme/SolicitudUniformeCreate';
import SolicitudUniformeUpdate from './views/solicituduniforme/SolicitudUniformeUpdate';
import SolicitudUniformeDisplay from './views/solicituduniforme/SolicitudUniformeDisplay';

import { SeguroMedico, SeguroMedicoDisplay, SeguroMedicoCreate, SeguroMedicoUpdate } from './views/seguromedico'; 

import { ReposicionHerramienta, ReposicionHerramientaDisplay, ReposicionHerramientaCreate, ReposicionHerramientaUpdate } from './views/reposicionherramienta'; 

import {Entrada, Almuerzo} from './views/merchandising';

import { Actividades, ActividadesDisplay, Capacitaciones, Descargas, Encuestas } from './views/actividades'; 

import {SeguridadCorporativa, DesarrolloHumano, Merchandising, Finanzas, RecursosHumanos} from './views/secciones'
import { Exhibicion, ExhibicionCreate, ExhibicionDisplay, ExhibicionUpdate } from './views/merchandising';
import { Monitoreos, MonitoreosCreate, MonitoreosUpdate, MonitoreosDisplay } from './views/merchandising';
import { Btl, BtlCreate, BtlUpdate, BtlDisplay } from './views/merchandising';
import { Competencia, CompetenciaCreate, CompetenciaDisplay, CompetenciaUpdate } from './views/merchandising';
import { VentaPromocion, VentaPromocionCreate, VentaPromocionUpdate, VentaPromocionDisplay } from './views/merchandising';
import { PromocionalMaterial, PromocionalMaterialCreate, PromocionalMaterialUpdate, PromocionalMaterialDisplay } from './views/merchandising';
import { Capacitacion, CapacitacionCreate, CapacitacionUpdate, CapacitacionDisplay } from './views/capacitacion';
import { Beneficios, BeneficiosDisplay } from './views/gestion_personal';
import { Organigrama } from './views/organigrama';
import { Salario, SalarioDisplay } from './views/salario';
import { Buzon } from './views/buzon';
import { Encuesta, EncuestaDisplay } from './views/gestion_personal';
import {Forgot, Change} from './views/passwords';

class Routes extends Component{
    constructor(props){
        super(props);
        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });

    }
    render(){
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/">
                        <Login />
                    </Route>
                    <Route exact path="/forgot">
                        <Forgot />
                    </Route>
                    <Route exact path="/change-password/:token" component={Change} />
                    <Route path="/login">
                        <Login />
                    </Route>
                    <PrivateRoute path='/dashboard' component={Dashboard}/>
                    <PrivateRoute path='/geolocation' component={Geolocation}/>

                    <PrivateRoute exact path='/finanzas' component={Finanzas}/>

                    <PrivateRoute exact path='/finanzas/liquidaciones' component={Liquidaciones}/>
                    <PrivateRoute exact path='/finanzas/liquidaciones/crear' component={LiquidacionesCreate}/>
                    <PrivateRoute exact path='/finanzas/liquidaciones/actualizar/:id' component={LiquidacionesUpdate}/>
                    <PrivateRoute exact path='/finanzas/liquidaciones/ver/:id' component={LiquidacionesDisplay}/>
                    <PrivateRoute exact path='/finanzas/liquidaciones/ver' component={LiquidacionesDisplay}/>

                    <PrivateRoute exact path='/finanzas/viaticos' component={Viaticos}/>
                    <PrivateRoute exact path='/finanzas/viaticos/crear' component={ViaticosCreate}/>
                    <PrivateRoute exact path='/finanzas/viaticos/actualizar/:id' component={ViaticosUpdate}/>
                    <PrivateRoute exact path='/finanzas/viaticos/ver/:id' component={ViaticosDisplay}/>
                    <PrivateRoute exact path='/finanzas/viaticos/ver' component={ViaticosDisplay}/>

                    <PrivateRoute exact path='/finanzas/salario' component={Salario}/>
                    <PrivateRoute exact path='/finanzas/salario/ver' component={SalarioDisplay}/>

                    <PrivateRoute exact path='/recursoshumanos' component={RecursosHumanos}/>

                    <PrivateRoute exact path='/recursoshumanos/vacaciones' component={Vacaciones}/>
                    <PrivateRoute exact path='/recursoshumanos/vacaciones/crear' component={VacacionesCreate}/>
                    <PrivateRoute exact path='/recursoshumanos/vacaciones/actualizar/:id' component={VacacionesUpdate}/>
                    <PrivateRoute exact path='/recursoshumanos/vacaciones/ver/:id' component={VacacionesDisplay}/>

                    <PrivateRoute exact path='/recursoshumanos/cartaingresos' component={CartaIngresos}/>
                    <PrivateRoute exact path='/recursoshumanos/cartaingresos/crear' component={CartaIngresosCreate}/>
                    <PrivateRoute exact path='/recursoshumanos/cartaingresos/actualizar/:id' component={CartaIngresosUpdate}/>
                    <PrivateRoute exact path='/recursoshumanos/cartaingresos/ver' component={CartaIngresosDisplay}/>

                    {/* 
                    <PrivateRoute exact path='/recursoshumanos/certificadoigss' component={CertificadoIgss}/>
                    <PrivateRoute exact path='/recursoshumanos/certificadoigss/crear' component={CertificadoIgssCreate}/>
                    <PrivateRoute exact path='/recursoshumanos/certificadoigss/actualizar/:id' component={CertificadoIgssUpdate}/>
                    <PrivateRoute exact path='/recursoshumanos/certificadoigss/ver' component={CertificadoIgssDisplay}/>

                    <PrivateRoute exact path='/recursoshumanos/solicitudirtra' component={SolicitudIrtra}/>
                    <PrivateRoute exact path='/recursoshumanos/solicitudirtra/crear' component={SolicitudIrtraCreate}/>
                    <PrivateRoute exact path='/recursoshumanos/solicitudirtra/actualizar/:id' component={SolicitudIrtraUpdate}/>
                    <PrivateRoute exact path='/recursoshumanos/solicitudirtra/ver' component={SolicitudIrtraDisplay}/>
                    */}
                    <PrivateRoute exact path='/recursoshumanos/beneficios' component={Beneficios}/>
                    <PrivateRoute exact path='/recursoshumanos/beneficios/ver' component={BeneficiosDisplay}/>

                    <PrivateRoute exact path='/recursoshumanos/encuestas' component={Encuesta}/>
                    <PrivateRoute exact path='/recursoshumanos/encuestas/ver/:id' component={EncuestaDisplay}/>

                    <PrivateRoute exact path='/seguridadcorporativa/herramientanueva' component={HerramientaNueva}/>
                    <PrivateRoute exact path='/seguridadcorporativa/herramientanueva/crear' component={HerramientaNuevaCreate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/herramientanueva/actualizar/:id' component={HerramientaNuevaUpdate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/herramientanueva/ver' component={HerramientaNuevaDisplay}/>

                    <PrivateRoute exact path='/seguridadcorporativa/solicituduniforme' component={SolicitudUniforme}/>
                    <PrivateRoute exact path='/seguridadcorporativa/solicituduniforme/crear' component={SolicitudUniformeCreate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/solicituduniforme/actualizar/:id' component={SolicitudUniformeUpdate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/solicituduniforme/ver' component={SolicitudUniformeDisplay}/>

                    <PrivateRoute exact path='/seguridadcorporativa/seguromedico' component={SeguroMedico}/>
                    <PrivateRoute exact path='/seguridadcorporativa/seguromedico/crear' component={SeguroMedicoCreate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/seguromedico/actualizar/:id' component={SeguroMedicoUpdate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/seguromedico/ver' component={SeguroMedicoDisplay}/>
                    
                    <PrivateRoute exact path='/seguridadcorporativa/reposicionherramienta' component={ReposicionHerramienta}/>
                    <PrivateRoute exact path='/seguridadcorporativa/reposicionherramienta/crear' component={ReposicionHerramientaCreate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/reposicionherramienta/actualizar/:id' component={ReposicionHerramientaUpdate}/>
                    <PrivateRoute exact path='/seguridadcorporativa/reposicionherramienta/ver' component={ReposicionHerramientaDisplay}/>

                    <PrivateRoute exact path='/seguridadcorporativa' component={SeguridadCorporativa}/>
                    
                    <PrivateRoute exact path='/desarrollohumano/actividades/ver/:id' component={ActividadesDisplay}/>
                    <PrivateRoute exact path='/desarrollohumano/actividades' component={Actividades}/>
                    <PrivateRoute exact path='/desarrollohumano/capacitaciones' component={Capacitaciones}/>
                    <PrivateRoute exact path='/desarrollohumano/encuestas' component={Encuestas}/>
                    
                    <PrivateRoute exact path='/desarrollohumano/descargas' component={Descargas}/>
                    <PrivateRoute exact path='/desarrollohumano/buzon' component={Buzon}/>

                    <PrivateRoute exact path='/desarrollohumano' component={DesarrolloHumano}/>
                    
                    <PrivateRoute exact path='/merchandising/entrada' component={Entrada}/>
                    <PrivateRoute exact path='/merchandising/almuerzo' component={Almuerzo}/>


                    <PrivateRoute exact path='/merchandising/exhibicion' component={Exhibicion}/>
                    <PrivateRoute exact path='/merchandising/exhibicion/crear' component={ExhibicionCreate}/>
                    <PrivateRoute exact path='/merchandising/exhibicion/actualizar/:id' component={ExhibicionUpdate}/>
                    <PrivateRoute exact path='/merchandising/exhibicion/ver' component={ExhibicionDisplay}/>


                    <PrivateRoute exact path='/merchandising/monitoreos' component={Monitoreos}/>
                    <PrivateRoute exact path='/merchandising/monitoreos/crear' component={MonitoreosCreate}/>
                    <PrivateRoute exact path='/merchandising/monitoreos/actualizar/:id' component={MonitoreosUpdate}/>
                    <PrivateRoute exact path='/merchandising/monitoreos/ver' component={MonitoreosDisplay}/>
                    
                    <PrivateRoute exact path='/merchandising/btl' component={Btl}/>
                    <PrivateRoute exact path='/merchandising/btl/crear' component={BtlCreate}/>
                    <PrivateRoute exact path='/merchandising/btl/actualizar/:id' component={BtlUpdate}/>
                    <PrivateRoute exact path='/merchandising/btl/ver' component={BtlDisplay}/>
                    
                    <PrivateRoute exact path='/merchandising/competencia' component={Competencia}/>
                    <PrivateRoute exact path='/merchandising/competencia/crear' component={CompetenciaCreate}/>
                    <PrivateRoute exact path='/merchandising/competencia/actualizar/:id' component={CompetenciaUpdate}/>
                    <PrivateRoute exact path='/merchandising/competencia/ver' component={CompetenciaDisplay}/>
                    
                    <PrivateRoute exact path='/merchandising/venta_promocion' component={VentaPromocion}/>
                    <PrivateRoute exact path='/merchandising/venta_promocion/crear' component={VentaPromocionCreate}/>
                    <PrivateRoute exact path='/merchandising/venta_promocion/actualizar/:id' component={VentaPromocionUpdate}/>
                    <PrivateRoute exact path='/merchandising/venta_promocion/ver' component={VentaPromocionDisplay}/>
                    
                    <PrivateRoute exact path='/merchandising/promocional_material' component={PromocionalMaterial}/>
                    <PrivateRoute exact path='/merchandising/promocional_material/crear' component={PromocionalMaterialCreate}/>
                    <PrivateRoute exact path='/merchandising/promocional_material/actualizar/:id' component={PromocionalMaterialUpdate}/>
                    <PrivateRoute exact path='/merchandising/promocional_material/ver' component={PromocionalMaterialDisplay}/>
                    
                    <PrivateRoute exact path='/merchandising/capacitacion' component={Capacitacion}/>
                    <PrivateRoute exact path='/merchandising/capacitacion/crear' component={CapacitacionCreate}/>
                    <PrivateRoute exact path='/merchandising/capacitacion/actualizar/:id' component={CapacitacionUpdate}/>
                    <PrivateRoute exact path='/merchandising/capacitacion/ver' component={CapacitacionDisplay}/>

                    <PrivateRoute exact path='/organigrama' component={Organigrama}/>

                    <PrivateRoute exact path='/merchandising' component={Merchandising}/>
                    
                    <PrivateRoute path='/perfil' component={Perfil}/>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </Router>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
  

export default connect(mapStateToProps)(Routes);
