import { componentsConstants } from '../constants';

let initialState = {sidebarToggle : true};

export function components(state = initialState, action) {
  switch (action.type) {
    case componentsConstants.SIDEBAR_TOGGLED:
      return {
        sidebarToggle: !state.sidebarToggle
      };
    default:
      return state
  }
}