export const actividadesConstants = {
    SAVE_ACTIVIDADES_REQUEST: 'SAVE_ACTIVIDADES_REQUEST',
    SAVE_ACTIVIDADES_SUCCESS: 'SAVE_ACTIVIDADES_SUCCESS',
    SAVE_ACTIVIDADES_FAILURE: 'SAVE_ACTIVIDADES_FAILURE',

    FETCH_ACTIVIDADES_REQUEST: 'FETCH_ACTIVIDADES_REQUEST',
    FETCH_ACTIVIDADES_SUCCESS: 'FETCH_ACTIVIDADES_SUCCESS',
    FETCH_ACTIVIDADES_FAILURE: 'FETCH_ACTIVIDADES_FAILURE',

    FETCH_ENCUESTAS_REQUEST: 'FETCH_ENCUESTAS_REQUEST',
    FETCH_ENCUESTAS_SUCCESS: 'FETCH_ENCUESTAS_SUCCESS',
    FETCH_ENCUESTAS_FAILURE: 'FETCH_ENCUESTAS_FAILURE',

    SET_MEINTERESA_REQUEST: 'SET_MEINTERESA_REQUEST',
    SET_MEINTERESA_SUCCESS: 'SET_MEINTERESA_SUCCESS',
    SET_MEINTERESA_FAILURE: 'SET_MEINTERESA_FAILURE',

    GET_ACTIVIDAD_REQUEST: 'GET_ACTIVIDAD_REQUEST',
    GET_ACTIVIDAD_SUCCESS: 'GET_ACTIVIDAD_SUCCESS',
    GET_ACTIVIDAD_FAILURE: 'GET_ACTIVIDAD_FAILURE',

    GET_COMENTARIOS_REQUEST: 'GET_COMENTARIOS_REQUEST',
    GET_COMENTARIOS_SUCCESS: 'GET_COMENTARIOS_SUCCESS',
    GET_COMENTARIOS_FAILURE: 'GET_COMENTARIOS_FAILURE',
};
