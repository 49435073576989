export const salariosConstants = {
    SAVE_SALARIO_REQUEST: 'SAVE_SALARIO_REQUEST',
    SAVE_SALARIO_SUCCESS: 'SAVE_SALARIO_SUCCESS',
    SAVE_SALARIO_FAILURE: 'SAVE_SALARIO_FAILURE',

    FETCH_SALARIO_REQUEST: 'FETCH_SALARIO_REQUEST',
    FETCH_SALARIO_SUCCESS: 'FETCH_SALARIO_SUCCESS',
    FETCH_SALARIO_FAILURE: 'FETCH_SALARIO_FAILURE',


    GET_SALARIO_REQUEST: 'GET_SALARIO_REQUEST',
    GET_SALARIO_SUCCESS: 'GET_SALARIO_SUCCESS',
    GET_SALARIO_FAILURE: 'GET_SALARIO_FAILURE',
};
