import {apiUrl} from '../helpers'
export const reposicionherramientaService = {
    guardar,
    actualizar,
    getAll,
    getTipos,
    getMotivos,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/seguridad-corporativa/v1/reposicionherramienta/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getTipos() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Tipo de Herramienta'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getMotivos() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Motivos de Perdida'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + `api/seguridad-corporativa/v1/reposicionherramienta/obtener/${id}`;
    var params = {token: user.access_token};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}


function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));

    var Data = new FormData();
    Data.append('empleado_id',user.id);
    Data.append('tipo_herramienta_id',formData.tipo_herramienta_id);
    Data.append('motivo_id',formData.motivo_id);
    Data.append('fecha_solicitud',formData.fecha_solicitud);
    Data.append('observaciones',formData.observaciones);
    Data.append('denuncia_mp',formData.denuncia_mp);
    Data.append('fotografia',formData.fotografia);
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    return fetch(USER_API_BASE_URL + 'api/seguridad-corporativa/v1/reposicionherramienta/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.id = id;
    formData.token = user.access_token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/seguridad-corporativa/v1/reposicionherramienta/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}