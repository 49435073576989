import { monitoreosConstants } from '../constants';

export function monitoreos(state = {monitoreos: [] ,loading:false}, action) {
  switch (action.type) {
    case monitoreosConstants.FETCH_MONITOREOS_REQUEST:
      return {
        loading: true,
        monitoreos: []
      };
    case monitoreosConstants.FETCH_MONITOREOS_SUCCESS:
      return {
        loading: false,
        monitoreos: action.data
      };
    case monitoreosConstants.FETCH_MONITOREOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}