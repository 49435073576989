import { herramientanuevaConstants } from '../constants';

export function herramientanueva(state = {solicitudes: [], tipo_herramienta: [] ,loading:true}, action) {
  switch (action.type) {
    case herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_REQUEST:
      return {
        loading: true,
        solicitudes: [],
        tipo_herramienta: state.tipo_herramienta
      };
    case herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_SUCCESS:
      return {
        loading: false,
        solicitudes: action.data,
        tipo_herramienta: state.tipo_herramienta
      };
    case herramientanuevaConstants.FETCH_HERRAMIENTANUEVA_FAILURE:
      return { 
        error: action.error
      };
    case herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_REQUEST:
      return {
        loading: true,
        tipo_herramienta: [],
        solicitudes: state.solicitudes
      };
    case herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_SUCCESS:
      return {
        loading: false,
        tipo_herramienta: action.data,
        solicitudes: state.solicitudes
      };
    case herramientanuevaConstants.FETCH_TIPOHERRAMIENTA_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}