import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Actividades'
import {apiUrl} from '../../helpers'
import { alertActions } from '../../actions';

class EncuestaForm extends Component{
    
    constructor(props){
        super(props);
        let preguntas = { };
        for(var field in this.props.preguntas){
            preguntas[this.props.preguntas[field].id] = '';
        }
        this.state = preguntas;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    createMarkup(html) {
        return {__html: html};
    }

    handleChange(e) {    
        let preguntas = this.state;
        preguntas[e.target.name] = e.target.value
        this.setState(preguntas);
    }
    
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    return response.message;
                }
        
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData(event.target);
        let user = JSON.parse(localStorage.getItem('user'));
        formData.token = user.access_token;
        formData.empleado_id = user.id;
        formData.usuario_id = user.id;
        formData.respuestas = this.state;
        formData.actividad_id = this.props.actividad;
        // console.log(formData);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(formData)
        };
        return fetch(apiUrl + 'api/desarrollohumano/v1/actividades/preguntas/guardar', requestOptions)
            .then(this.handleResponse)
            .then(response => {
                const { dispatch } = this.props;
                if(response.status){
                    dispatch(alertActions.success(response.mensaje));
                }else{
                    dispatch(alertActions.success(response.mensaje));
                }
                window.location.reload();
                return response;
        });
    }
    
    
    render(){
        return (
                <form  onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <label htmlFor="nombre">{this.props.encuesta.nombre}</label>
                            <div className="form-group">
                            <div dangerouslySetInnerHTML={this.createMarkup(this.props.encuesta.descripcion)} />
                            </div>
                        </div>
                        {
                            this.props.preguntas.map((pregunta,j) => 
                                <div className="col-12 col-md-12" key={j}>
                                    <div className="form-group">
                                        <label htmlFor={pregunta.id}>{pregunta.pregunta}</label>
                                        {
                                            pregunta.tipo === 'text' ? (
                                                <input type="text" onChange={this.handleChange} name={pregunta.id} className="form-control"/>
                                            ):(
                                                <div className="row  justify-content-center">
                                                    {pregunta.opciones.map((opcion, i)=>
                                                    <div className="col-md-2" key={i}>
                                                        <input onChange={this.handleChange} type={pregunta.tipo} id={i+pregunta.id} value={opcion} name={pregunta.id} required={pregunta.requerida} />
                                                        <label htmlFor={i+pregunta.id}>{opcion}
                                                        </label>
                                                    </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block"  > Guardar </button>
                        </div>
                    </div>
                </form>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(EncuestaForm);