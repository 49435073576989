import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import SolicitudUniformeForm from './SolicitudUniformeForm'
import { Link } from 'react-router-dom'

class SolicitudUniformeCreate extends Component{
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Solicitud de Uniforme</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/seguridadcorporativa/solicituduniforme"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                    <SolicitudUniformeForm />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

export default SolicitudUniformeCreate;