export const dataConstants = {
    SAVE_LIQUIDACIONES_REQUEST: 'SAVE_LIQUIDACIONES_REQUEST',
    SAVE_LIQUIDACIONES_SUCCESS: 'SAVE_LIQUIDACIONES_SUCCESS',
    SAVE_LIQUIDACIONES_FAILURE: 'SAVE_LIQUIDACIONES_FAILURE',

    SAVE_GASTOS_REQUEST: 'SAVE_GASTOS_REQUEST',
    SAVE_GASTOS_SUCCESS: 'SAVE_GASTOS_SUCCESS',
    SAVE_GASTOS_FAILURE: 'SAVE_GASTOS_FAILURE',

    FETCH_LIQUIDACIONES_REQUEST: 'FETCH_LIQUIDACIONES_REQUEST',
    FETCH_LIQUIDACIONES_SUCCESS: 'FETCH_LIQUIDACIONES_SUCCESS',
    FETCH_LIQUIDACIONES_FAILURE: 'FETCH_LIQUIDACIONES_FAILURE',

    GET_LIQUIDACION_REQUEST: 'GET_LIQUIDACION_REQUEST',
    GET_LIQUIDACION_SUCCESS: 'GET_LIQUIDACION_SUCCESS',
    GET_LIQUIDACION_FAILURE: 'GET_LIQUIDACION_FAILURE',

    FETCH_PROVEEDORES_REQUEST: 'FETCH_PROVEEDORES_REQUEST',
    FETCH_PROVEEDORES_SUCCESS: 'FETCH_PROVEEDORES_SUCCESS',
    FETCH_PROVEEDORES_FAILURE: 'FETCH_PROVEEDORES_FAILURE',

    GET_PROVEEDOR_REQUEST: 'GET_PROVEEDOR_REQUEST',
    GET_PROVEEDOR_SUCCESS: 'GET_PROVEEDOR_SUCCESS',
    GET_PROVEEDOR_FAILURE: 'GET_PROVEEDOR_FAILURE',


    FETCH_CADENAS_REQUEST: 'FETCH_CADENAS_REQUEST',
    FETCH_CADENAS_SUCCESS: 'FETCH_CADENAS_SUCCESS',
    FETCH_CADENAS_FAILURE: 'FETCH_CADENAS_FAILURE',

    FETCH_TIENDAS_REQUEST: 'FETCH_TIENDAS_REQUEST',
    FETCH_TIENDAS_SUCCESS: 'FETCH_TIENDAS_SUCCESS',
    FETCH_TIENDAS_FAILURE: 'FETCH_TIENDAS_FAILURE',

    GET_CADENA_REQUEST: 'GET_CADENA_REQUEST',
    GET_CADENA_SUCCESS: 'GET_CADENA_SUCCESS',
    GET_CADENA_FAILURE: 'GET_CADENA_FAILURE',

    GET_TIENDA_REQUEST: 'GET_TIENDA_REQUEST',
    GET_TIENDA_SUCCESS: 'GET_TIENDA_SUCCESS',
    GET_TIENDA_FAILURE: 'GET_TIENDA_FAILURE',

    FETCH_DROPDOWN_REQUEST: 'FETCH_DROPDOWN_REQUEST',
    FETCH_DROPDOWN_SUCCESS: 'FETCH_DROPDOWN_SUCCESS',
    FETCH_DROPDOWN_FAILURE: 'FETCH_DROPDOWN_FAILURE',

    FETCH_CONCEPTOS_REQUEST: 'FETCH_CONCEPTOS_REQUEST',
    FETCH_CONCEPTOS_SUCCESS: 'FETCH_CONCEPTOS_SUCCESS',
    FETCH_CONCEPTOS_FAILURE: 'FETCH_CONCEPTOS_FAILURE',

    FETCH_TIPOSGASOLINA_REQUEST: 'FETCH_TIPOSGASOLINA_REQUEST',
    FETCH_TIPOSGASOLINA_SUCCESS: 'FETCH_TIPOSGASOLINA_SUCCESS',
    FETCH_TIPOSGASOLINA_FAILURE: 'FETCH_TIPOSGASOLINA_FAILURE',

    DELETE_GASTO_REQUEST: 'DELETE_GASTO_REQUEST',
    DELETE_GASTO_SUCCESS: 'DELETE_GASTO_SUCCESS',
    DELETE_GASTO_FAILURE: 'DELETE_GASTO_FAILURE',

    FETCH_GASTOS_REQUEST: 'FETCH_GASTOS_REQUEST',
    FETCH_GASTOS_SUCCESS: 'FETCH_GASTOS_SUCCESS',
    FETCH_GASTOS_FAILURE: 'FETCH_GASTOS_FAILURE',
};
