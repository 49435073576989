import React, {Component} from 'react'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux'
import { componentsActions, userActions } from '../actions';
import { history } from '../helpers';
import {apiUrl} from '../helpers'


class Navbar extends Component{
    toggleSidebar(){
        const { dispatch } = this.props;
        dispatch(componentsActions.toggle());
    }
    logOut(){
        const { dispatch } = this.props;
        dispatch(userActions.logout());
        history.push('/login');
    }
    render(){
        const user = this.props.authentication.user;
        return (
            <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
                <div className="container-fluid">
                <button className="btn btn-link d-md-none rounded-circle mr-3" id="sidebarToggleTop" onClick={() => this.toggleSidebar()} type="button"><i className="fas fa-bars"></i></button>
                    <ul className="nav navbar-nav flex-nowrap ml-auto">
                        {/* <li className="nav-item dropdown d-sm-none no-arrow">
                            <a className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="/#">
                                <i className="fas fa-search"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right p-3 animated--grow-in" role="menu" aria-labelledby="searchDropdown">
                                <form className="form-inline mr-auto navbar-search w-100">
                                    <div className="input-group">
                                        <input className="bg-light form-control border-0 small"  type="text" placeholder="Search for ..."/>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary py-0" type="button">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                        <li className="nav-item dropdown no-arrow mx-1" role="presentation">
                            <div className="nav-item dropdown no-arrow">
                                <a className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="/#">
                                    <span className="badge badge-danger badge-counter"></span>
                                    <i className="fas fa-bell fa-fw"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-list dropdown-menu-right animated--grow-in" role="menu">
                                    <h6 className="dropdown-header">alerts center</h6>
                                    <a className="d-flex align-items-center dropdown-item" href="/#">
                                        <div className="mr-3">
                                            <div className="bg-primary icon-circle"><i className="fas fa-file-alt text-white"></i></div>
                                        </div>
                                        <div><span className="small text-gray-500">December 12, 2019</span>
                                            <p>A new monthly report is ready to download!</p>
                                        </div>
                                    </a>
                                    <a className="text-center dropdown-item small text-gray-500" href="/#">Show All Alerts</a>
                                </div>
                            </div>
                        </li> */}
                        <div className="d-none d-sm-block topbar-divider"></div>
                        <li className="nav-item dropdown no-arrow" role="presentation">
                            <div className="nav-item dropdown no-arrow"><a className="dropdown-toggle nav-link"
                                    data-toggle="dropdown" aria-expanded="false" href="/#"><span
                                        className="d-none d-lg-inline mr-2 text-gray-600 small">{user.name}</span><img
                                        alt="profile img"
                                        className="border rounded-circle img-profile"
                                        src={(user.fotografia !== undefined && user.fotografia !== null && user.fotografia !== '') ? apiUrl + 'storage/uploads/images/'+user.fotografia:'assets/img/user.png'} /></a>
                                <div className="dropdown-menu shadow dropdown-menu-right animated--grow-in" role="menu">
                                    <NavLink to='/perfil' className="dropdown-item" activeClassName='active'><i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Perfil</NavLink>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" role="presentation" onClick={() => this.logOut()} href="/#"><i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Cerrar Sesión</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    const { components, authentication } = state;
    return {
        components,
        authentication
    };
}


export default connect(mapStateToProps)(Navbar);