export const btlConstants = {
    SAVE_BTL_REQUEST: 'SAVE_BTL_REQUEST',
    SAVE_BTL_SUCCESS: 'SAVE_BTL_SUCCESS',
    SAVE_BTL_FAILURE: 'SAVE_BTL_FAILURE',

    FETCH_BTL_REQUEST: 'FETCH_BTL_REQUEST',
    FETCH_BTL_SUCCESS: 'FETCH_BTL_SUCCESS',
    FETCH_BTL_FAILURE: 'FETCH_BTL_FAILURE',


    SET_BTL_REQUEST: 'SET_BTL_REQUEST',
    SET_BTL_SUCCESS: 'SET_BTL_SUCCESS',
    SET_BTL_FAILURE: 'SET_BTL_FAILURE',

    GET_BTL_REQUEST: 'GET_BTL_REQUEST',
    GET_BTL_SUCCESS: 'GET_BTL_SUCCESS',
    GET_BTL_FAILURE: 'GET_BTL_FAILURE',

};
