export const monitoreosConstants = {
    SAVE_MONITOREOS_REQUEST: 'SAVE_MONITOREOS_REQUEST',
    SAVE_MONITOREOS_SUCCESS: 'SAVE_MONITOREOS_SUCCESS',
    SAVE_MONITOREOS_FAILURE: 'SAVE_MONITOREOS_FAILURE',

    FETCH_MONITOREOS_REQUEST: 'FETCH_MONITOREOS_REQUEST',
    FETCH_MONITOREOS_SUCCESS: 'FETCH_MONITOREOS_SUCCESS',
    FETCH_MONITOREOS_FAILURE: 'FETCH_MONITOREOS_FAILURE',


    SET_MONITOREO_REQUEST: 'SET_MONITOREO_REQUEST',
    SET_MONITOREO_SUCCESS: 'SET_MONITOREO_SUCCESS',
    SET_MONITOREO_FAILURE: 'SET_MONITOREO_FAILURE',

    GET_MONITOREO_REQUEST: 'GET_MONITOREO_REQUEST',
    GET_MONITOREO_SUCCESS: 'GET_MONITOREO_SUCCESS',
    GET_MONITOREO_FAILURE: 'GET_MONITOREO_FAILURE',

};
