export const encuestasConstants = {
    SAVE_ENCUESTAS_REQUEST: 'SAVE_ENCUESTAS_REQUEST',
    SAVE_ENCUESTAS_SUCCESS: 'SAVE_ENCUESTAS_SUCCESS',
    SAVE_ENCUESTAS_FAILURE: 'SAVE_ENCUESTAS_FAILURE',

    FETCH_ENCUESTAS_REQUEST: 'FETCH_ENCUESTAS_REQUEST',
    FETCH_ENCUESTAS_SUCCESS: 'FETCH_ENCUESTAS_SUCCESS',
    FETCH_ENCUESTAS_FAILURE: 'FETCH_ENCUESTAS_FAILURE',

    GET_ENCUESTA_REQUEST: 'GET_ENCUESTA_REQUEST',
    GET_ENCUESTA_SUCCESS: 'GET_ENCUESTA_SUCCESS',
    GET_ENCUESTA_FAILURE: 'GET_ENCUESTA_FAILURE',

};
