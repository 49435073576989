export const cartaingresosConstants = {
    SAVE_CARTAINGRESOS_REQUEST: 'SAVE_CARTAINGRESOS_REQUEST',
    SAVE_CARTAINGRESOS_SUCCESS: 'SAVE_CARTAINGRESOS_SUCCESS',
    SAVE_CARTAINGRESOS_FAILURE: 'SAVE_CARTAINGRESOS_FAILURE',

    FETCH_CARTAINGRESOS_REQUEST: 'FETCH_CARTAINGRESOS_REQUEST',
    FETCH_CARTAINGRESOS_SUCCESS: 'FETCH_CARTAINGRESOS_SUCCESS',
    FETCH_CARTAINGRESOS_FAILURE: 'FETCH_CARTAINGRESOS_FAILURE',


    FETCH_TRAMITES_REQUEST: 'FETCH_TRAMITES_REQUEST',
    FETCH_TRAMITES_SUCCESS: 'FETCH_TRAMITES_SUCCESS',
    FETCH_TRAMITES_FAILURE: 'FETCH_TRAMITES_FAILURE',

    FETCH_BANCOS_REQUEST: 'FETCH_BANCOS_REQUEST',
    FETCH_BANCOS_SUCCESS: 'FETCH_BANCOS_SUCCESS',
    FETCH_BANCOS_FAILURE: 'FETCH_BANCOS_FAILURE',

    GET_CARTAINGRESOS_REQUEST: 'GET_CARTAINGRESOS_REQUEST',
    GET_CARTAINGRESOS_SUCCESS: 'GET_CARTAINGRESOS_SUCCESS',
    GET_CARTAINGRESOS_FAILURE: 'GET_CARTAINGRESOS_FAILURE',

};
