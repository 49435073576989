import React, { useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './styles.css'
import getPermissions from '../../helpers/permissions'
let Merchandising = ({dispatch}) => {

    useEffect(() => {
        getPermissions(dispatch)
    },[])
    return (
        <div id="wrapper">
            <Sidebar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <Alert />
                        <div className="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 className="text-dark mb-0">Merchandising</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <Link to="/merchandising/entrada"  className="btn btn-success">Entrada y Salida</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/almuerzo"  className="btn btn-success">Almuerzo</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/exhibicion"  className="btn btn-success">Exhibición</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/monitoreos"  className="btn btn-success">Monitoreos de Precios</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/btl"  className="btn btn-success">BTL</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/competencia"  className="btn btn-success">Competencias</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <Link to="/merchandising/venta_promocion"  className="btn btn-success">Ventas & Promociones</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/promocional_material"  className="btn btn-success">Material Promocional</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to="/merchandising/capacitacion"  className="btn btn-success">Capacitación</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}

Merchandising = connect(mapStateToProps)(Merchandising);
export {Merchandising};