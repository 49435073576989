import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { salarioActions, alertActions } from '../../actions'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { salarioService } from '../../services'

const SalarioSchema = Yup.object().shape({
    aprobado: Yup.boolean()
        .required("Campo requerido"),
    justificacion: Yup.string()
        .when('aprobado',{
            is: false,
            then: Yup.string().required('Campo Requerido'),
            otherwise: Yup.string().nullable()
        }).nullable()
});

class SalarioDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            aprobado: 0,
            justificacion: "",
        };
        this.props.dispatch(salarioActions.getOne(this.props.location.state.id));
    }
    createMarkup(html) {
        return {__html: html};
    }
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Salario</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row pb-3">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/finanzas/salario"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                        {!this.props.loading  ? (
                                        <div className="">
                                            <div className="row pb-3">
                                                <div className="col-md-8 col-12">
                                                    <h3>{this.props.salario ? this.props.salario.nombre : ''}</h3>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Estación</th>
                                                                <th scope="col">{this.props.salario.estacion}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Código Empleado</th>
                                                                <td>{this.props.salario.codigo}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Sueldo Mensual</th>
                                                                <td>{this.props.salario.sueldo_mensual}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Días Laborados</th>
                                                                <td>{this.props.salario.dias_laborados}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Sueldo Devengado</th>
                                                                <td>{this.props.salario.sueldo_devengado}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Horas Extras</th>
                                                                <td>{this.props.salario.horas_extras}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Otros Ingresos</th>
                                                                <td>{this.props.salario.otros_ingresos}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Total Ingresos</th>
                                                                <td>{this.props.salario.total_ingresos}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">IGSS</th>
                                                                <td>{this.props.salario.igss}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Otros Descuentos</th>
                                                                <td>{this.props.salario.otros_descuentos}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Ant. 1Era Quincena</th>
                                                                <td>{this.props.salario.ant_quincena}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">78-89 7-2000 37-2001</th>
                                                                <td>{this.props.salario.incentivo_decreto7889}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Bonificación</th>
                                                                <td>{this.props.salario.bonificacion}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Liquido a Recibir</th>
                                                                <td>{this.props.salario.liquido_recibir}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-4 col-12">

                                                    <Formik
                                                        initialValues={this.props.salario}
                                                        enableReinitialize={true}
                                                        validationSchema={SalarioSchema}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setSubmitting(true);
                                                            values.aprobado = values.aprobado ? 1 : 0 ;
                                                            salarioService.actualizar(values,this.props.location.state.id).then(
                                                                response => {
                                                                    const { dispatch } = this.props;
                                                                    dispatch(alertActions.success(response.mensaje));
                                                                },error => {
                                                                    const { dispatch } = this.props;
                                                                    dispatch(alertActions.success(error));
                                                                }
                                                            );
                                                            setSubmitting(false);
                                                        }}
                                                    >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                                                        <Form>
                                                            <div className="row">
                                                                <div className='col-12 col-md-12'>
                                                                    <div className="form-group">
                                                                        <label htmlFor="aprobado">Aprobado</label>
                                                                        <div className="form-group">
                                                                            <label htmlFor="aprobado1"> <Field id="aprobado1" type="radio" checked={values.aprobado} name="aprobado" onChange={() => {setFieldValue("aprobado", !values.aprobado);}} value={true} className={`form-control no-style ${touched.aprobado && errors.aprobado ? "is-invalid" : "" }`} /> Si</label>
                                                                            <label htmlFor="aprobado2"> <Field id="aprobado2" type="radio" checked={!values.aprobado} name="aprobado" onChange={() => {setFieldValue("aprobado", !values.aprobado);}} value={false} className={`form-control no-style ${touched.aprobado && errors.aprobado ? "is-invalid" : "" }`} /> No</label>
                                                                            <ErrorMessage component="div" name="aprobado" className="invalid-feedback" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                {
                                                                    !values.aprobado &&
                                                                    <div className="form-group">
                                                                        <label htmlFor="justificacion">Justificación por que no aprueba el salario </label>
                                                                        <Field component="textarea" rows="4" name="justificacion" value={values.justificacion || ''} placeholder="Justificación" className={`form-control ${touched.justificacion && errors.justificacion ? "is-invalid" : "" }`} />
                                                                        <ErrorMessage component="div" name="justificacion" className="invalid-feedback" />
                                                                    </div>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                        :
                                        (
                                            <div className="row"></div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { salarios} = state;
    return {
        salario: salarios.salario,
        loading: salarios.loading
    };
}
SalarioDisplay = connect(mapStateToProps)(SalarioDisplay);
export {SalarioDisplay};