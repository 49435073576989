import React, { Component } from 'react'
import { herramientanuevaActions } from '../../actions';
import { connect } from 'react-redux'
import './HerramientaNueva'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    tipo_herramienta_id: Yup.string()
        .required("Campo requerido")
});

class HerramientaNuevaForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            herramienta: {
                tipo_herramienta_id: "",
                observaciones: ""
            }
        };

        const { dispatch } = this.props;
        dispatch(herramientanuevaActions.getTipos());
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/seguridad-corporativa/v1/herramientanueva/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.herramienta.tipo_herramienta_id !== undefined){
                    for (const result of this.props.tipo_herramienta) {
                        if(response.herramienta.tipo_herramienta_id === result.value){
                            response.herramienta.tipo_herramienta_id = result;
                        }
                    }
                }
                this.setState({herramienta: response.herramienta});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.herramienta}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.tipo_herramienta_id = values.tipo_herramienta_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(herramientanuevaActions.guardar(values));
                    }else{
                        dispatch(herramientanuevaActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tipo_herramienta_id">Tramite</label>

                                <MySelect
                                    value={values.tipo_herramienta_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tipo_herramienta_id}
                                    touched={touched.tipo_herramienta_id}
                                    field={'tipo_herramienta_id'}
                                    options={this.props.tipo_herramienta}
                                />
                                <ErrorMessage component="div" name="tipo_herramienta_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, herramientanueva } = state;
    return {
        alert,
        tipo_herramienta: herramientanueva.tipo_herramienta
    };
}


export default connect(mapStateToProps)(HerramientaNuevaForm);