import React, { Component } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Navbar from '../../../layout/Navbar'
import Footer from '../../../layout/Footer'
import Alert from '../../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { beneficiosService } from '../../../services'
import { apiUrl } from '../../../helpers'

class BeneficiosDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            beneficio: {}
        };
        beneficiosService.getOne(this.props.history.location.state.id).then(response => {
            if(response.status){
                console.log(response);
                this.setState({beneficio: response.beneficio})
            }else{
                console.log(response.mensaje);
            }
        });
    }
    createMarkup(html) {
        return {__html: html};
    }
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Beneficios</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row pb-3">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/recursoshumanos/beneficios"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                        <div className="">
                                            <div className="row pb-3">
                                                    <div className="col-md-8 col-12">
                                                        <h3>{this.state.beneficio ? this.state.beneficio.nombre : ''}</h3>
                                                        <div dangerouslySetInnerHTML={this.createMarkup(this.state.beneficio.descripcion ? this.state.beneficio.descripcion : '')} />
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <p>
                                                            Disponible desde el: {this.state.beneficio.fecha_inicio} al {this.state.beneficio.fecha_fin}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-1 col-12">
                                                        <a rel="noopener noreferrer" target="_blank" href={apiUrl+"storage/uploads/files/"+this.state.beneficio.archivo} className="btn btn-primary">Ver</a>
                                                    </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { beneficios} = state;
    return {
        actividad: beneficios.actividad,
        loading: beneficios.loading
    };
}
BeneficiosDisplay = connect(mapStateToProps)(BeneficiosDisplay);
export {BeneficiosDisplay};