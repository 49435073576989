export const viaticosConstants = {
    SAVE_VIATICOS_REQUEST: 'SAVE_VIATICOS_REQUEST',
    SAVE_VIATICOS_SUCCESS: 'SAVE_VIATICOS_SUCCESS',
    SAVE_VIATICOS_FAILURE: 'SAVE_VIATICOS_FAILURE',

    FETCH_VIATICOS_REQUEST: 'FETCH_VIATICOS_REQUEST',
    FETCH_VIATICOS_SUCCESS: 'FETCH_VIATICOS_SUCCESS',
    FETCH_VIATICOS_FAILURE: 'FETCH_VIATICOS_FAILURE',

    GET_VIATICO_REQUEST: 'GET_VIATICO_REQUEST',
    GET_VIATICO_SUCCESS: 'GET_VIATICO_SUCCESS',
    GET_VIATICO_FAILURE: 'GET_VIATICO_FAILURE',

};
