import React, { Component } from 'react'
import Sidebar from '../../../layout/Sidebar'
import Navbar from '../../../layout/Navbar'
import Footer from '../../../layout/Footer'
import Alert from '../../../components/alerts/Alert'
import { Link } from 'react-router-dom'
import { vacacionesService } from '../../../services'
import { apiUrl } from '../../../helpers'

class VacacionesDisplay extends Component{
    constructor(props){
        super(props);
        this.state = {
            vacaciones: {},
            archivo_vacaciones: {},
            archivo_igss: {},
            
            loaded: false,
        }
        vacacionesService.getOne(this.props.match.params.id).then(
            response => {
                if(response.status){
                    console.log(response);
                    this.setState({vacaciones: response.data, loaded: true, archivo_igss: response.archivo_igss, archivo_vacaciones: response.archivo_vacaciones});
                }else{
                }
            },
        );
    }

    subirConstancia(e) {
        let Data = new FormData();
        Data.append('id',this.props.match.params.id);
        if(e.target.files[0]){
            Data.append('archivo_firmado',e.target.files[0]);
        }
        this.setState({loaded:false});
        vacacionesService.guardarArchivo(Data).then(response => {
            if(response.status){
                this.setState({vacaciones: response.data, loaded: true});
            }else{
            }
        },
        error => {
            console.log(error)
        });
    }


    subirExtra(e) {
        let Data = new FormData();
        Data.append('id',this.props.match.params.id);
        if(e.target.files[0]){
            Data.append('archivo_extra',e.target.files[0]);
        }
        this.setState({loaded:false});
        vacacionesService.guardarArchivo(Data).then(response => {
            if(response.status){
                this.setState({vacaciones: response.data, loaded: true});
            }else{
            }
        },
        error => {
            console.log(error)
        });
    }

    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Acción de Personal</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <Link to="/recursoshumanos/vacaciones"  className="btn btn-success">Regresar</Link>
                                        </div>
                                    </div>
                                    {
                                        this.state.loaded &&
                                        <div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    Fecha Salida: {this.state.vacaciones.fecha_salida !== undefined ? this.state.vacaciones.fecha_salida : ''}
                                                </div>
                                                <div className="col-md-4">
                                                    Fecha Regreso: {this.state.vacaciones.fecha_regreso}
                                                </div>
                                                <div className="col-md-4">
                                                    Días: {this.state.vacaciones.dias}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    Comentario: {this.state.vacaciones.comentarios}
                                                </div>
                                                <div className="col-md-6">
                                                    Motivo: {(this.state.vacaciones.movimiento !== null  ) ? this.state.vacaciones.movimiento.value : '' }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    Acepta que todos los datos proporcionados son correctos: {this.state.vacaciones.acepta_empleado ? 'Acepto' : 'No Acepto'}
                                                </div>
                                            </div>
                                            <div className="row">
                                                    <div className="col-md-3">
                                                        {/* {
                                                            this.state.vacaciones.movimiento !== null && this.state.vacaciones.movimiento.value === 'Suspensión IGSS' && this.state.archivo_vacaciones && this.state.archivo_vacaciones.archivo !== '' ?
                                                            <a href={apiUrl+`storage/uploads/files/${this.state.archivo_igss.archivo}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                                            :
                                                            <a href={apiUrl+`storage/uploads/files/${this.state.archivo_vacaciones.archivo}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para firmar</a>
                                                        } */}
                                                    </div>
                                                    {
                                                        this.state.vacaciones.archivo_firmado &&
                                                        <div className="col-md-3">
                                                            <a href={apiUrl+`storage/uploads/images/${this.state.vacaciones.archivo_firmado}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo firmado</a>
                                                        </div>
                                                    }
                                            </div>
                                            {
                                                this.state.vacaciones.acepta_rrhh && this.state.vacaciones.acepta_jefe &&
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label htmlFor="archivo_firmado">Subir archivo para firmar</label>
                                                        <input type="file" id="archivo_firmado" name="archivo_firmado" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" onChange={this.subirConstancia.bind(this)} />
                                                        {
                                                            this.state.vacaciones.tipo !== null && this.state.vacaciones.tipo.value === 'Licencia con goce de salario' &&
                                                            <div>
                                                                <label htmlFor="archivo_firmado">Subir Archivo extra</label>
                                                                <input type="file" id="archivo_extra" name="archivo_extra" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" onChange={this.subirExtra.bind(this)} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

export {VacacionesDisplay};