import { beneficiosConstants } from '../constants';
import { beneficiosService } from '../services';
import { alertActions } from './';

export const beneficiosActions = {
    getAll,
    aplicar,
    noAplicar,
    getOne
};

function aplicar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        beneficiosService.aplicar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        dispatch(alertActions.success(response.mensaje));
                        dispatch(beneficiosActions.getAll());
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(beneficios) { return { type: beneficiosConstants.APLICAR_REQUEST, beneficios } }
    function success(beneficios) { return { type: beneficiosConstants.APLICAR_SUCCESS, beneficios } }
    function failure(error) { return { type: beneficiosConstants.APLICAR_FAILURE, error } }
}

function noAplicar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        beneficiosService.noAplicar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        dispatch(alertActions.success(response.mensaje));
                        dispatch(beneficiosActions.getAll());
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(beneficios) { return { type: beneficiosConstants.APLICAR_REQUEST, beneficios } }
    function success(beneficios) { return { type: beneficiosConstants.APLICAR_SUCCESS, beneficios } }
    function failure(error) { return { type: beneficiosConstants.APLICAR_FAILURE, error } }
}
function getAll() {
    return dispatch => {
        dispatch(request({}));

        beneficiosService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: beneficiosConstants.FETCH_BENEFICIOS_REQUEST, data } }
    function success(data) { return { type: beneficiosConstants.FETCH_BENEFICIOS_SUCCESS, data } }
    function failure(error) { return { type: beneficiosConstants.FETCH_BENEFICIOS_FAILURE, error } }
}

function getOne(beneficios) {
    return dispatch => {
        dispatch(request());

        beneficiosService.getOne(beneficios)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: beneficiosConstants.GET_VIATICO_REQUEST, data } }
    function success(data) { return { type: beneficiosConstants.GET_VIATICO_SUCCESS, data } }
    function failure(error) { return { type: beneficiosConstants.GET_VIATICO_FAILURE, error } }
}