import {apiUrl} from '../helpers'
export const capacitacionService = {
    guardar,
    actualizar,
    getAll,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/merchandising/v1/capacitacion/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/merchandising/v1/capacitacion/obtener/${id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('empleado_id',user.id);
    Data.append('capacitador_id',formData.capacitador_id);
    Data.append('tipo_id',formData.tipo_id);
    Data.append('tema_id',formData.tema_id);
    Data.append('medio_id',formData.medio_id);
    Data.append('asistencia_id',formData.asistencia_id);
    Data.append('longitud',formData.longitud);
    Data.append('latitud',formData.latitud);
    if(formData.lugar){
    Data.append('lugar',formData.lugar);
    }
    if(formData.fecha){
    Data.append('fecha',formData.fecha);
    }
    if(formData.observaciones){
    Data.append('observaciones',formData.observaciones);
    }
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/capacitacion/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('id',id);
    Data.append('empleado_id',user.id);
    Data.append('capacitador_id',formData.capacitador_id);
    Data.append('tipo_id',formData.tipo_id);
    Data.append('tema_id',formData.tema_id);
    Data.append('medio_id',formData.medio_id);
    Data.append('asistencia_id',formData.asistencia_id);
    if(formData.lugar){
    Data.append('lugar',formData.lugar);
    }
    if(formData.fecha){
    Data.append('fecha',formData.fecha);
    }
    if(formData.observaciones){
    Data.append('observaciones',formData.observaciones);
    }
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/capacitacion/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}