import { capacitacionConstants } from '../constants';

export function capacitacion(state = {capacitaciones: [] ,loading:false}, action) {
  switch (action.type) {
    case capacitacionConstants.FETCH_CAPACITACION_REQUEST:
      return {
        loading: true,
        capacitaciones: []
      };
    case capacitacionConstants.FETCH_CAPACITACION_SUCCESS:
      return {
        loading: false,
        capacitaciones: action.data
      };
    case capacitacionConstants.FETCH_CAPACITACION_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}