import {apiUrl} from '../helpers'
export const monitoreosService = {
    guardar,
    actualizar,
    getAll,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/merchandising/v1/monitoreos/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/merchandising/v1/monitoreos/obtener/${id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    // formData.token = user.access_token;
    // formData.empleado_id = user.id;
    let Data = new FormData();
    Data.append('empleado_id',user.id);
    Data.append('cadena',formData.cadena);
    Data.append('tienda',formData.tienda);
    Data.append('marca',formData.marca);
    Data.append('categoria',formData.categoria);
    Data.append('subcategoria',formData.subcategoria);
    Data.append('item',formData.item);
    Data.append('producto',formData.producto);
    Data.append('tamano',formData.tamano);
    Data.append('mes',formData.mes.value);
    
    if(formData.precio_normal){
        Data.append('precio_normal',formData.precio_normal);
    }
    if(formData.precio_oferta){
        Data.append('precio_oferta',formData.precio_oferta);
    }
    if(formData.precio_promocion){
        Data.append('precio_promocion',formData.precio_promocion);
    }
    if(formData.promocional){
        Data.append('promocional',formData.promocional);
    }
    Data.append('n_a',(formData.n_a) ? 1 : 0);
    if(formData.fecha_inicio){
        Data.append('fecha_inicio',formData.fecha_inicio);
    }
    if(formData.fecha_fin){
        Data.append('fecha_fin',formData.fecha_fin);
    }
    Data.append('observaciones',formData.observaciones);
    Data.append('latitud',formData.latitud);
    Data.append('longitud',formData.longitud);
    if(formData.fotografia && formData.fotografia.name !== undefined){
        Data.append('fotografia',formData.fotografia);
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/monitoreos/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('id',id);
    Data.append('empleado_id',user.id);
    Data.append('cadena',formData.cadena);
    Data.append('tienda',formData.tienda);
    Data.append('marca',formData.marca);
    Data.append('categoria',formData.categoria);
    Data.append('subcategoria',formData.subcategoria);
    Data.append('item',formData.item);
    Data.append('producto',formData.producto);
    Data.append('tamano',formData.tamano);
    Data.append('mes',formData.mes);
    if(formData.precio_normal){
        Data.append('precio_normal',formData.precio_normal);
    }
    if(formData.precio_oferta){
        Data.append('precio_oferta',formData.precio_oferta);
    }
    if(formData.precio_promocion){
        Data.append('precio_promocion',formData.precio_promocion);
    }
    if(formData.promocional){
        Data.append('promocional',formData.promocional);
    }
    Data.append('n_a',(formData.n_a) ? 1 : 0);
    if(formData.fecha_inicio){
        Data.append('fecha_inicio',formData.fecha_inicio);
    }
    if(formData.fecha_fin){
        Data.append('fecha_fin',formData.fecha_fin);
    }
    Data.append('observaciones',formData.observaciones);
   
    if(formData.fotografia && formData.fotografia.name !== undefined){
        Data.append('fotografia',formData.fotografia);
    }
    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/monitoreos/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}