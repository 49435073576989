import React, { Component } from 'react'
import { perfilActions } from '../../actions';
import { perfilService } from '../../services';
import { connect } from 'react-redux'
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';

const PerfilSchema = Yup.object().shape({
    nombre: Yup.string()
        .required("Campo requerido"),
    apellido: Yup.string()
        .required("Campo requerido")
});

class PerfilForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            perfil: {
                nombre: "",
                apellido: "",
                telefono: "",
                telefono2: "",
                telefono3: "",
                hijos: 0,
                numero_personas_reside: 0,
                estado_civil: "",
                nivel_academico: "",
                direccion_residencial:"",
                contacto_emergencia:"",
                telefono_emergencia: "",
                parentesco: "",
                enfermedad_cronica:"",
                peso:"",
                estatura:"",
                fecha_matrimonio:"",
                fecha_vencimiento_pasaporte: "",
                fecha_vencimiento_dpi:"",
                fecha_vencimiento_visa_americana: "",
                fecha_vencimiento_irtra : "",
                fecha_vencimiento_carro: "",
                fecha_vencimiento_moto: "",
                pasaporte:"",
                irtra: "",
                licencia_carro:"",
                licencia_moto:"",
            },
            estados_civiles: [],
            niveles_academicos: [],
            parentescos: [],
        };
    }

    componentDidMount(){  
        perfilService.getPerfil().then(result => {
            if(result.status){
                console.log(result.data);
                let estado_civil = result.data.estados_civiles.filter((value, index)=> value.value === result.data.perfil.estado_civil);
                let nivel_academico = result.data.niveles_academicos.filter((value, index)=> value.value === result.data.perfil.nivel_academico);
                let parentesco = result.data.parentescos.filter((value, index)=> value.value === result.data.perfil.parentesco);
                result.data.perfil.fecha_matrimonio = result.data.perfil.fecha_matrimonio === null ? '' : result.data.perfil.fecha_matrimonio;
                result.data.perfil.fecha_vencimiento_pasaporte = result.data.perfil.fecha_vencimiento_pasaporte === null ? '' : result.data.perfil.fecha_vencimiento_pasaporte;
                result.data.perfil.fecha_vencimiento_dpi = result.data.perfil.fecha_vencimiento_dpi === null ? '' : result.data.perfil.fecha_vencimiento_dpi;
                result.data.perfil.fecha_vencimiento_visa_americana = result.data.perfil.fecha_vencimiento_visa_americana === null ? '' : result.data.perfil.fecha_vencimiento_visa_americana;
                result.data.perfil.fecha_vencimiento_irtra = result.data.perfil.fecha_vencimiento_irtra === null ? '' : result.data.perfil.fecha_vencimiento_irtra;
                result.data.perfil.fecha_vencimiento_carro = result.data.perfil.fecha_vencimiento_carro === null ? '' : result.data.perfil.fecha_vencimiento_carro;
                result.data.perfil.fecha_vencimiento_moto = result.data.perfil.fecha_vencimiento_moto === null ? '' : result.data.perfil.fecha_vencimiento_moto;
                result.data.perfil.hijos = result.data.perfil.hijos === null ? '' : result.data.perfil.hijos;
                if(estado_civil[0] !== undefined){
                    result.data.perfil.estado_civil = estado_civil[0];
                }
                if(nivel_academico[0] !== undefined){
                    result.data.perfil.nivel_academico = nivel_academico[0];
                }
                if(parentesco[0] !== undefined){
                    result.data.perfil.parentesco = parentesco[0];
                }
                this.setState({
                    perfil: result.data.perfil,
                    estados_civiles: result.data.estados_civiles,
                    niveles_academicos: result.data.niveles_academicos,
                    parentescos: result.data.parentescos,
                });
            }
        } );
    }

    render(){
        return (
            <Formik
                initialValues={this.state.perfil}
                enableReinitialize={true}
                validationSchema={PerfilSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    let estado_civil = values.estado_civil;
                    let nivel_academico = values.nivel_academico;
                    let parentesco = values.parentesco;
                    if( values.estado_civil && values.estado_civil.value !== undefined){
                        values.estado_civil = values.estado_civil.value;
                    }
                    if( values.nivel_academico && values.nivel_academico.value !== undefined){
                        values.nivel_academico = values.nivel_academico.value;
                    }
                    if( values.parentesco && values.parentesco.value !== undefined){
                        values.parentesco = values.parentesco.value;
                    }
                    dispatch(perfilActions.actualizar(values));
                    if(values.estado_civil !== undefined){
                        values.estado_civil = estado_civil;
                    }
                    if(values.nivel_academico !== undefined){
                        values.nivel_academico = nivel_academico;
                    }
                    if(values.parentesco !== undefined){
                        values.parentesco = parentesco;
                    }
                    setSubmitting(false);
                }}
            >{({ touched, errors, isSubmitting, values, setFieldTouched, setFieldValue }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="nombre">Nombre: {this.state.perfil !== undefined && this.state.perfil.nombre !== undefined && this.state.perfil.nombre}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="apellido">Apellido: {this.state.perfil !== undefined && this.state.perfil.apellido !== undefined && this.state.perfil.apellido}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="telefono">Teléfono </label>
                                    <Field type="text" name="telefono" placeholder="Teléfono" className={`form-control ${touched.telefono && errors.telefono ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="telefono" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="direccion_residencial">Dirección </label>
                                    <Field type="text" name="direccion_residencial" placeholder="Dirección" className={`form-control ${touched.direccion_residencial && errors.direccion_residencial ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="direccion_residencial" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="estado_civil">Estado Civil </label>

                                    <MySelect
                                        value={values.estado_civil||''}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.estado_civil}
                                        touched={touched.estado_civil}
                                        field={'estado_civil'}
                                        options={this.state.estados_civiles}
                                    />
                                    {/* <Field type="text" name="estado_civil" placeholder="Apellido" className={`form-control ${touched.estado_civil && errors.estado_civil ? "is-invalid" : "" }`} /> */}
                                    <ErrorMessage component="div" name="estado_civil" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_matrimonio">Fecha de Matrimonio</label>
                                    <Field type="date" name="fecha_matrimonio" className={`form-control ${touched.fecha_matrimonio && errors.fecha_matrimonio ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_matrimonio" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="hijos">Cantidad de Hijos </label>
                                    <Field type="number" name="hijos" placeholder="0" value={values.hijos || ''} className={`form-control ${touched.hijos && errors.hijos ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="hijos" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="numero_personas_reside">Cantidad de personas con las que reside </label>
                                    <Field type="number" name="numero_personas_reside" value={values.numero_personas_reside || ''} placeholder="0" className={`form-control ${touched.numero_personas_reside && errors.numero_personas_reside ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="numero_personas_reside" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="nivel_academico">Escolaridad </label>
                                    <MySelect
                                        value={values.nivel_academico||''}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.nivel_academico}
                                        touched={touched.nivel_academico}
                                        field={'nivel_academico'}
                                        options={this.state.niveles_academicos}
                                    />
                                    {/* <Field type="text" name="nivel_academico" placeholder="Apellido" className={`form-control ${touched.nivel_academico && errors.nivel_academico ? "is-invalid" : "" }`} /> */}
                                    <ErrorMessage component="div" name="nivel_academico" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="telefono2">Teléfono de Casa</label>
                                    <Field type="text" name="telefono2" placeholder="Teléfono de Casa" value={values.telefono2 || ''}  className={`form-control ${touched.telefono2 && errors.telefono2 ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="telefono2" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="telefono3">Teléfono Corporativo</label>
                                    <Field type="text" name="telefono3" placeholder="Teléfono Corporativo" value={values.telefono3 || ''} className={`form-control ${touched.telefono3 && errors.telefono3 ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="telefono3" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="pasaporte">Número de Pasaporte</label>
                                    <Field type="text" name="pasaporte" placeholder="Número de Pasaporte" value={values.pasaporte || ''} className={`form-control ${touched.pasaporte && errors.pasaporte ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="pasaporte" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_pasaporte">Fecha de Vencimiento de Pasaporte</label>
                                    <Field type="date" name="fecha_vencimiento_pasaporte" className={`form-control ${touched.fecha_vencimiento_pasaporte && errors.fecha_vencimiento_pasaporte ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_vencimiento_pasaporte" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_dpi">Fecha de Vencimiento de DPI</label>
                                    <Field type="date" name="fecha_vencimiento_dpi" className={`form-control ${touched.fecha_vencimiento_dpi && errors.fecha_vencimiento_dpi ? "is-invalid" : "" }`} required />
                                    <ErrorMessage component="div" name="fecha_vencimiento_dpi" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_visa_americana">Fecha de Vencimiento de Visa</label>
                                    <Field type="date" name="fecha_vencimiento_visa_americana" className={`form-control ${touched.fecha_vencimiento_visa_americana && errors.fecha_vencimiento_visa_americana ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_vencimiento_visa_americana" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="irtra">Número de IRTRA</label>
                                    <Field type="text" name="irtra" placeholder="Número de IRTRA" value={values.irtra || ''} className={`form-control ${touched.irtra && errors.irtra ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="irtra" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_irtra">Fecha de Vencimiento de IRTRA</label>
                                    <Field type="date" name="fecha_vencimiento_irtra" className={`form-control ${touched.fecha_vencimiento_irtra && errors.fecha_vencimiento_irtra ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_vencimiento_irtra" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="licencia_carro">Número de Licencia de Carro</label>
                                    <Field type="text" name="licencia_carro" placeholder="Número de Licencia de Carro" value={values.licencia_carro ||''} className={`form-control ${touched.licencia_carro && errors.licencia_carro ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="licencia_carro" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_carro">Fecha de Vencimiento de Licencia de Carro</label>
                                    <Field type="date" name="fecha_vencimiento_carro" className={`form-control ${touched.fecha_vencimiento_carro && errors.fecha_vencimiento_carro ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_vencimiento_carro" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="licencia_moto">Número de Licencia de Motocicleta</label>
                                    <Field type="text" name="licencia_moto" placeholder="Número de Licencia de Motocicleta" value={values.licencia_moto || ''} className={`form-control ${touched.licencia_moto && errors.licencia_moto ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="licencia_moto" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="fecha_vencimiento_moto">Fecha de Vencimiento de Licencia de Motocicleta</label>
                                    <Field type="date" name="fecha_vencimiento_moto" className={`form-control ${touched.fecha_vencimiento_moto && errors.fecha_vencimiento_moto ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="fecha_vencimiento_moto" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <p>Contacto de Emergencia</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="contacto_emergencia">Contacto de Emergencia </label>
                                    <Field type="text" name="contacto_emergencia" value={values.contacto_emergencia || ''} placeholder="Contacto de Emergencia" className={`form-control ${touched.contacto_emergencia && errors.contacto_emergencia ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="contacto_emergencia" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="parentesco">Parentesco </label>
                                    <MySelect
                                        value={values.parentesco||''}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.parentesco}
                                        touched={touched.parentesco}
                                        field={'parentesco'}
                                        options={this.state.parentescos}
                                    />
                                    {/* <Field type="text" name="parentesco" value={values.parentesco || ''} placeholder="Parentesco" className={`form-control ${touched.parentesco && errors.parentesco ? "is-invalid" : "" }`} /> */}
                                    <ErrorMessage component="div" name="parentesco" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="telefono_emergencia">Teléfono </label>
                                    <Field type="text" name="telefono_emergencia" value={values.telefono_emergencia || ''} placeholder="Teléfono" className={`form-control ${touched.telefono_emergencia && errors.telefono_emergencia ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="telefono_emergencia" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <p>Enfermedades</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="enfermedad_cronica">Enfermedad Crónica </label>
                                    <Field type="text" name="enfermedad_cronica" value={values.enfermedad_cronica || ''} placeholder="Enfermedad Crónica" className={`form-control ${touched.enfermedad_cronica && errors.enfermedad_cronica ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="enfermedad_cronica" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="estatura">Estatura </label>
                                    <Field type="text" name="estatura" value={values.estatura || ''} placeholder="Estatura" className={`form-control ${touched.estatura && errors.estatura ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="estatura" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="peso">Peso </label>
                                    <Field type="text" name="peso" value={values.peso || ''} placeholder="Peso" className={`form-control ${touched.peso && errors.peso ? "is-invalid" : "" }`} />
                                    <ErrorMessage component="div" name="peso" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, perfil } = state;
    return {
        alert,
        perfil: perfil.perfil
    };
}


export default connect(mapStateToProps)(PerfilForm);