import React, { Component } from 'react'
import { viaticosActions, proveedoresActions } from '../../actions';
import { connect } from 'react-redux'
import './Viaticos'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    fecha_salida: Yup.date()
        .required("Campo requerido"),
    fecha_llegada: Yup.date()
        .required("Campo requerido"),
    motivo: Yup.string()
        .min(3,"Minimo 3 Caracteres")
        .required("Campo requerido"),
    lugar_visitar: Yup.string()
        .min(3,"Minimo 3 Caracteres")
        .required("Campo requerido"),
    forma_remota: Yup.boolean()
        .required("Campo requerido"),
    forma_remota_explicacion: Yup.string()
        .when('forma_remota', {
            is: true, // alternatively: (val) => val == true
            then: Yup.string().required("Campo Requerido"),
            otherwise: Yup.string().nullable(),
          }),
    viaje_presupuestado: Yup.boolean()
        .required("Campo requerido"),
    transporte: Yup.number()
        .required('Campo requerido'),
    hospedaje: Yup.number()
        .required('Campo requerido'),
    alimentacion: Yup.number()
        .required('Campo requerido'),
    otros: Yup.number()
        .required('Campo requerido'),
    explique: Yup.string()
            .when('otros', {
                is: (val) => val > 0, // alternatively: (val) => val == true
                then: Yup.string().required("Campo Requerido"),
                otherwise: Yup.string().nullable()
            }),
});

class ViaticosForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            viatico: {
                motivo: "",
                fecha_salida: "", 
                fecha_llegada: "", 
                dias: 0,
                lugar_visitar: "",
                forma_remota: false,
                forma_remota_explicacion: "",
                viaje_presupuestado: false,
                transporte:0,
                hospedaje:0,
                alimentacion:0,
                otros:0,
                combustible: 0,
                explique:"",
                moneda: ""
            },
            monedas: [
                { label: 'Quetzales', value: 'Quetzales' },
                { label: 'Dolares', value: 'Dolares' },
                { label: 'Euros', value: 'Euros' },
                { label: 'Pesos Mexicanos', value: 'Pesos Mexicanos' },
            ],
            tipoMoneda: 'Q',
        };

        const { dispatch } = this.props;
        dispatch(proveedoresActions.getAll());
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/finanzas/v1/viaticos/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                let moneda_selected = "";
                if(response.viatico.moneda !== undefined){
                    moneda_selected = this.state.monedas.filter(function(val,index){
                        return val.value === response.viatico.moneda;
                    });
                }
                response.viatico.moneda = moneda_selected;
                this.setState({viatico: response.viatico});
            });
        }
    }
    render(){
        const user = JSON.parse(localStorage.getItem('user'));
        return (
            <Formik
                initialValues={this.state.viatico}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    if(values.moneda.value !== undefined){
                        values.moneda = values.moneda.value;
                    }
                    if(this.props.id === undefined){
                        dispatch(viaticosActions.guardar(values));
                    }else{
                        dispatch(viaticosActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue, setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <label htmlFor="nombre">Nombre</label>
                            <div className="form-group">
                            <p>{user.nombre} {user.apellido}</p> 
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="motivo">Motivo</label>
                                <Field type="text" name="motivo" placeholder="Motivo de los Viaticos" className={`form-control ${touched.motivo && errors.motivo ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="motivo" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fecha_salida">Fecha Inicio</label>
                                <Field type="date" name="fecha_salida" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_salida && errors.fecha_salida ? "is-invalid" : ""}`} />
                                <ErrorMessage component="div" name="fecha_salida" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fecha_llegada">Fecha Fin</label>
                                <Field type="date" name="fecha_llegada" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_llegada && errors.fecha_llegada ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha_llegada" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="dias">Dias</label>
                                <Field type="number" name="dias" className={`form-control ${touched.dias && errors.dias ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="dias" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="lugar_visitar">Lugar a Visitar</label>
                                <Field type="text" name="lugar_visitar" placeholder="Lugar a Visitar de los Viaticos" className={`form-control ${touched.lugar_visitar && errors.lugar_visitar ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="lugar_visitar" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Viaje Presupuestado</label>
                            <div className="form-group">
                                <Field type="radio" name="viaje_presupuestado" onChange={() => setFieldValue("viaje_presupuestado", true)} checked={values.viaje_presupuestado === true} value={true} id="frmchk1" className={`form-control no-style ${touched.viaje_presupuestado && errors.viaje_presupuestado ? "is-invalid" : "" }`} />  <label htmlFor="frmchk1">Si</label>
                                <Field type="radio" name="viaje_presupuestado" onChange={() => setFieldValue("viaje_presupuestado", false)} checked={values.viaje_presupuestado === false} value={false} id="frmchk2" className={`form-control no-style ${touched.viaje_presupuestado && errors.viaje_presupuestado ? "is-invalid" : "" }`} />  <label htmlFor="frmchk2">No</label>
                                <ErrorMessage component="div" name="viaje_presupuestado" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="moneda">Moneda</label>
                                <MySelect
                                    field={'moneda'}
                                    value={values.moneda}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.moneda}
                                    touched={touched.moneda}
                                    options={this.state.monedas}
                                />
                                <ErrorMessage component="div" name="moneda" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Forma Remota</label>                            
                            <div className="form-group">
                                <Field type="radio" name="forma_remota" onChange={() => setFieldValue("forma_remota", true)} checked={values.forma_remota === true} value={true} id="frmchk3" className={`form-control no-style ${touched.forma_remota && errors.forma_remota ? "is-invalid" : "" }`} />  <label htmlFor="frmchk3">Si</label>
                                <Field type="radio" name="forma_remota" onChange={() => setFieldValue("forma_remota", false)} checked={values.forma_remota === false} value={false} id="frmchk4" className={`form-control no-style ${touched.forma_remota && errors.forma_remota ? "is-invalid" : "" }`} />  <label htmlFor="frmchk4">No</label>
                                <ErrorMessage component="div" name="forma_remota" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="forma_remota_explicacion">Explicacion</label>
                                <Field type="text" name="forma_remota_explicacion" value={values.forma_remota_explicacion === null || values.forma_remota_explicacion === undefined ? '' : values.forma_remota_explicacion} placeholder="Explicación de Forma Remota" className={`form-control ${touched.forma_remota_explicacion && errors.forma_remota_explicacion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="forma_remota_explicacion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="combustible">Combustible</label>
                                <Field type="number" name="combustible" className={`form-control ${touched.combustible && errors.combustible ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="combustible" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="transporte">Transporte</label>
                                <Field type="number" name="transporte" className={`form-control ${touched.transporte && errors.transporte ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="transporte" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="hospedaje">Hospedaje</label>
                                <Field type="number" name="hospedaje" className={`form-control ${touched.hospedaje && errors.hospedaje ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="hospedaje" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="alimentacion">Alimentación</label>
                                <Field type="number" name="alimentacion" className={`form-control ${touched.alimentacion && errors.alimentacion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="alimentacion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label htmlFor="otros">Otros</label>
                                <Field type="number" name="otros" className={`form-control ${touched.otros && errors.otros ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="otros" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="explique">Explique los otros gastos</label>
                                <Field type="text" name="explique" value={values.explique === null || values.explique === undefined ? '' : values.explique} placeholder="Otros Gastos" className={`form-control ${touched.explique && errors.explique ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="explique" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}


export default connect(mapStateToProps)(ViaticosForm);