import { solicituduniformeConstants } from '../constants';

export function solicituduniforme(state = {solicitudes: [], tallas_camisa: [], tallas_pantalon: [], colores: [], loading:true}, action) {
  switch (action.type) {
    case solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_REQUEST:
      return {
        loading: true,
        solicitudes: [],
        tallas_camisa: state.tallas_camisa,
        tallas_pantalon: state.tallas_pantalon,
        colores: state.colores
      };
    case solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_SUCCESS:
      return {
        loading: false,
        solicitudes: action.data,
        tallas_camisa: state.tallas_camisa,
        tallas_pantalon: state.tallas_pantalon,
        colores: state.colores
      };
    case solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_FAILURE:
      return { 
        error: action.error
      };

    case solicituduniformeConstants.FETCH_TALLASCAMISA_REQUEST:
    return {
        loading: true,
        tallas_camisa: [],
        solicitudes: state.solicitudes,
        tallas_pantalon: state.tallas_pantalon,
        colores: state.colores
    };
    case solicituduniformeConstants.FETCH_TALLASCAMISA_SUCCESS:
    return {
        loading: false,
        tallas_camisa: action.data,
        solicitudes: state.solicitudes,
        tallas_pantalon: state.tallas_pantalon,
        colores: state.colores
    };
    case solicituduniformeConstants.FETCH_TALLASCAMISA_FAILURE:
    return { 
        error: action.error
    };

    case solicituduniformeConstants.FETCH_TALLASPANTALON_REQUEST:
    return {
        loading: true,
        tallas_camisa: state.tallas_camisa,
        solicitudes: state.solicitudes,
        tallas_pantalon: [],
        colores: state.colores
    };
    case solicituduniformeConstants.FETCH_TALLASPANTALON_SUCCESS:
    return {
        loading: false,
        tallas_camisa: state.tallas_camisa,
        solicitudes: state.solicitudes,
        tallas_pantalon: action.data,
        colores: state.colores
    };
    case solicituduniformeConstants.FETCH_TALLASPANTALON_FAILURE:
    return { 
        error: action.error
    };
    

    case solicituduniformeConstants.FETCH_COLORESUNIFORME_REQUEST:
    return {
        loading: true,
        tallas_camisa: state.colores,
        solicitudes: state.solicitudes,
        tallas_pantalon: state.tallas_pantalon,
        colores: []
    };
    case solicituduniformeConstants.FETCH_COLORESUNIFORME_SUCCESS:
    return {
        loading: false,
        tallas_camisa: state.tallas_camisa,
        solicitudes: state.solicitudes,
        tallas_pantalon: state.tallas_pantalon,
        colores: action.data
    };
    case solicituduniformeConstants.FETCH_COLORESUNIFORME_FAILURE:
    return { 
        error: action.error
    };
    
    default:
      return state
  }
}