import { encuestasConstants } from '../constants';
import { encuestasService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const encuestasActions = {
    guardar,
    actualizar,
    getAll,
    meInteresa,
    getComentarios,
    guardarComentario,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        encuestasService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/desarrollohumano/encuestas/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(encuestas) { return { type: encuestasConstants.SAVE_ENCUESTAS_REQUEST, encuestas } }
    function success(encuestas) { return { type: encuestasConstants.SAVE_ENCUESTAS_SUCCESS, encuestas } }
    function failure(error) { return { type: encuestasConstants.SAVE_ENCUESTAS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        encuestasService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.encuestas));
                        history.push(`/desarrollohumano/encuestas/actualizar/${response.encuestas.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(encuestas) { return { type: encuestasConstants.SAVE_ENCUESTAS_REQUEST, encuestas } }
    function success(encuestas) { return { type: encuestasConstants.SAVE_ENCUESTAS_SUCCESS, encuestas } }
    function failure(error) { return { type: encuestasConstants.SAVE_ENCUESTAS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        encuestasService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: encuestasConstants.FETCH_ENCUESTAS_REQUEST, data } }
    function success(data) { return { type: encuestasConstants.FETCH_ENCUESTAS_SUCCESS, data } }
    function failure(error) { return { type: encuestasConstants.FETCH_ENCUESTAS_FAILURE, error } }
}

function meInteresa(blog_id) {
    return dispatch => {
        dispatch(request({}));

        encuestasService.meInteresa(blog_id)
            .then(
                response => {
                    if(response.status){
                        dispatch(encuestasActions.getAll());
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: encuestasConstants.SET_MEINTERESA_REQUEST, data } }
    function success(data) { return { type: encuestasConstants.SET_MEINTERESA_SUCCESS, data } }
    function failure(error) { return { type: encuestasConstants.SET_MEINTERESA_FAILURE, error } }
}


function getOne(id) {
    return dispatch => {
        dispatch(request());

        encuestasService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: encuestasConstants.GET_ENCUESTA_REQUEST, data } }
    function success(data) { return { type: encuestasConstants.GET_ENCUESTA_SUCCESS, data } }
    function failure(error) { return { type: encuestasConstants.GET_ENCUESTA_FAILURE, error } }
}

function getComentarios(id,page = 0,limit = 10) {
    return dispatch => {
        dispatch(request());

        encuestasService.getComentarios(id,page,limit)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: encuestasConstants.GET_COMENTARIOS_REQUEST, data } }
    function success(data) { return { type: encuestasConstants.GET_COMENTARIOS_SUCCESS, data } }
    function failure(error) { return { type: encuestasConstants.GET_COMENTARIOS_FAILURE, error } }
}


function guardarComentario(formData) {
    return dispatch => {

        encuestasService.guardarComentario(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(alertActions.error(response.mensaje));
                    }
                    // dispatch(encuestasActions.getComentarios(formData.blog_id));
                },
                error => dispatch(alertActions.error(error))
            );
    };
}