import {apiUrl} from '../helpers'
export const organigramaService = {
    organigrama
};

const USER_API_BASE_URL = apiUrl;
function organigrama() {
    // let user = JSON.parse(localStorage.getItem('user'));
    // let Data = new FormData();
    // Data.append('usuario_id',user.id);
    // const requestOptions = {
    //     method: 'GET',
    //     headers: {'Authorization': 'Bearer '+user.access_token},
    //     body: Data
    // };
    
    // return fetch(USER_API_BASE_URL + 'api/organigrama/v1/organigrama/obtener', requestOptions)
    //     .then(handleResponse)
    //     .then(response => {
    //         return response;
    // });
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/organigrama/v1/organigrama/obtener?';
    var params = {token: user.access_token,user: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}