import { exhibicionesConstants } from '../constants';

export function exhibiciones(state = {exhibiciones: [] ,loading:false}, action) {
  switch (action.type) {
    case exhibicionesConstants.FETCH_EXHIBICIONES_REQUEST:
      return {
        loading: true,
        exhibiciones: []
      };
    case exhibicionesConstants.FETCH_EXHIBICIONES_SUCCESS:
      return {
        loading: false,
        exhibiciones: action.data
      };
    case exhibicionesConstants.FETCH_EXHIBICIONES_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}