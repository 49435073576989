export const venta_promocionConstants = {
    SAVE_VENTA_PROMOCION_REQUEST: 'SAVE_VENTA_PROMOCION_REQUEST',
    SAVE_VENTA_PROMOCION_SUCCESS: 'SAVE_VENTA_PROMOCION_SUCCESS',
    SAVE_VENTA_PROMOCION_FAILURE: 'SAVE_VENTA_PROMOCION_FAILURE',

    FETCH_VENTA_PROMOCION_REQUEST: 'FETCH_VENTA_PROMOCION_REQUEST',
    FETCH_VENTA_PROMOCION_SUCCESS: 'FETCH_VENTA_PROMOCION_SUCCESS',
    FETCH_VENTA_PROMOCION_FAILURE: 'FETCH_VENTA_PROMOCION_FAILURE',


    SET_VENTA_PROMOCION_REQUEST: 'SET_VENTA_PROMOCION_REQUEST',
    SET_VENTA_PROMOCION_SUCCESS: 'SET_VENTA_PROMOCION_SUCCESS',
    SET_VENTA_PROMOCION_FAILURE: 'SET_VENTA_PROMOCION_FAILURE',

    GET_VENTA_PROMOCION_REQUEST: 'GET_VENTA_PROMOCION_REQUEST',
    GET_VENTA_PROMOCION_SUCCESS: 'GET_VENTA_PROMOCION_SUCCESS',
    GET_VENTA_PROMOCION_FAILURE: 'GET_VENTA_PROMOCION_FAILURE',

};
