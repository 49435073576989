import React, { Component } from 'react'
import { venta_promocionActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';
import { cadenasService, dropdownsService, venta_promocionService } from '../../../services';
import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),   
    marca: Yup.string()
        .required("Campo requerido"), 
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    item: Yup.string()
        .required("Campo requerido"),
    producto: Yup.string()
        .required("Campo requerido"),
    tamano: Yup.string()
        .required("Campo requerido"),
    campana: Yup.string()
        .nullable("Campo requerido"),
    precio: Yup.number()
        .required("Campo requerido"),
    fotografia: Yup.string()
        .required("Campo requerido"),
    fotografia_entrega: Yup.string()
        .required("Campo requerido"),
    observaciones: Yup.string()
        .nullable("Campo requerido"),
    promocional_descripcion: Yup.string()
        .when('promocional', { is: true, then:  Yup.string().required('Requerido') })
});

const emptyOption = { value: "", label: "" };
class VentaPromocionForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            venta_promocion: {
                cadena_id: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                campana: "",
                cantidad: 0,
                precio: 0,
                factura: "",
                fotografia: "",
                observaciones: "",
                promocional: false,
                promocional_descripcion: "",
                cantidad_entrega: "",
                fotografia_entrega: "",
                observaciones_entrega: "",
                latitud: "",
                longitud: "",
            },
        };
        // this.getTiendas = this.getTiendas.bind(this);
        // this.getSubcategorias = this.getSubcategorias.bind(this);
        this.ifPresent = this.ifPresent.bind(this);
    }

    ifPresent(value, input){
        let error;
        if((value === "") && (input !== undefined || input !== "")){
            error = "Campo Requerido";
        }
        return error;
    }
    
    componentDidMount(){  
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ 
                    venta_promocion: {
                        ...this.state.venta_promocion,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }
        if(this.props.id){
            let venta_promocion_temp = this.state.venta_promocion;
            venta_promocionService.getOne(this.props.id).then(
                response => {
                    console.log(response.data);
                    
                    venta_promocion_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({venta_promocion: venta_promocion_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.venta_promocion}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(venta_promocionActions.guardar(values));
                    }else{
                        dispatch(venta_promocionActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                    <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div>
            
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 

                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaños</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cantidad">Cantidad</label>
                                <Field type="number" min="0" name="cantidad" value={values.cantidad || ''} placeholder="0" className={`form-control ${touched.cantidad && errors.cantidad ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio">Precio Unitario</label>
                                <Field type="number" min="0" step="0.01" name="precio" value={values.precio || ''} placeholder="0" className={`form-control ${touched.precio && errors.precio ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="factura"># Factura</label>
                                <Field type="text" name="factura" value={values.factura || ''} placeholder="# Factura" className={`form-control ${touched.factura && errors.factura ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="factura" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="promocional">Promocional</label>
                                <Field type="checkbox" name="n_a" value={values.n_a || ''} onChange={() => setFieldValue('n_a',(values.n_a === 1) ? 0:1 )} checked={(values.n_a === 0) ? false:true} className={`form-control ${touched.n_a && errors.n_a ? "is-invalid" : "" }`} />
                               
                                <ErrorMessage component="div" name="promocional" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="promocional_descripcion">Promocional Descripción</label>
                                <Field type="text" name="promocional_descripcion" value={values.promocional_descripcion || ''} placeholder="Promocional Descripción" className={`form-control ${touched.promocional_descripcion && errors.promocional_descripcion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="promocional_descripcion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cantidad_entrega">Cantidad a entregar</label>
                                <Field type="number" min="0" name="cantidad_entrega" value={values.cantidad_entrega || ''} placeholder="0" className={`form-control ${touched.cantidad_entrega && errors.cantidad_entrega ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad_entrega" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fotografia_entrega">Fotografia de Entrega</label>
                                <input type="file" accept="image/*" capture="user" id="fotografia_entrega" name="fotografia_entrega" onChange={(event) => { setFieldValue("fotografia_entrega", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia_entrega && errors.fotografia_entrega ? "is-invalid" : "" }`} />
                                {this.state.fotografia_entrega!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia_entrega} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia_entrega" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="campana">Campaña</label>
                                <Field type="text" name="campana" value={values.campana || ''} placeholder="Ingrese la Campaña" className={`form-control ${touched.campana && errors.campana ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="campana" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones_entrega">Observaciones</label>
                                <Field type="text" name="observaciones_entrega" value={values.observaciones_entrega || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_entrega && errors.observaciones_entrega ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_entrega" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(VentaPromocionForm);