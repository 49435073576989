import { competenciaConstants } from '../constants';
import { competenciaService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const competenciaActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        competenciaService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/competencia/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(competencia) { return { type: competenciaConstants.SAVE_COMPETENCIA_REQUEST, competencia } }
    function success(competencia) { return { type: competenciaConstants.SAVE_COMPETENCIA_SUCCESS, competencia } }
    function failure(error) { return { type: competenciaConstants.SAVE_COMPETENCIA_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        competenciaService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/merchandising/competencia/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(competencia) { return { type: competenciaConstants.SAVE_COMPETENCIA_REQUEST, competencia } }
    function success(competencia) { return { type: competenciaConstants.SAVE_COMPETENCIA_SUCCESS, competencia } }
    function failure(error) { return { type: competenciaConstants.SAVE_COMPETENCIA_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        competenciaService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: competenciaConstants.FETCH_COMPETENCIA_REQUEST, data } }
    function success(data) { return { type: competenciaConstants.FETCH_COMPETENCIA_SUCCESS, data } }
    function failure(error) { return { type: competenciaConstants.FETCH_COMPETENCIA_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        competenciaService.getOne(id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: competenciaConstants.GET_COMPETENCIA_REQUEST, data } }
    function success(data) { return { type: competenciaConstants.GET_COMPETENCIA_SUCCESS, data } }
    function failure(error) { return { type: competenciaConstants.GET_COMPETENCIA_FAILURE, error } }
}