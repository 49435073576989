import { descargasConstants } from '../constants';

export function descargas(state = {descargas: [], loading:false}, action) {
  switch (action.type) {
    case descargasConstants.FETCH_DESCARGAS_REQUEST:
      return {
        loading: true,
        descargas: [],
      };
    case descargasConstants.FETCH_DESCARGAS_SUCCESS:
      return {
        loading: false,
        descargas: action.data,
      };
    case descargasConstants.FETCH_DESCARGAS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}