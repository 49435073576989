import {apiUrl} from '../helpers'
export const descargasService = {
    getAll
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/desarrollohumano/v1/descargas/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
