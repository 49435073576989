import { viaticosConstants } from '../constants';

export function viaticos(state = {viaticos: [],loading:true}, action) {
  switch (action.type) {
    case viaticosConstants.FETCH_VIATICOS_REQUEST:
      return {
        loading: true,
        viaticos: []
      };
    case viaticosConstants.FETCH_VIATICOS_SUCCESS:
      return {
        loading: false,
        viaticos: action.data
      };
    case viaticosConstants.FETCH_VIATICOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}