import React, { Component } from 'react'
import { certificadoigssActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    motivo_id: Yup.string()
        .required("Campo requerido"),
    certificado_id: Yup.string()
        .required("Campo requerido"),
    fecha_utilizacion: Yup.string()
        .required("Campo requerido"),
    fecha_accidente: Yup.string()
        .nullable("Campo requerido"),
    beneficiario: Yup.string()
        .nullable("Campo requerido")
});

class CertificadoIgssForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            certificado: {
                motivo_id: "",
                certificado_id: "",
                fecha_utilizacion: "",
                fecha_accidente: "",
                beneficiario: ""
            }
        };

        const { dispatch } = this.props;
        dispatch(certificadoigssActions.getMotivos());
        dispatch(certificadoigssActions.getCertificados());
        this.validateOpt = this.validateOpt.bind(this);
    }

    validateOpt(value,option,selected){
        let error;
        if(value === "" && option.label === selected){
            error = 'Requerido';
        }
        return error;
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/recursoshumanos/v1/certificadoigss/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.certificado.motivo_id !== undefined){
                    for (const result of this.props.motivos) {
                        if(response.certificado.motivo_id === result.value){
                            response.certificado.motivo_id = result;
                        }
                    }
                }
                if(response.certificado.certificado_id !== undefined){
                    for (const result of this.props.tipocertificados) {
                        if(response.certificado.certificado_id === result.value){
                            response.certificado.certificado_id = result;
                        }
                    }
                }
                this.setState({certificado: response.certificado});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.certificado}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.motivo_id = values.motivo_id.value;
                    values.certificado_id = values.certificado_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(certificadoigssActions.guardar(values));
                    }else{
                        dispatch(certificadoigssActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting, isValidating }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fecha_utilizacion">Fecha Utilización</label>
                                <Field type="date" name="fecha_utilizacion" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_utilizacion && errors.fecha_utilizacion ? "is-invalid" : ""}`} />
                                <ErrorMessage component="div" name="fecha_utilizacion" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="motivo_id">Motivo</label>
                                <MySelect
                                    value={values.motivo_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.motivo_id}
                                    touched={touched.motivo_id}
                                    field={'motivo_id'}
                                    options={this.props.motivos}
                                />
                                <ErrorMessage component="div" name="motivo_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fecha_accidente">Si fuese un accidente, indique la fecha</label>
                                <Field type="date" value={values.fecha_accidente || ''} validate={(value) => this.validateOpt(value, values.motivo_id, "Accidente")} name="fecha_accidente" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_accidente && errors.fecha_accidente ? "is-invalid" : ""}`} />
                                <ErrorMessage component="div" name="fecha_accidente" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="motivo_id">Uso de Certificado</label>

                                <MySelect
                                    value={values.certificado_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.certificado_id}
                                    touched={touched.certificado_id}
                                    field={'certificado_id'}
                                    options={this.props.tipocertificados}
                                />
                                <ErrorMessage component="div" name="certificado_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="beneficiario">Si es de un beneficiario, indique en nombre</label>
                                <Field type="text" name="beneficiario" value={values.beneficiario || ''} validate={(value) => this.validateOpt(value, values.certificado_id, "Beneficiario")} placeholder="Nombre del Beneficiario" className={`form-control ${touched.beneficiario && errors.beneficiario ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="beneficiario" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, certificadoigss } = state;
    return {
        alert,
        motivos: certificadoigss.motivos,
        tipocertificados: certificadoigss.tipocertificados
    };
}


export default connect(mapStateToProps)(CertificadoIgssForm);