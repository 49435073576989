import { viaticosConstants } from '../constants';
import { viaticosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const viaticosActions = {
    guardar,
    actualizar,
    getAll,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        viaticosService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/finanzas/viaticos/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(viatico) { return { type: viaticosConstants.SAVE_VIATICOS_REQUEST, viatico } }
    function success(viatico) { return { type: viaticosConstants.SAVE_VIATICOS_SUCCESS, viatico } }
    function failure(error) { return { type: viaticosConstants.SAVE_VIATICOS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        viaticosService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.viatico));
                        history.push(`/finanzas/viaticos/actualizar/${response.viatico.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(viatico) { return { type: viaticosConstants.SAVE_VIATICOS_REQUEST, viatico } }
    function success(viatico) { return { type: viaticosConstants.SAVE_VIATICOS_SUCCESS, viatico } }
    function failure(error) { return { type: viaticosConstants.SAVE_VIATICOS_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        viaticosService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: viaticosConstants.FETCH_VIATICOS_REQUEST, data } }
    function success(data) { return { type: viaticosConstants.FETCH_VIATICOS_SUCCESS, data } }
    function failure(error) { return { type: viaticosConstants.FETCH_VIATICOS_FAILURE, error } }
}


function getOne(viatico) {
    return dispatch => {
        dispatch(request());

        viaticosService.getOne(viatico)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: viaticosConstants.GET_VIATICO_REQUEST, data } }
    function success(data) { return { type: viaticosConstants.GET_VIATICO_SUCCESS, data } }
    function failure(error) { return { type: viaticosConstants.GET_VIATICO_FAILURE, error } }
}