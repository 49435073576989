import { dataConstants } from '../constants';

export function data(state = {proveedores: [], cadenas:[], tiendas: [] }, action) {
  switch (action.type) {
    case dataConstants.FETCH_PROVEEDORES_REQUEST:
      return {
        ...state,
        loading: true,
        proveedores: []
      };
    case dataConstants.FETCH_PROVEEDORES_SUCCESS:
      return {
        ...state,
        loading: false,
        proveedores: action.data
      };
    case dataConstants.FETCH_PROVEEDORES_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case dataConstants.FETCH_CADENAS_REQUEST:
      return {
        ...state,
        loading: true,
        cadenas: []
      };
    case dataConstants.FETCH_CADENAS_SUCCESS:
      return {
        ...state,
        loading: false,
        cadenas: action.data
      };
    case dataConstants.FETCH_CADENAS_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case dataConstants.FETCH_TIENDAS_REQUEST:
      return {
        ...state,
        loading: true,
        tiendas: []
      };
    case dataConstants.FETCH_TIENDAS_SUCCESS:
      return {
        ...state,
        loading: false,
        tiendas: action.data
      };
    case dataConstants.FETCH_TIENDAS_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    default:
      return state
  }
}