import { cartaingresosConstants } from '../constants';

export function cartaingresos(state = {cartas: [], tramites: [], bancos: [], loading:true}, action) {
  switch (action.type) {
    case cartaingresosConstants.FETCH_CARTAINGRESOS_REQUEST:
      return {
        loading: true,
        cartas: [],
        tramites: state.tramites
      };
    case cartaingresosConstants.FETCH_CARTAINGRESOS_SUCCESS:
      return {
        loading: false,
        cartas: action.data,
        tramites: state.tramites
      };
    case cartaingresosConstants.FETCH_CARTAINGRESOS_FAILURE:
      return { 
        error: action.error
      };
    case cartaingresosConstants.FETCH_TRAMITES_REQUEST:
      return {
        ...state,
        loading: true,
        tramites: [],

      };
    case cartaingresosConstants.FETCH_TRAMITES_SUCCESS:
      return {
        ...state,
        loading: false,
        tramites: action.data,

      };
    case cartaingresosConstants.FETCH_TRAMITES_FAILURE:
      return { 
        error: action.error
      };
    case cartaingresosConstants.FETCH_BANCOS_REQUEST:
      return {
        ...state,
        loading: true,
        bancos: [],

      };
    case cartaingresosConstants.FETCH_BANCOS_SUCCESS:
      return {
        ...state,
        loading: false,
        bancos: action.data,

      };
    case cartaingresosConstants.FETCH_BANCOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}