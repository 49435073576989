import { authHeader } from '../helpers';
import { history, apiUrl } from '../helpers';
export const userService = {
    login,
    logout,
    getAll,
    forgot,
    change,
    refreshToken
};

const USER_API_BASE_URL = apiUrl;
// const USER_API_BASE_URL = 'https://actalentos.barrilete.grupoperinola.net/';

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(USER_API_BASE_URL + 'api/auth/usuario/login', requestOptions)
        .then(handleResponse)
        .then(response => {
            // login successful if there's a jwt token in the response
            if (response.access_token) {
                let userData = {
                    id: response.user.id,
                    nombre: response.user.nombre,
                    apellido: response.user.apellido,
                    activo: response.user.activo,
                    email: response.user.email,
                    empleado_id: response.empleado.empleado_id,
                    estacion_id: response.empleado.estacion_id,
                    cliente_id: response.empleado.cliente_id,
                    fotografia: response.empleado.fotografia,
                    access_token: response.access_token,
                    expires_in: response.expires_in,
                    expiraction_time: response.expiraction_time,
                };
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(userData));
                return userData;
            }

            return response;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function refreshToken(){
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/auth/refresh`;
    return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
        // login successful if there's a jwt token in the response
        if (response.access_token) {
            let userData = {
                id: response.user.id,
                nombre: response.user.nombre,
                apellido: response.user.apellido,
                activo: response.user.activo,
                email: response.user.email,
                empleado_id: response.empleado.empleado_id,
                estacion_id: response.empleado.estacion_id,
                cliente_id: response.empleado.cliente_id,
                fotografia: response.empleado.fotografia,
                access_token: response.access_token,
                expires_in: response.expires_in,
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(userData));
            return userData;
        }
    });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // window.location.reload(true);
                history.push('/login');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function forgot(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(USER_API_BASE_URL + 'api/auth/forgot', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function change(email,password,token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, token })
    };

    return fetch(USER_API_BASE_URL + 'api/auth/change-password', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}