import { solicituduniformeConstants } from '../constants';
import { solicituduniformeService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const solicituduniformeActions = {
    guardar,
    actualizar,
    getAll,
    getTallasCamisas,
    getTallasPantalones,
    getColoresUniformes,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        solicituduniformeService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/seguridadcorporativa/solicituduniforme/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(solicituduniforme) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_REQUEST, solicituduniforme } }
    function success(solicituduniforme) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_SUCCESS, solicituduniforme } }
    function failure(error) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        solicituduniformeService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.solicituduniforme));
                        history.push(`/seguridadcorporativa/solicituduniforme/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(solicituduniforme) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_REQUEST, solicituduniforme } }
    function success(solicituduniforme) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_SUCCESS, solicituduniforme } }
    function failure(error) { return { type: solicituduniformeConstants.SAVE_SOLICITUDUNIFORME_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        solicituduniformeService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_REQUEST, data } }
    function success(data) { return { type: solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_SUCCESS, data } }
    function failure(error) { return { type: solicituduniformeConstants.FETCH_SOLICITUDUNIFORME_FAILURE, error } }
}

function getTallasCamisas() {
    return dispatch => {
        dispatch(request({}));

        solicituduniformeService.getTallasCamisas()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicituduniformeConstants.FETCH_TALLASCAMISA_REQUEST, data } }
    function success(data) { return { type: solicituduniformeConstants.FETCH_TALLASCAMISA_SUCCESS, data } }
    function failure(error) { return { type: solicituduniformeConstants.FETCH_TALLASCAMISA_FAILURE, error } }
}

function getTallasPantalones() {
    return dispatch => {
        dispatch(request({}));

        solicituduniformeService.getTallasPantalones()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicituduniformeConstants.FETCH_TALLASPANTALON_REQUEST, data } }
    function success(data) { return { type: solicituduniformeConstants.FETCH_TALLASPANTALON_SUCCESS, data } }
    function failure(error) { return { type: solicituduniformeConstants.FETCH_TALLASPANTALON_FAILURE, error } }
}

function getColoresUniformes() {
    return dispatch => {
        dispatch(request({}));

        solicituduniformeService.getColoresUniformes()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicituduniformeConstants.FETCH_COLORESUNIFORME_REQUEST, data } }
    function success(data) { return { type: solicituduniformeConstants.FETCH_COLORESUNIFORME_SUCCESS, data } }
    function failure(error) { return { type: solicituduniformeConstants.FETCH_COLORESUNIFORME_FAILURE, error } }
}




function getOne(solicituduniforme) {
    return dispatch => {
        dispatch(request());

        solicituduniformeService.getOne(solicituduniforme)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: solicituduniformeConstants.GET_SOLICITUDUNIFORME_REQUEST, data } }
    function success(data) { return { type: solicituduniformeConstants.GET_SOLICITUDUNIFORME_SUCCESS, data } }
    function failure(error) { return { type: solicituduniformeConstants.GET_SOLICITUDUNIFORME_FAILURE, error } }
}