import { dataConstants } from '../constants';
import { proveedoresService } from '../services';

export const proveedoresActions = {
    getAll,
    getOne
};

function getAll() {
    return dispatch => {
        dispatch(request({}));

        proveedoresService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.FETCH_PROVEEDORES_REQUEST, data } }
    function success(data) { return { type: dataConstants.FETCH_PROVEEDORES_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.FETCH_PROVEEDORES_FAILURE, error } }
}


function getOne(proveedor) {
    return dispatch => {
        dispatch(request());

        proveedoresService.getOne(proveedor)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.GET_PROVEEDOR_REQUEST, data } }
    function success(data) { return { type: dataConstants.GET_PROVEEDOR_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.GET_PROVEEDOR_FAILURE, error } }
}