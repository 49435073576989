import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../actions';
import Alert from '../../components/alerts/Alert';
import { Link } from 'react-router-dom';


class Login extends Component{
    constructor(props){
        super(props);

        console.log("modify 07/4/2024 19:00")
        // reset login status
        this.props.dispatch(userActions.logout());

        this.state = {
            email: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.login(email, password));
        }
    }

    render(){
        const { loggingIn } = this.props;
        const { email, password, submitted } = this.state;
        return (
            <div className="bg-gradient-primary-login">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-lg-12 col-xl-10">
                            <div className="card shadow-lg o-hidden border-0 my-5">
                                <div className="card-body p-0">
                                    <Alert/>
                                    <div className="row">
                                        <div className="col-lg-3 d-none d-lg-flex">
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h4 className="text-dark mb-4">AC Talentos</h4>
                                                </div>
                                                <form className="user" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-user" type="email" name="email" value={email} onChange={this.handleChange} id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Correo Electronico" autoComplete="email"/>
                                                        {submitted && !email &&
                                                            <div className="help-block">Correo Electronico es requerido</div>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-user"  type="password" name="password" value={password} onChange={this.handleChange} id="exampleInputPassword" placeholder="Password" autoComplete="current-password"/>
                                                        {submitted && !password &&
                                                            <div className="help-block">contraseña es requerido</div>
                                                        }
                                                    </div>
                                                    <button className="btn btn-primary btn-block text-white btn-user" type="submit">Iniciar Sesión</button>
                                                    {loggingIn &&
                                                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                    <Link to="/forgot"  className="btn btn-success  btn-block text-white btn-user">Olvidé mi contraseña</Link>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

export default connect(mapStateToProps)(Login);