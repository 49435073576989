import { dataConstants } from '../constants';
import { liquidacionesService } from '../services';
import { alertActions, proveedoresActions } from './';
import { history } from '../helpers';

export const liquidacionesActions = {
    guardar,
    guardarGasto,
    actualizar,
    getAll,
    getConceptos,
    getTipoGasolina,
    getAllGastos,
    eliminarGasto,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        liquidacionesService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/finanzas/liquidaciones/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(liquidacion) { return { type: dataConstants.SAVE_LIQUIDACIONES_REQUEST, liquidacion } }
    function success(liquidacion) { return { type: dataConstants.SAVE_LIQUIDACIONES_SUCCESS, liquidacion } }
    function failure(error) { return { type: dataConstants.SAVE_LIQUIDACIONES_FAILURE, error } }
}

function guardarGasto(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        liquidacionesService.guardarGasto(formData, id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/finanzas/liquidaciones/actualizar/${response.data.liquidacion_id}`);
                        dispatch(alertActions.success(response.mensaje));
                        dispatch(liquidacionesActions.getAllGastos(response.data.liquidacion_id));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: dataConstants.SAVE_GASTOS_REQUEST  } }
    function success(gasto) { return { type: dataConstants.SAVE_GASTOS_SUCCESS, gasto } }
    function failure(error) { return { type: dataConstants.SAVE_GASTOS_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        liquidacionesService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.liquidacion));
                        history.push(`/finanzas/liquidaciones/actualizar/${response.liquidacion.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(liquidacion) { return { type: dataConstants.SAVE_LIQUIDACIONES_REQUEST, liquidacion } }
    function success(liquidacion) { return { type: dataConstants.SAVE_LIQUIDACIONES_SUCCESS, liquidacion } }
    function failure(error) { return { type: dataConstants.SAVE_LIQUIDACIONES_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        liquidacionesService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        dispatch(proveedoresActions.getAll());
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.FETCH_LIQUIDACIONES_REQUEST, data } }
    function success(data) { return { type: dataConstants.FETCH_LIQUIDACIONES_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.FETCH_LIQUIDACIONES_FAILURE, error } }
}


function getAllGastos(liquidacion_id) {
    return dispatch => {
        dispatch(request({}));
        liquidacionesService.getAllGastos(liquidacion_id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.gastos));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.FETCH_GASTOS_REQUEST, data } }
    function success(data) { return { type: dataConstants.FETCH_GASTOS_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.FETCH_GASTOS_FAILURE, error } }
}


function getOne(liquidacion) {
    return dispatch => {
        dispatch(request());

        liquidacionesService.getOne(liquidacion)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.GET_LIQUIDACION_REQUEST, data } }
    function success(data) { return { type: dataConstants.GET_LIQUIDACION_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.GET_LIQUIDACION_FAILURE, error } }
}


function getConceptos() {
    return dispatch => {
        dispatch(request({}));

        liquidacionesService.getConceptos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.FETCH_CONCEPTOS_REQUEST, data } }
    function success(data) { return { type: dataConstants.FETCH_CONCEPTOS_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.FETCH_CONCEPTOS_FAILURE, error } }
}


function getTipoGasolina() {
    return dispatch => {
        dispatch(request({}));

        liquidacionesService.getTipoGasolina()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.FETCH_TIPOSGASOLINA_REQUEST, data } }
    function success(data) { return { type: dataConstants.FETCH_TIPOSGASOLINA_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.FETCH_TIPOSGASOLINA_FAILURE, error } }
}

function eliminarGasto(gasto){
    return dispatch => {
        dispatch(request({}));
        liquidacionesService.eliminarGasto(gasto.id)
            .then(
                response => {
                    dispatch(alertActions.success(response.mensaje));
                    dispatch(liquidacionesActions.getAllGastos(gasto.liquidacion_id));
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: dataConstants.DELETE_GASTO_REQUEST, data } }
    // function success(data) { return { type: dataConstants.DELETE_GASTO_SUCCESS, data } }
    function failure(error) { return { type: dataConstants.DELETE_GASTO_FAILURE, error } }
}