// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TableRow extends Component {
  render() {
    return (
        <tr>
          <td>
            {this.props.solicitud.id}
          </td>
          <td>
            {this.props.solicitud.created_at}
          </td>
          <td>
            {this.props.solicitud.tramite}
          </td>
          <td>
            {this.props.solicitud.conyugue}
          </td>
          <td>
            {this.props.solicitud.aprobado ? 'Si' : 'No'}
          </td>
          <td>
            {this.props.solicitud.fecha_aprobada}
          </td>
          <td>
            {this.props.solicitud.bitacora}
          </td>
          <td>
            {
              this.props.solicitud.aprobado &&
              <Link to={"/recursoshumanos/solicitudirtra/actualizar/"+this.props.solicitud.id} id={this.props.solicitud.id} className="btn btn-primary">Editar</Link>
            }
          </td>
          {/* <td>
            <button className="btn btn-danger">Delete</button>
          </td> */}
        </tr>
    );
  }
}

export default TableRow;