import {apiUrl} from '../helpers'
export const checkService = {
    guardar
};

const USER_API_BASE_URL = apiUrl;
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('usuario_id', user.id);
    Data.append('checkin',formData.checkin?1:0);
    
    Data.append('fotografia',formData.fotografia);
    Data.append('entrada',(formData.entrada?1:0));
    
    if(formData.latitud !== undefined){
        Data.append('latitud',formData.latitud);
    }
    if(formData.longitud !== undefined){
        Data.append('longitud',formData.longitud);
    }
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/merchandising/v1/check/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}