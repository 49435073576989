import React, { Component } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import { liquidacionesActions } from '../../actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import TableRow from './TableRow'


class Liquidaciones extends Component{
    constructor(props){
        super(props);
        this.props.dispatch(liquidacionesActions.getAll());
    }

    tabRow(){
        if(this.props.liquidaciones instanceof Array){
            return this.props.liquidaciones.map(function(object, i){
                return <TableRow liquidacion={object} key={i} />;
            })
        }
    }
    render(){
        return (
            <div id="wrapper">
                <Sidebar/>
                <div className="d-flex flex-column" id="content-wrapper">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <Alert />
                            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                                <h3 className="text-dark mb-0">Liquidaciones</h3>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-10">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-10"></div>
                                            <div className="col-md-2">
                                                <Link to="/finanzas/liquidaciones/crear"  className="btn btn-success">Crear Liquidación</Link>
                                            </div>
                                        </div><br />
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <td>ID</td>
                                                    <td>Concepto</td>
                                                    <td>Aprobó Supervisor</td>
                                                    <td>Aprobó Finanzas</td>
                                                    <td>Actions</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {this.tabRow()}
                                                </tbody>
                                            </table>
                                            {this.props.loading &&
                                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { liquidaciones, loading } = state.users;
    return {
        liquidaciones, loading
    };
}

export default connect(mapStateToProps)(Liquidaciones);