import { btlConstants } from '../constants';

export function btl(state = {btl: [] ,loading:false}, action) {
  switch (action.type) {
    case btlConstants.FETCH_BTL_REQUEST:
      return {
        loading: true,
        btl: []
      };
    case btlConstants.FETCH_BTL_SUCCESS:
      return {
        loading: false,
        btl: action.data
      };
    case btlConstants.FETCH_BTL_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}