import React, {Component} from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { componentsActions } from '../actions';

var classNames = require('classnames');
class Sidebar extends Component{
    toggleSidebar(){
        const { dispatch } = this.props;
        dispatch(componentsActions.toggle());
    }
    getNavLinkClass(path,active='active',default_val=''){
        return this.props.location.pathname === path ? active : default_val;
    }
    render(){
        const { components } = this.props;
        let nav_clases = classNames({
            'navbar': true,
            'navbar-dark': true,
            'align-items-start': true,
            'sidebar': true,
            'sidebar-dark': true,
            'accordion': true,
            'bg-gradient-primary': true,
            'p-0':true,
            'toggled': components.sidebarToggle
        });
        return (
            <nav className={nav_clases}>
                <div className="container-fluid d-flex flex-column p-0">
                    <NavLink className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to="/dashboard">
                        <div className="sidebar-brand-icon rotate-n-15"><i className="fas fa-laugh-wink"></i></div>
                        <div className="sidebar-brand-text mx-3"><span>AC Talentos</span></div>
                    </NavLink>
                    <hr className="sidebar-divider my-0" />
                    <ul className="nav navbar-nav text-light" id="accordionSidebar">
                        <li className="nav-item" role="presentation"><NavLink to='/dashboard' className="nav-link" activeClassName='active'><i className="fas fa-tachometer-alt"></i><span>Dashboard</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/organigrama' className="nav-link" activeClassName='active'><i className="fas fa-sitemap"></i><span>Organigrama</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/finanzas' className="nav-link" activeClassName='active'><i className="fas fa-coins"></i><span>Finanzas</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/recursoshumanos' className="nav-link" activeClassName='active'><i className="fas fa-users"></i><span>Gestión de Personal</span></NavLink></li>
                        {/* <li className="nav-item" role="presentation"><NavLink to='/vacaciones' className="nav-link" activeClassName='active'><i className="fas fa-user"></i><span>Vacaciones</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/cartaingresos' className="nav-link" activeClassName='active'><i className="fas fa-user"></i><span>Carta de Ingresos</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/certificadoigss' className="nav-link" activeClassName='active'><i className="fas fa-user"></i><span>Certificado de Igss</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/solicitudirtra' className="nav-link" activeClassName='active'><i className="fas fa-user"></i><span>Solicitud de Irtra</span></NavLink></li> */}
                        <li className="nav-item" role="presentation"><NavLink to='/seguridadcorporativa' className="nav-link" activeClassName='active'><i className="fas fa-user-shield"></i><span>Seguridad Corporativa</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/desarrollohumano' className="nav-link" activeClassName='active'><i className="fas fa-user"></i><span>Desarrollo Humano</span></NavLink></li>
                        <li className="nav-item" role="presentation"><NavLink to='/merchandising' className="nav-link" activeClassName='active'><i className="fas fa-hand-holding-usd"></i><span>Merchandising</span></NavLink></li>
                    </ul>
                    <div className="text-center d-none d-md-inline"><button className="btn rounded-circle border-0" onClick={() => this.toggleSidebar()} id="sidebarToggle" type="button"></button></div>
                </div>
            </nav>
        );
    }
}
function mapStateToProps(state) {
    const { components } = state;
    return {
        components
    };
  }
  
Sidebar = withRouter(Sidebar);
export default connect(mapStateToProps)(Sidebar);