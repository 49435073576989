export const herramientanuevaConstants = {
    SAVE_HERRAMIENTANUEVA_REQUEST: 'SAVE_HERRAMIENTANUEVA_REQUEST',
    SAVE_HERRAMIENTANUEVA_SUCCESS: 'SAVE_HERRAMIENTANUEVA_SUCCESS',
    SAVE_HERRAMIENTANUEVA_FAILURE: 'SAVE_HERRAMIENTANUEVA_FAILURE',

    FETCH_HERRAMIENTANUEVA_REQUEST: 'FETCH_HERRAMIENTANUEVA_REQUEST',
    FETCH_HERRAMIENTANUEVA_SUCCESS: 'FETCH_HERRAMIENTANUEVA_SUCCESS',
    FETCH_HERRAMIENTANUEVA_FAILURE: 'FETCH_HERRAMIENTANUEVA_FAILURE',


    FETCH_TIPOHERRAMIENTA_REQUEST: 'FETCH_TIPOHERRAMIENTA_REQUEST',
    FETCH_TIPOHERRAMIENTA_SUCCESS: 'FETCH_TIPOHERRAMIENTA_SUCCESS',
    FETCH_TIPOHERRAMIENTA_FAILURE: 'FETCH_TIPOHERRAMIENTA_FAILURE',

    GET_HERRAMIENTANUEVA_REQUEST: 'GET_HERRAMIENTANUEVA_REQUEST',
    GET_HERRAMIENTANUEVA_SUCCESS: 'GET_HERRAMIENTANUEVA_SUCCESS',
    GET_HERRAMIENTANUEVA_FAILURE: 'GET_HERRAMIENTANUEVA_FAILURE',

};
