import { seguromedicoConstants } from '../constants';
import { seguromedicoService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const seguromedicoActions = {
    guardar,
    actualizar,
    getAll,
    getTipos,
    getAseguradoras,
    getOne
};

function guardar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        seguromedicoService.guardar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                        history.push(`/seguridadcorporativa/seguromedico/actualizar/${response.data.id}`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(seguromedico) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_REQUEST, seguromedico } }
    function success(seguromedico) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_SUCCESS, seguromedico } }
    function failure(error) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_FAILURE, error } }
}

function actualizar(formData,id) {
    return dispatch => {
        dispatch(request({ formData }));

        seguromedicoService.actualizar(formData,id)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.certificado));
                        history.push(`/seguridadcorporativa/seguromedico`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(seguromedico) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_REQUEST, seguromedico } }
    function success(seguromedico) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_SUCCESS, seguromedico } }
    function failure(error) { return { type: seguromedicoConstants.SAVE_SEGUROMEDICO_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request({}));

        seguromedicoService.getAll()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: seguromedicoConstants.FETCH_SEGUROMEDICO_REQUEST, data } }
    function success(data) { return { type: seguromedicoConstants.FETCH_SEGUROMEDICO_SUCCESS, data } }
    function failure(error) { return { type: seguromedicoConstants.FETCH_SEGUROMEDICO_FAILURE, error } }
}

function getTipos() {
    return dispatch => {
        dispatch(request({}));

        seguromedicoService.getTipos()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: seguromedicoConstants.FETCH_TIPOSEGURO_REQUEST, data } }
    function success(data) { return { type: seguromedicoConstants.FETCH_TIPOSEGURO_SUCCESS, data } }
    function failure(error) { return { type: seguromedicoConstants.FETCH_TIPOSEGURO_FAILURE, error } }
}

function getAseguradoras() {
    return dispatch => {
        dispatch(request({}));

        seguromedicoService.getAseguradoras()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: seguromedicoConstants.FETCH_ASEGURADORA_REQUEST, data } }
    function success(data) { return { type: seguromedicoConstants.FETCH_ASEGURADORA_SUCCESS, data } }
    function failure(error) { return { type: seguromedicoConstants.FETCH_ASEGURADORA_FAILURE, error } }
}


function getOne(seguromedico) {
    return dispatch => {
        dispatch(request());

        seguromedicoService.getOne(seguromedico)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request(data) { return { type: seguromedicoConstants.GET_SEGUROMEDICO_REQUEST, data } }
    function success(data) { return { type: seguromedicoConstants.GET_SEGUROMEDICO_SUCCESS, data } }
    function failure(error) { return { type: seguromedicoConstants.GET_SEGUROMEDICO_FAILURE, error } }
}