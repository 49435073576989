export const certificadoigssConstants = {
    SAVE_CERTIFICADOIGSS_REQUEST: 'SAVE_CERTIFICADOIGSS_REQUEST',
    SAVE_CERTIFICADOIGSS_SUCCESS: 'SAVE_CERTIFICADOIGSS_SUCCESS',
    SAVE_CERTIFICADOIGSS_FAILURE: 'SAVE_CERTIFICADOIGSS_FAILURE',

    FETCH_CERTIFICADOIGSS_REQUEST: 'FETCH_CERTIFICADOIGSS_REQUEST',
    FETCH_CERTIFICADOIGSS_SUCCESS: 'FETCH_CERTIFICADOIGSS_SUCCESS',
    FETCH_CERTIFICADOIGSS_FAILURE: 'FETCH_CERTIFICADOIGSS_FAILURE',


    FETCH_MOTIVOS_REQUEST: 'FETCH_MOTIVOS_REQUEST',
    FETCH_MOTIVOS_SUCCESS: 'FETCH_MOTIVOS_SUCCESS',
    FETCH_MOTIVOS_FAILURE: 'FETCH_MOTIVOS_FAILURE',

    FETCH_CERTIFICADOS_REQUEST: 'FETCH_CERTIFICADOS_REQUEST',
    FETCH_CERTIFICADOS_SUCCESS: 'FETCH_CERTIFICADOS_SUCCESS',
    FETCH_CERTIFICADOS_FAILURE: 'FETCH_CERTIFICADOS_FAILURE',

    GET_CERTIFICADOIGSS_REQUEST: 'GET_CERTIFICADOIGSS_REQUEST',
    GET_CERTIFICADOIGSS_SUCCESS: 'GET_CERTIFICADOIGSS_SUCCESS',
    GET_CERTIFICADOIGSS_FAILURE: 'GET_CERTIFICADOIGSS_FAILURE',

};
