export const exhibicionesConstants = {
    SAVE_EXHIBICIONES_REQUEST: 'SAVE_EXHIBICIONES_REQUEST',
    SAVE_EXHIBICIONES_SUCCESS: 'SAVE_EXHIBICIONES_SUCCESS',
    SAVE_EXHIBICIONES_FAILURE: 'SAVE_EXHIBICIONES_FAILURE',

    FETCH_EXHIBICIONES_REQUEST: 'FETCH_EXHIBICIONES_REQUEST',
    FETCH_EXHIBICIONES_SUCCESS: 'FETCH_EXHIBICIONES_SUCCESS',
    FETCH_EXHIBICIONES_FAILURE: 'FETCH_EXHIBICIONES_FAILURE',


    SET_EXHIBICION_REQUEST: 'SET_EXHIBICION_REQUEST',
    SET_EXHIBICION_SUCCESS: 'SET_EXHIBICION_SUCCESS',
    SET_EXHIBICION_FAILURE: 'SET_EXHIBICION_FAILURE',

    GET_EXHIBICION_REQUEST: 'GET_EXHIBICION_REQUEST',
    GET_EXHIBICION_SUCCESS: 'GET_EXHIBICION_SUCCESS',
    GET_EXHIBICION_FAILURE: 'GET_EXHIBICION_FAILURE',

};
