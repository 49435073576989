import React, {useState} from 'react'
import Alert from '../../components/alerts/Alert';
import { connect } from 'react-redux'
import { userService } from '../../services';
import { alertActions } from '../../actions';

let Forgot = (props) => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {

        e.preventDefault();
        const {dispatch} = props;
        setSubmitted(true);
        if (email) {
            userService.forgot(email)
            .then( 
                response => {
                    console.log(response);
                    if(response.status){
                        dispatch(alertActions.success(response.message));
                    }else{
                        dispatch(alertActions.error(response.message));
                    }
                },
                error => {
                    console.log(error);
                }
            );
        }
        setSubmitted(false);
    }

    return (
        <div className="bg-gradient-primary-login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <Alert/>
                                <div className="row">
                                    <div className="col-lg-3 d-none d-lg-flex">
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">AC Talentos</h4>
                                            </div>
                                            <form className="user" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <input className="form-control form-control-user" type="email" name="email" value={email} onChange={event => setEmail(event.target.value)} id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Correo Electronico" autoComplete="email"/>
                                                    {submitted  && !email &&
                                                        <div className="help-block">Correo Electronico es requerido</div>
                                                    }
                                                </div>
                                                
                                                <button className="btn btn-primary btn-block text-white btn-user" type="submit">Cambiar Contraseña</button>
                                                    {submitted &&
                                                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

Forgot = connect(mapStateToProps)(Forgot);

export {Forgot}