import { venta_promocionConstants } from '../constants';

export function venta_promocion(state = {venta_promocion: [] ,loading:false}, action) {
  switch (action.type) {
    case venta_promocionConstants.FETCH_VENTA_PROMOCION_REQUEST:
      return {
        loading: true,
        venta_promocion: []
      };
    case venta_promocionConstants.FETCH_VENTA_PROMOCION_SUCCESS:
      return {
        loading: false,
        venta_promocion: action.data
      };
    case venta_promocionConstants.FETCH_VENTA_PROMOCION_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}