
import React from 'react';
import Select from 'react-select';
class MySelect extends React.Component {

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.field, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.field, true);
    };
    
    render() {
        return (
        <div>
            <Select
            id={this.props.field}
            options={this.props.options}
            multi={false}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.props.value}
            placeholder={this.props.placeholder?this.props.placeholder:'Seleccione uno'}
            />
            {!!this.props.error &&
            this.props.touched && (
                <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
            )}
        </div>
        );
    }
}

export default MySelect;