import { certificadoigssConstants } from '../constants';

export function certificadoigss(state = {certificados: [], motivos: [], tipocertificados: [] ,loading:true}, action) {
  switch (action.type) {
    case certificadoigssConstants.FETCH_CERTIFICADOIGSS_REQUEST:
      return {
        loading: true,
        certificados: [],
        motivos: state.motivos,
        tipocertificados: state.tipocertificados
      };
    case certificadoigssConstants.FETCH_CERTIFICADOIGSS_SUCCESS:
      return {
        loading: false,
        certificados: action.data,
        motivos: state.motivos,
        tipocertificados: state.tipocertificados
      };
    case certificadoigssConstants.FETCH_CERTIFICADOIGSS_FAILURE:
      return { 
        error: action.error
      };
    case certificadoigssConstants.FETCH_MOTIVOS_REQUEST:
      return {
        loading: true,
        motivos: [],
        tipocertificados: state.tipocertificados,
        certificados: state.certificados
      };
    case certificadoigssConstants.FETCH_MOTIVOS_SUCCESS:
      return {
        loading: false,
        motivos: action.data,
        tipocertificados: state.tipocertificados,
        certificados: state.certificados
      };
    case certificadoigssConstants.FETCH_MOTIVOS_FAILURE:
      return { 
        error: action.error
      };
    case certificadoigssConstants.FETCH_CERTIFICADOS_REQUEST:
      return {
        loading: true,
        motivos: state.motivos,
        tipocertificados: [],
        certificados: state.certificados
      };
    case certificadoigssConstants.FETCH_CERTIFICADOS_SUCCESS:
      return {
        loading: false,
        tipocertificados: action.data,
        motivos: state.motivos,
        certificados: state.certificados
      };
    case certificadoigssConstants.FETCH_CERTIFICADOS_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}