import { perfilConstants } from '../constants';
import { perfilService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const perfilActions = {
    actualizar,
    getPerfil
};

function actualizar(formData) {
    return dispatch => {
        dispatch(request({ formData }));

        perfilService.actualizar(formData)
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.perfil));
                        history.push(`/perfil`);
                        dispatch(alertActions.success(response.mensaje));
                    }else{
                        dispatch(failure(response.mensaje));
                        dispatch(alertActions.error(response.mensaje));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(perfil) { return { type: perfilConstants.SAVE_PERFIL_REQUEST, perfil } }
    function success(perfil) { return { type: perfilConstants.SAVE_PERFIL_SUCCESS, perfil } }
    function failure(error) { return { type: perfilConstants.SAVE_PERFIL_FAILURE, error } }
}

function getPerfil() {
    return dispatch => {
        dispatch(request());

        perfilService.getPerfil()
            .then(
                response => {
                    if(response.status){
                        dispatch(success(response.data));
                    }else{
                        dispatch(failure(response.mensaje));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: perfilConstants.FETCH_PERFIL_REQUEST} }
    function success(data) { return { type: perfilConstants.FETCH_PERFIL_SUCCESS, data } }
    function failure(error) { return { type: perfilConstants.FETCH_PERFIL_FAILURE, error } }
}