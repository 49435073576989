import {apiUrl} from '../helpers'
export const actividadesService = {
    guardar,
    actualizar,
    getAll,
    getEncuestas,
    meInteresa,
    getComentarios,
    guardarComentario,
    getComentariosURL,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll(tipo) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/todas?';
    var params = {token: user.access_token,empleado_id: user.id, tipo};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getEncuestas() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/encuestas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getOne(id) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/desarrollohumano/v1/actividades/obtener/${id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}


function getComentarios(id,page,limit) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/desarrollohumano/v1/actividades/comentarios/${id}?page=${page}&limit=${limit}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getComentariosURL(url) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}


function guardarComentario(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.append('usuario_id',user.id);
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: formData
    };
    
    return fetch(USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/guardarComentario', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
    });
}


function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.token = user.access_token;
    formData.empleado_id = user.id;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    formData.id = id;
    formData.token = user.access_token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
    };
    
    return fetch(USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function meInteresa(blog_id) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('blog_id',blog_id);
    Data.append('usuario_id',user.id);
    Data.append('asistio',0);
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/desarrollohumano/v1/actividades/meInteresa', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
    });
}