import React, { Component } from 'react'
import { seguromedicoActions } from '../../actions';
import { connect } from 'react-redux'
import './SeguroMedico'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';
import { seguromedicoService } from '../../services';

const LoginSchema = Yup.object().shape({
    tipo_id: Yup.string()
        .required("Campo requerido"),
    aseguradora_id: Yup.string()
        .required("Campo requerido")
});

class SeguroMedicoForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            seguro: {
                tipo_id: "",
                aseguradora_id: "",
                fecha_solicitud: "",
                archivo: "",
                observaciones: ""
            },
            archivos: {},
            archivo_seleccionado: "",
            prevAseguradora:""
        };

        const { dispatch } = this.props;
        dispatch(seguromedicoActions.getAseguradoras());
        dispatch(seguromedicoActions.getTipos());
        seguromedicoService.getArchivos().then(

            response => {
                if(response.status){
                    this.setState({...this.state, archivos: response.archivos});
                }
            },
            error => console.error(error)
        );
        this.ifPresent = this.ifPresent.bind(this);
    }

    ifPresent(value, input){
        let error;
        if(value === "" && (input !== undefined || input !== "")){
            error = "Campo Requerido";
        }
        return error;
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/seguridad-corporativa/v1/seguromedico/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.data.tipo_id !== undefined){
                    for (const result of this.props.tipo_seguro) {
                        if(response.data.tipo_id === result.value){
                            response.data.tipo_id = result;
                        }
                    }
                }
                if(response.data.aseguradora_id !== undefined){
                    for (const result of this.props.aseguradoras) {
                        if(response.data.aseguradora_id === result.value){
                            response.data.aseguradora_id = result;
                        }
                    }
                }
                this.setState({seguro: response.data});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.seguro}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.tipo_id = values.tipo_id.value;
                    values.aseguradora_id = values.aseguradora_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(seguromedicoActions.guardar(values));
                    }else{
                        dispatch(seguromedicoActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tipo_id">Tipo de Seguro</label>

                                <MySelect
                                    value={values.tipo_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.tipo_id}
                                    touched={touched.tipo_id}
                                    field={'tipo_id'}
                                    options={this.props.tipo_seguro}
                                />
                                <ErrorMessage component="div" name="tipo_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="aseguradora_id">Aseguradoras</label>

                                <MySelect
                                    value={values.aseguradora_id||''}
                                    onChange={(field, value)=>{
                                        const newCadena = value.value;
                                        const shoulReset = newCadena !== this.state.prevAseguradora;
                                        this.setState({ prevAseguradora: newCadena }, () => {
                                            setFieldValue(field, value);
                                            if (shoulReset) {
                                                let archivoSeleccionado = this.state.archivos.filter((val, index)=>{ return val.tipo === value.label; });
                                                if(archivoSeleccionado[0].archivo !== undefined){
                                                    this.setState({archivo_seleccionado: archivoSeleccionado[0].archivo})
                                                }else{
                                                    this.setState({archivo_seleccionado: ""})
                                                }
                                            }
                                        });
                                    }}
                                    onBlur={setFieldTouched}
                                    error={errors.aseguradora_id}
                                    touched={touched.aseguradora_id}
                                    field={'aseguradora_id'}
                                    options={this.props.aseguradoras}
                                    validate={(value) => this.ifPresent(value, values.tipo_id)}
                                />
                                {
                                    this.state.archivo_seleccionado !== "" &&
                                    <div><a href={apiUrl+`storage/uploads/files/${this.state.archivo_seleccionado}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo para subir</a></div>
                                }
                                {
                                    this.state.seguro.archivo !== "" &&
                                    <a href={apiUrl+`storage/uploads/files/${this.state.seguro.archivo}`} target="_blank" download="download" rel="noopener noreferrer">Ver archivo subido</a>
                                }
                                <ErrorMessage component="div" name="aseguradora_id" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="archivo">Archivo </label>
                                <input type="file"  accept="image/*" capture="user" id="archivo" name="archivo" onChange={(event) => { setFieldValue("archivo", event.currentTarget.files[0]);}} className={`form-control ${touched.archivo && errors.archivo ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="archivo" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, seguromedico } = state;
    return {
        alert,
        tipo_seguro: seguromedico.tipo_seguro,
        aseguradoras: seguromedico.aseguradoras,
    };
}


export default connect(mapStateToProps)(SeguroMedicoForm);