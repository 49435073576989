import { seguromedicoConstants } from '../constants';

export function seguromedico(state = {seguros: [], tipo_seguro: [], aseguradoras: [], loading:true}, action) {
  switch (action.type) {
    case seguromedicoConstants.FETCH_SEGUROMEDICO_REQUEST:
      return {
        loading: true,
        seguros: [],
        tipo_seguro: state.tipo_seguro,
        aseguradoras: state.aseguradoras,
        colores: state.colores
      };
    case seguromedicoConstants.FETCH_SEGUROMEDICO_SUCCESS:
      return {
        loading: false,
        seguros: action.data,
        tipo_seguro: state.tipo_seguro,
        aseguradoras: state.aseguradoras,
        colores: state.colores
      };
    case seguromedicoConstants.FETCH_SEGUROMEDICO_FAILURE:
      return { 
        error: action.error
      };

    case seguromedicoConstants.FETCH_TIPOSEGURO_REQUEST:
    return {
        loading: true,
        tipo_seguro: [],
        seguros: state.seguros,
        aseguradoras: state.aseguradoras,
        colores: state.colores
    };
    case seguromedicoConstants.FETCH_TIPOSEGURO_SUCCESS:
    return {
        loading: false,
        tipo_seguro: action.data,
        seguros: state.seguros,
        aseguradoras: state.aseguradoras,
        colores: state.colores
    };
    case seguromedicoConstants.FETCH_TIPOSEGURO_FAILURE:
    return { 
        error: action.error
    };

    case seguromedicoConstants.FETCH_ASEGURADORA_REQUEST:
    return {
        loading: true,
        tipo_seguro: state.tipo_seguro,
        seguros: state.seguros,
        aseguradoras: [],
        colores: state.colores
    };
    case seguromedicoConstants.FETCH_ASEGURADORA_SUCCESS:
    return {
        loading: false,
        tipo_seguro: state.tipo_seguro,
        seguros: state.seguros,
        aseguradoras: action.data,
        colores: state.colores
    };
    case seguromedicoConstants.FETCH_ASEGURADORA_FAILURE:
    return { 
        error: action.error
    };

    default:
      return state
  }
}