// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { actividadesActions } from '../../actions'
import { connect } from 'react-redux'

class TableRow extends Component {
  constructor(props){
    super(props);
    
    this.meInteresa = this.meInteresa.bind(this);
  }
  meInteresa(actividad){
    const { dispatch } = this.props;
    dispatch(actividadesActions.meInteresa(actividad));
  }
  render() {
    return (
        <tr>
          <td>
            {this.props.data.id}
          </td>
          <td>
            {this.props.data.nombre}
          </td>
          <td>
            {this.props.data.fecha}
          </td>
          <td>
            <div className="row">
              <div className="col-md-6">
                <Link to={{pathname:`/desarrollohumano/actividades/ver/${this.props.data.id}`, state:{id:this.props.data.id}}} id={this.props.data.id} className="btn btn-primary">Ver</Link>
              </div>
              <div className="col-md-6">
                { this.props.data.interes === 0 && this.props.data.aplicable === 1 && <button onClick={()=>this.meInteresa(this.props.data.id)} className="btn btn-success" type="button">Me Interesa</button> }
              </div>
            </div>
          </td>
        </tr>
    );
  }
}


function mapStateToProps(state) {
  const { alert, vacaciones } = state;
  return {
      alert,
      motivos: vacaciones.motivos
  };
}
export default connect(mapStateToProps)(TableRow);