export const competenciaConstants = {
    SAVE_COMPETENCIA_REQUEST: 'SAVE_COMPETENCIA_REQUEST',
    SAVE_COMPETENCIA_SUCCESS: 'SAVE_COMPETENCIA_SUCCESS',
    SAVE_COMPETENCIA_FAILURE: 'SAVE_COMPETENCIA_FAILURE',

    FETCH_COMPETENCIA_REQUEST: 'FETCH_COMPETENCIA_REQUEST',
    FETCH_COMPETENCIA_SUCCESS: 'FETCH_COMPETENCIA_SUCCESS',
    FETCH_COMPETENCIA_FAILURE: 'FETCH_COMPETENCIA_FAILURE',


    SET_COMPETENCIA_REQUEST: 'SET_COMPETENCIA_REQUEST',
    SET_COMPETENCIA_SUCCESS: 'SET_COMPETENCIA_SUCCESS',
    SET_COMPETENCIA_FAILURE: 'SET_COMPETENCIA_FAILURE',

    GET_COMPETENCIA_REQUEST: 'GET_COMPETENCIA_REQUEST',
    GET_COMPETENCIA_SUCCESS: 'GET_COMPETENCIA_SUCCESS',
    GET_COMPETENCIA_FAILURE: 'GET_COMPETENCIA_FAILURE',

};
