// TableRow.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { beneficiosActions } from '../../../actions'
import { connect } from 'react-redux'

class TableRow extends Component {
  constructor(props){
    super(props);
    
    this.meInteresa = this.meInteresa.bind(this);
    this.noMeInteresa = this.noMeInteresa.bind(this);
  }
  meInteresa(actividad){
    const { dispatch } = this.props;
    dispatch(beneficiosActions.aplicar(actividad));
  }
  noMeInteresa(actividad){
    const { dispatch } = this.props;
    dispatch(beneficiosActions.noAplicar(actividad));
  }
  render() {
    return (
        <tr>
          <td>
            {this.props.data.id}
          </td>
          <td>
            {this.props.data.nombre}
          </td>
          <td>
            {this.props.data.fecha_inicio}
          </td>
          <td>
            {this.props.data.fecha_fin}
          </td>
          <td>
            <div className="row">
              <div className="col-md-6 col-12">
                <Link to={{pathname:"/recursoshumanos/beneficios/ver", state:{id:this.props.data.id}}} id={this.props.data.id} className="btn btn-primary">Ver</Link>
              </div>
                { this.props.data.aplicable && this.props.data.aplico === 0 && 
              <div className="col-md-6 col-12"><button onClick={()=>this.meInteresa(this.props.data.id)} className="btn btn-success" type="button">Me interesa</button></div> }
                { this.props.data.aplicable && this.props.data.aplico > 0 && 
              <div className="col-md-6 col-12"> <button onClick={()=>this.noMeInteresa(this.props.data.id)} className="btn btn-danger" type="button">Ya no me interesa</button> </div> }
            </div>
          </td>
        </tr>
    );
  }
}


function mapStateToProps(state) {
  const { alert, vacaciones } = state;
  return {
      alert,
      motivos: vacaciones.motivos
  };
}
export default connect(mapStateToProps)(TableRow);