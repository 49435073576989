import React, { Component } from 'react'
import { solicituduniformeActions } from '../../actions';
import { connect } from 'react-redux'
import './SolicitudUniforme'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    talla_camisa_id: Yup.string()
        .nullable("Campo requerido"),
    talla_pantalon_id: Yup.string()
        .nullable("Campo requerido")
});

class SolicitudUniformeForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            uniforme: {
                talla_camisa_id: "",
                color_camisa_id: "",
                talla_pantalon_id: "",
                color_pantalon_id: "",
                observaciones: "",
                cantidad_camisas: 0,
                cantidad_pantalones: 0,
                observaciones_camisas: "",
                observaciones_pantalones: "",

            }
        };

        const { dispatch } = this.props;
        dispatch(solicituduniformeActions.getTallasCamisas());
        dispatch(solicituduniformeActions.getTallasPantalones());
        dispatch(solicituduniformeActions.getColoresUniformes());
        this.ifPresent = this.ifPresent.bind(this);
    }

    ifPresent(value, input){
        let error;
        if(value === "" && (input !== undefined || input !== "")){
            error = "Campo Requerido";
        }
        return error;
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/seguridad-corporativa/v1/solicituduniforme/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.data.talla_camisa_id !== undefined){
                    for (const result of this.props.tallas_camisa) {
                        if(response.data.talla_camisa_id === result.value){
                            response.data.talla_camisa_id = result;
                        }
                    }
                }
                if(response.data.color_camisa_id !== undefined){
                    for (const result of this.props.colores) {
                        if(response.data.color_camisa_id === result.value){
                            response.data.color_camisa_id = result;
                        }
                    }
                }
                if(response.data.talla_pantalon_id !== undefined){
                    for (const result of this.props.tallas_pantalon) {
                        if(response.data.talla_pantalon_id === result.value){
                            response.data.talla_pantalon_id = result;
                        }
                    }
                }
                if(response.data.color_pantalon_id !== undefined){
                    for (const result of this.props.colores) {
                        if(response.data.color_pantalon_id === result.value){
                            response.data.color_pantalon_id = result;
                        }
                    }
                }
                this.setState({uniforme: response.data});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.uniforme}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.talla_camisa_id = values.talla_camisa_id.value;
                    values.color_camisa_id = values.color_camisa_id.value;
                    values.talla_pantalon_id = values.talla_pantalon_id.value;
                    values.color_pantalon_id = values.color_pantalon_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(solicituduniformeActions.guardar(values));
                    }else{
                        dispatch(solicituduniformeActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="talla_camisa_id">Talla de Camisa</label>

                                <MySelect
                                    value={values.talla_camisa_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.talla_camisa_id}
                                    touched={touched.talla_camisa_id}
                                    field={'talla_camisa_id'}
                                    options={this.props.tallas_camisa}
                                />
                                <ErrorMessage component="div" name="talla_camisa_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="color_camisa_id">Color de Camisa</label>

                                <MySelect
                                    value={values.color_camisa_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.color_camisa_id}
                                    touched={touched.color_camisa_id}
                                    field={'color_camisa_id'}
                                    options={this.props.colores}
                                    validate={(value) => this.ifPresent(value, values.talla_camisa_id)}
                                />
                                <ErrorMessage component="div" name="color_camisa_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="cantidad_camisas">Cantidad</label>
                                <Field type="number" min="0" name="cantidad_camisas" value={values.cantidad_camisas || ''} className={`form-control ${touched.cantidad_camisas && errors.cantidad_camisas ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad_camisas" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="observaciones_camisas">Observaciones Camisas</label>
                                <Field type="text" name="observaciones_camisas" value={values.observaciones_camisas || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_camisas && errors.observaciones_camisas ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_camisas" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="talla_pantalon_id">Talla de Pantalon</label>

                                <MySelect
                                    value={values.talla_pantalon_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.talla_pantalon_id}
                                    touched={touched.talla_pantalon_id}
                                    field={'talla_pantalon_id'}
                                    options={this.props.tallas_pantalon}
                                />
                                <ErrorMessage component="div" name="talla_pantalon_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="color_pantalon_id">Color de Pantalon</label>

                                <MySelect
                                    value={values.color_pantalon_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.color_pantalon_id}
                                    touched={touched.color_pantalon_id}
                                    field={'color_pantalon_id'}
                                    options={this.props.colores}
                                />
                                <ErrorMessage component="div" name="color_pantalon_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="cantidad_pantalones">Cantidad</label>
                                <Field type="number" min="0" name="cantidad_pantalones" value={values.cantidad_pantalones || ''} className={`form-control ${touched.cantidad_pantalones && errors.cantidad_pantalones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad_pantalones" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group">
                                <label htmlFor="observaciones_pantalones">Observaciones Pantalon</label>
                                <Field type="text" name="observaciones_pantalones" value={values.observaciones_pantalones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_pantalones && errors.observaciones_pantalones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_pantalones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, solicituduniforme } = state;
    return {
        alert,
        tallas_camisa: solicituduniforme.tallas_camisa,
        tallas_pantalon: solicituduniforme.tallas_pantalon,
        colores: solicituduniforme.colores
    };
}


export default connect(mapStateToProps)(SolicitudUniformeForm);