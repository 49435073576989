export const reposicionherramientaConstants = {
    SAVE_REPOSICIONHERRAMIENTA_REQUEST: 'SAVE_REPOSICIONHERRAMIENTA_REQUEST',
    SAVE_REPOSICIONHERRAMIENTA_SUCCESS: 'SAVE_REPOSICIONHERRAMIENTA_SUCCESS',
    SAVE_REPOSICIONHERRAMIENTA_FAILURE: 'SAVE_REPOSICIONHERRAMIENTA_FAILURE',

    FETCH_REPOSICIONHERRAMIENTA_REQUEST: 'FETCH_REPOSICIONHERRAMIENTA_REQUEST',
    FETCH_REPOSICIONHERRAMIENTA_SUCCESS: 'FETCH_REPOSICIONHERRAMIENTA_SUCCESS',
    FETCH_REPOSICIONHERRAMIENTA_FAILURE: 'FETCH_REPOSICIONHERRAMIENTA_FAILURE',

    FETCH_TIPOHERRAMIENTA_REQUEST: 'FETCH_TIPOHERRAMIENTA_REQUEST',
    FETCH_TIPOHERRAMIENTA_SUCCESS: 'FETCH_TIPOHERRAMIENTA_SUCCESS',
    FETCH_TIPOHERRAMIENTA_FAILURE: 'FETCH_TIPOHERRAMIENTA_FAILURE',

    FETCH_MOTIVO_REQUEST: 'FETCH_MOTIVO_REQUEST',
    FETCH_MOTIVO_SUCCESS: 'FETCH_MOTIVO_SUCCESS',
    FETCH_MOTIVO_FAILURE: 'FETCH_MOTIVO_FAILURE',

    GET_REPOSICIONHERRAMIENTA_REQUEST: 'GET_REPOSICIONHERRAMIENTA_REQUEST',
    GET_REPOSICIONHERRAMIENTA_SUCCESS: 'GET_REPOSICIONHERRAMIENTA_SUCCESS',
    GET_REPOSICIONHERRAMIENTA_FAILURE: 'GET_REPOSICIONHERRAMIENTA_FAILURE',

};
