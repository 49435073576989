import React, { Component } from 'react'
import { liquidacionesActions } from '../../actions';
import { connect } from 'react-redux'
import './Liquidaciones.css'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import TableGastoRow from './TableGastoRow';
import MySelect from '../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    fecha: Yup.date()
        .required("Campo requerido"),
    proveedor_id: Yup.string()
        .nullable("Campo requerido"),
    no_factura: Yup.string()
        .required("Campo requerido"),
    serie_factura: Yup.string()
        .required("Campo requerido"),
    concepto: Yup.string()
        .required("Campo requerido"),
    monto: Yup.number()
        .required('Campo requerido')
});
//api/liquidaciones/guardar

class GastosForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            gasto: {
                fecha: "", proveedor_id: "", no_factura: "",serie_factura:"", concepto: "", monto:0, galones: 0, gasolina: "", impuesto: 0, idp: 0,tipo_cambio: 0, fotografia: "", moneda:""
            },
            gastos : [],
            proveedores: [],
            loading: true,
            conceptos: [],
            gasolina: [],
            isGasolina: false,
            impuesto: 0,
            monedas: [
                { label: 'Quetzales', value: 'Q' },
                { label: 'Dolares', value: '$' },
                { label: 'Euros', value: '€' },
            ],
            tipoMoneda: 'Q',
            editMode: false,
        };
        const { dispatch } = this.props;
        
        dispatch(liquidacionesActions.getConceptos());
        dispatch(liquidacionesActions.getTipoGasolina());
        if(this.props.id){
            dispatch(liquidacionesActions.getAllGastos(this.props.id));
        }
        this.ifPresent = this.ifPresent.bind(this);
    }

    tabRow(){
        if(this.props.id){
            if(this.props.gastos instanceof Array){
                return this.props.gastos.map(function(object, i){
                    return <TableGastoRow gasto={object} key={i} />;
                })
            }
        }
    }

    ifPresent(value, input){
        let error;
        if(value === "" && (input !== undefined || input !== "")){
            error = "Campo Requerido";
        }
        return error;
    }

    validateOpt(value, option,selected){
        let error;
        if(option.label === selected){
            this.setState({isGasolina: true});
            error = 'Requerido';
        }else{
            this.setState({isGasolina: false});
        }
        return error;
    }
    
    render(){
        return (
            <div className="row">
                <div className="col-md-12">
                    <Formik
                        initialValues={this.state.gasto}
                        enableReinitialize={true}
                        validationSchema={LoginSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            if(values.proveedor_id.value !== undefined){
                                values.proveedor_id = values.proveedor_id.value;
                            }
                            values.gasolina = (values.gasolina.label !== undefined)?values.gasolina.label:null;
                            values.concepto = (values.concepto.label !== undefined)?values.concepto.label:null;
                            values.moneda = (values.moneda.value !== undefined)?values.moneda.value:null;
                            let concepto = this.props.conceptos.filter((val)=> val.label === values.concepto);
                            if(concepto[0].childs !== undefined){
                                let categoria = concepto[0].childs.filter((val,index) => { return val.label});
                                if(categoria[0].label !== undefined){
                                    values.categoria = categoria[0].label;
                                }
                            }
                            setSubmitting(true);
                            const { dispatch } = this.props;
                            dispatch(liquidacionesActions.guardarGasto(values,this.props.id));
                            setSubmitting(false);
                            resetForm({});
                            this.getGastos();
                        }}
                    >{({ setFieldValue, values, touched, errors, isSubmitting, setFieldTouched }) => 
                        {

                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="moneda">Moneda</label>
                                                <MySelect
                                                    field={'moneda'}
                                                    value={values.moneda}
                                                    onChange={(field, value)=>{
                                                        const newCadena = value.value;
                                                        this.setState({ tipoMoneda: newCadena }, () => {
                                                            setFieldValue(field, value);
                                                        });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    error={errors.moneda}
                                                    touched={touched.moneda}
                                                    options={this.state.monedas}
                                                />
                                                <ErrorMessage component="div" name="moneda" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className={this.state.tipoMoneda === 'Q'?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
                                            <div className="form-group">
                                                <label htmlFor="tipo_cambio">Tipo de Cambio</label>
                                                <Field type="text" name="tipo_cambio" placeholder="" className={`form-control ${touched.tipo_cambio && errors.tipo_cambio ? "is-invalid" : "" }`} />
                                                <ErrorMessage component="div" name="tipo_cambio" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fecha">Fecha</label>
                                                <Field type="date" name="fecha" placeholder="dd/mm/yy" className={`form-control ${touched.fecha && errors.fecha ? "is-invalid" : ""}`} />
                                                <ErrorMessage component="div" name="fecha" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className={this.state.tipoMoneda !== 'Q'?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
                                            <div className="form-group">
                                                <label htmlFor="proveedor_id">Proveedor</label>
        
                                                <MySelect
                                                    field={'proveedor_id'}
                                                    value={values.proveedor_id}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    error={errors.proveedor_id}
                                                    touched={touched.proveedor_id}
                                                    options={this.props.proveedores}
                                                    placeholder="ej: 123456-7"
                                                />
                                                <ErrorMessage component="div" name="proveedor_id" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="no_factura">No. Factura</label>
                                                <Field type="text" name="no_factura" placeholder="No. Factura" className={`form-control ${touched.no_factura && errors.no_factura ? "is-invalid" : "" }`} />
                                                <ErrorMessage component="div" name="no_factura" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="serie_factura">Serie de la Factura</label>
                                                <Field type="text" name="serie_factura" placeholder="Serie" className={`form-control ${touched.serie_factura && errors.serie_factura ? "is-invalid" : "" }`} />
                                                <ErrorMessage component="div" name="serie_factura" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
        
                                            <div className="form-group">
                                                <label htmlFor="concepto">Concepto</label>
                                                <MySelect
                                                    value={values.concepto||''}
                                                    onChange={(field, value)=>{
                                                        const newCadena = value.value;
                                                        const shoulReset = newCadena !== this.state.prevCategoria;
                                                        this.setState({ prevCategoria: newCadena }, () => {
                                                            setFieldValue(field, value);
                                                            if (shoulReset && value.label === 'Gasolina') {
                                                                this.setState({isGasolina: true});
                                                            }else{
                                                                this.setState({isGasolina: false});
                                                                this.setState({impuesto: 0});
                                                            }
                                                        });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    error={errors.concepto}
                                                    touched={touched.concepto}
                                                    field={'concepto'}
                                                    options={this.props.conceptos}
                                                    validate={(value) => this.validateOpt(value,values.concepto, "Gasolina")}
                                                />
                                                <ErrorMessage component="div" name="concepto" className="invalid-feedback" />
                                            </div>
                                        </div>
        
                                        <div className={!this.state.isGasolina?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
        
                                            <div className="form-group">
                                                <label htmlFor="gasolina">Tipo de Gasolina</label>
                                                <MySelect
                                                    value={values.gasolina||''}
                                                    onChange={(field, value)=>{
                                                        const newCadena = value.value;
                                                        const shoulReset = newCadena !== this.state.prevCategoria;
                                                        this.setState({ prevCategoria: newCadena }, () => {
                                                            setFieldValue(field, value);
                                                            if (shoulReset && value.label === 'Regular') {
                                                                setFieldValue('impuesto',4.6);
                                                            }else if(shoulReset && value.label === 'Super'){
                                                                setFieldValue('impuesto',4.7);
                                                            }else{
                                                                setFieldValue('impuesto',1.3);
                                                            }
                                                        });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    error={errors.gasolina}
                                                    touched={touched.gasolina}
                                                    field={'gasolina'}
                                                    options={this.props.tipo_gasolina}
                                                />
                                                <ErrorMessage component="div" name="gasolina" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className={!this.state.isGasolina?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
                                            <div className="form-group">
                                                <label htmlFor="galones">Galones</label>
                                                <Field type="text" name="galones" placeholder="Concepto de Gasto" onChange={(field,value) => {
                                                    setFieldValue(field.target.name,field.target.value);
                                                    setFieldValue('idp',field.target.value * values.impuesto);
                                                }} value={values.galones} className={`form-control ${touched.galones && errors.galones ? "is-invalid" : "" }`} />
                                                <ErrorMessage component="div" name="galones" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className={!this.state.isGasolina?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
                                            <div className="form-group">
                                                <label htmlFor="galones">Impuesto</label>
                                                {/* <input type="hidden" name="impuesto" value={setFieldValue('impuesto',this.state.impuesto)}/> */}
                                                <p>{values.impuesto}</p>
                                                <ErrorMessage component="div" name="galones" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className={!this.state.isGasolina?'col-12 col-md-3 d-none':'col-12 col-md-3'}>
                                            <div className="form-group">
                                                <label htmlFor="galones">IDP</label>
                                                {/* <input type="hidden" name="idp" value={setFieldValue('idp',values.impuesto * values.galones)}/> */}
                                                <p>{values.idp}</p>
                                                <ErrorMessage component="div" name="galones" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="monto">Monto</label>
                                                <Field type="number" name="monto" className={`form-control ${touched.monto && errors.monto ? "is-invalid" : "" }`} />
                                                <ErrorMessage component="div" name="monto" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="fotografia">Fotografia </label>
                                                <input type="file" accept="image/*,application/pdf" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                                {this.state.gasto.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }
                    }
                    </Formik>
                </div>
                <div className="col-md-12">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>Fecha</td>
                            <td>Proveedor</td>
                            <td>No. Factura</td>
                            <td>Serie de la Factura</td>
                            <td>Concepto</td>
                            <td>Monto</td>
                        </tr>
                        </thead>
                        <tbody>
                            {this.tabRow()}
                        </tbody>
                    </table>
                    {this.props.loading &&
                        <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { proveedores } = state.data;
    const { users } = state;
    return {
        alert,
        proveedores,
        conceptos: users.conceptos,
        tipo_gasolina: users.tipo_gasolina,
        gastos: users.gastos,
        loading: users.loading,
    };
}


export default connect(mapStateToProps)(GastosForm);