import {apiUrl} from '../helpers'
export const perfilService = {
    actualizar,
    getPerfil
};

const USER_API_BASE_URL = apiUrl;


function getPerfil() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json'},
        headers: {'Authorization': 'Bearer '+user.access_token},
    };
    var url = USER_API_BASE_URL + `api/empleados/v1/perfil/obtener/${user.id}`;
    return fetch(url, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
} 

function actualizar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    // formData.id = user.id;
    // formData.token = user.access_token;
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json'},
    //     body: JSON.stringify(formData)
    // };
    
    let Data = new FormData();
    Data.append('id',user.id);
    Data.append('telefono',formData.telefono);
    Data.append('direccion_residencial',formData.direccion_residencial);
    if(formData.estado_civil !== undefined && formData.estado_civil !== null){
        Data.append('estado_civil',formData.estado_civil);
    }
    if(formData.hijos){
        Data.append('hijos',formData.hijos);
    }
    if(formData.contacto_emergencia){
        Data.append('contacto_emergencia',formData.contacto_emergencia);
    }
    if(formData.telefono_emergencia){
        Data.append('telefono_emergencia',formData.telefono_emergencia);
    }
    if(formData.parentesco){
        Data.append('parentesco',formData.parentesco);
    }
    if(formData.numero_personas_reside !== undefined && formData.numero_personas_reside !== null){
        Data.append('numero_personas_reside',formData.numero_personas_reside);
    }
    if(formData.nivel_academico !== undefined && formData.nivel_academico !== null){
        Data.append('nivel_academico',formData.nivel_academico);
    }
    if(formData.parentesco !== undefined && formData.parentesco !== null){
        Data.append('parentesco',formData.parentesco);
    }
    if(formData.enfermedad_cronica !== undefined && formData.enfermedad_cronica !== null){
        Data.append('enfermedad_cronica',formData.enfermedad_cronica);
    }
    if(formData.estatura !== undefined && formData.estatura !== null){
        Data.append('estatura',formData.estatura);
    }
    if(formData.peso !== undefined && formData.peso !== null){
        Data.append('peso',formData.peso);
    }
    if(formData.telefono2 !== undefined && formData.telefono2 !== null){
        Data.append('telefono2',formData.telefono2);
    }
    if(formData.telefono3 !== undefined && formData.telefono3 !== null){
        Data.append('telefono3',formData.telefono3);
    }
    if(formData.fecha_matrimonio !== undefined && formData.fecha_matrimonio !== null){
        Data.append('fecha_matrimonio',formData.fecha_matrimonio);
    }
    if(formData.fecha_vencimiento_pasaporte !== undefined && formData.fecha_vencimiento_pasaporte !== null){
        Data.append('fecha_vencimiento_pasaporte',formData.fecha_vencimiento_pasaporte);
    }
    Data.append('fecha_vencimiento_dpi',formData.fecha_vencimiento_dpi);

    if(formData.fecha_vencimiento_visa_americana !== undefined && formData.fecha_vencimiento_visa_americana !== null){
        Data.append('fecha_vencimiento_visa_americana',formData.fecha_vencimiento_visa_americana);
    }
    if(formData.fecha_vencimiento_irtra !== undefined && formData.fecha_vencimiento_irtra !== null){
        Data.append('fecha_vencimiento_irtra',formData.fecha_vencimiento_irtra);
    }
    if(formData.fecha_vencimiento_carro !== undefined && formData.fecha_vencimiento_carro !== null){
        Data.append('fecha_vencimiento_carro',formData.fecha_vencimiento_carro);
    }
    if(formData.fecha_vencimiento_moto !== undefined && formData.fecha_vencimiento_moto !== null){
        Data.append('fecha_vencimiento_moto',formData.fecha_vencimiento_moto);
    }
    if(formData.pasaporte !== undefined && formData.pasaporte !== null){
        Data.append('pasaporte',formData.pasaporte);
    }
    if(formData.irtra !== undefined && formData.irtra !== null){
        Data.append('irtra',formData.irtra);
    }
    if(formData.licencia_carro !== undefined && formData.licencia_carro !== null){
        Data.append('licencia_carro',formData.licencia_carro);
    }
    if(formData.licencia_moto !== undefined && formData.licencia_moto !== null){
        Data.append('licencia_moto',formData.licencia_moto);
    }
    

    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    return fetch(USER_API_BASE_URL + 'api/empleados/v1/perfil/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            if(response.status){
                user.nombre = response.data.nombre;
                user.apellido = response.data.apellido;
                localStorage.setItem('user', JSON.stringify(user));
            }
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
    
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
