import { promocional_materialConstants } from '../constants';

export function promocional_material(state = {promocionales: [] ,loading:false}, action) {
  switch (action.type) {
    case promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_REQUEST:
      return {
        loading: true,
        promocionales: []
      };
    case promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_SUCCESS:
      return {
        loading: false,
        promocionales: action.data
      };
    case promocional_materialConstants.FETCH_PROMOCIONAL_MATERIAL_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}