import React, { Component } from 'react'
import { promocional_materialActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';
import { cadenasService, dropdownsService, promocional_materialService } from '../../../services';
import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),   
    marca: Yup.string()
        .required("Campo requerido"), 
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    item: Yup.string()
        .required("Campo requerido"),
    producto: Yup.string()
        .required("Campo requerido"),
    tamano: Yup.string()
        .required("Campo requerido"),
});

const emptyOption = { value: "", label: "" };
class PromocionalMaterialForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            promocional_material: {
                cadena: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                promocional: "",
                material: "",
                material: "",
                puesto: "",
                requerimiento: "",
                cantidad: 0,
                // cod_producto: "",
                // modelo: "",
                // producto: "",
                fotografia: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
        };
        // this.getTiendas = this.getTiendas.bind(this);
        // this.getSubcategorias = this.getSubcategorias.bind(this);
    }
    
    componentDidMount(){  
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ 
                    promocional_material: {
                        ...this.state.promocional_material,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }
        if(this.props.id){
            let promocional_material_temp = this.state.promocional_material;
            promocional_materialService.getOne(this.props.id).then(
                response => {
                    promocional_material_temp = response.data;
                    console.log(promocional_material_temp);
                }
            ).finally(()=>{
                    this.setState({promocional_material: promocional_material_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.promocional_material}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(promocional_materialActions.guardar(values));
                    }else{
                        dispatch(promocional_materialActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                    <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaño</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="promocional">Promocional</label>
                                <Field type="text" name="promocional" value={values.promocional || ''} placeholder="Ingrese la Promocional" className={`form-control ${touched.promocional && errors.promocional ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="promocional" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="material">Material</label>
                                <Field type="text" name="material" value={values.material || ''} placeholder="Ingrese el Material" className={`form-control ${touched.material && errors.material ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="material" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="puesto">Puesto</label>
                                <Field type="text" name="puesto" value={values.puesto || ''} placeholder="Ingrese el Puesto" className={`form-control ${touched.puesto && errors.puesto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="puesto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="requerimiento">Requerimiento</label>
                                <Field type="text" name="requerimiento" value={values.requerimiento || ''} placeholder="Ingrese el Requerimiento" className={`form-control ${touched.requerimiento && errors.requerimiento ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="requerimiento" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="cantidad">Cantidad</label>
                                <Field type="number" min="0" name="cantidad" value={values.cantidad || ''} placeholder="0" className={`form-control ${touched.cantidad && errors.cantidad ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cantidad" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file" id="fotografia"  accept="image/*" capture="user" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(PromocionalMaterialForm);