import React, { Component } from 'react'
import { liquidacionesActions, proveedoresActions } from '../../actions';
import { connect } from 'react-redux'
import './Liquidaciones.css'
import {apiUrl} from '../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const LoginSchema = Yup.object().shape({
    fecha_inicio: Yup.date()
        .required("Campo requerido"),
    fecha_fin: Yup.date()
        .required("Campo requerido"),
    concepto: Yup.string()
        .min(3,"Minimo 3 Caracteres")
        .required("Campo requerido"),
    adelanto_recibido: Yup.boolean()
        .required('Campo requerido'),
    adelanto: Yup.number()
        .when('adelanto_recibido',{
            is: true,
            then: Yup.number().required("Campo requerido").moreThan(0,"Debe ser mayor a 0")
        }),
    acepta_enviado: Yup.boolean()
        .required("Debe Aceptar que la información proporcionada es la correcta")
        .oneOf([true], 'Debe Aceptar que la información proporcionada es la correcta')
});
//api/liquidaciones/guardar

class LiquidacionesForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            liquidacion: {
                fecha_inicio: "", fecha_fin: "", concepto: "",adelanto_recibido:"", adelanto: 0, acepta_enviado:false
            }
        };

        const { dispatch } = this.props;
        dispatch(proveedoresActions.getAll());
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/finanzas/v1/liquidaciones/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                this.setState({liquidacion: response.liquidacion});
            });
        }
    }
    render(){
        const user = JSON.parse(localStorage.getItem('user'));
        return (
            <Formik
                initialValues={this.state.liquidacion}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(liquidacionesActions.guardar(values));
                    }else{
                        dispatch(liquidacionesActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <label htmlFor="nombre">Nombre</label>
                            <div className="form-group">
                            <p>{user.nombre?user.nombre:''} {user.apellido?user.nombre:''}</p> 
                                <input type="hidden" name="empleado_id" value={user.id?user.id:''}/>
                                <input type="hidden" name="token" value={user.access_token?user.access_token:''}/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fecha_inicio">Fecha Inicio</label>
                                <Field type="date" name="fecha_inicio" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_inicio && errors.fecha_inicio ? "is-invalid" : ""}`} />
                                <ErrorMessage component="div" name="fecha_inicio" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fecha_fin">Fecha Fin</label>
                                <Field type="date" name="fecha_fin" placeholder="dd/mm/yy" className={`form-control ${touched.fecha_fin && errors.fecha_fin ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="fecha_fin" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="concepto">Concepto</label>
                                <Field type="text" name="concepto" placeholder="Concepto de los Liquidaciones" className={`form-control ${touched.concepto && errors.concepto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="concepto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="adelanto_recibido">Adelanto de Efectivo Recibido (Autorizado)</label>
                                <div  className="form-group">
                                    <Field type="radio" name="adelanto_recibido" onChange={() => setFieldValue("adelanto_recibido", 1)} checked={values.adelanto_recibido === 1} value={1} id="frmchk1" className={`form-control no-style ${touched.adelanto_recibido && errors.adelanto_recibido ? "is-invalid" : "" }`} />  <label htmlFor="frmchk1">Si</label>
                                </div>
                                <div className="form-group">
                                    <Field type="radio" name="adelanto_recibido" onChange={() => setFieldValue("adelanto_recibido", 0)} checked={values.adelanto_recibido === 0} value={0} id="frmchk2" className={`form-control no-style ${touched.adelanto_recibido && errors.adelanto_recibido ? "is-invalid" : "" }`} />  <label htmlFor="frmchk2">No</label>
                                    <ErrorMessage component="div" name="adelanto_recibido" className="invalid-feedback" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="adelanto">Adelanto</label>
                                <Field type="number" name="adelanto" className={`form-control ${touched.adelanto && errors.adelanto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="adelanto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label>Acepta que todos los datos proporcionados son correctos</label>
                            <div className="form-group">
                                <Field type="checkbox" id="acepta_enviado" checked={values.acepta_enviado === true}  name="acepta_enviado" onChange={() => {setFieldValue("acepta_enviado", !values.acepta_enviado);}} value={true} className={`form-control no-style ${touched.acepta_enviado && errors.acepta_enviado ? "is-invalid" : "" }`} />  <label htmlFor="acepta_enviado">Acepto</label>
                                <ErrorMessage component="div" name="acepta_enviado" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { liquidacion } = state.users;
    return {
        alert,
        liquidacion 
    };
}


export default connect(mapStateToProps)(LiquidacionesForm);