import React, { useState, useEffect } from 'react'
import Sidebar from '../../layout/Sidebar'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import Alert from '../../components/alerts/Alert'
import CenteredTreeGraph from '../../components/centerTreeGraph/CenteredTreeGraph'
import { organigramaService } from '../../services'


function Organigrama(props){
    const [height, setHeight] = useState(0);
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    useEffect(() => {
        setHeight(window.innerHeight);
        if(!dataLoaded){
            organigramaService.organigrama().then(
                response => {
                    if(response.status){
                        if(Object.keys(response.data).length > 0){
                            setData(response.data);
                        }
                        setDataLoaded(true);
                    }
                }
            );
        }
    },[dataLoaded]);
    return (
        <div id="wrapper">
            <Sidebar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
                        <Alert />
                        <div className="d-sm-flex justify-content-between align-items-center mb-4">
                            <h3 className="text-dark mb-0">Organigrama</h3>
                        </div>
                        <div className="row">
                            <div id="treeWrapper" height={height} className="col-md-12" style={{'borderStyle': 'solid'}}>
                                <CenteredTreeGraph data={data}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <a className="border rounded d-inline scroll-to-top" href="#page-top"><i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
}

export {Organigrama};