import React, { Component } from 'react'
import { cartaingresosActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';

const LoginSchema = Yup.object().shape({
    tramite_id: Yup.string()
        .required("Campo requerido"),
    banco_id: Yup.string()
    .required("Campo requerido"),
});

class CartaIngresosForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            carta: {
                tramite_id: "",
                banco_id: ""
            },
            bancario: false,
            prevTramite: "",
            bancos: [],
        };

        const { dispatch } = this.props;
        dispatch(cartaingresosActions.getTramites());
        // dispatch(cartaingresosActions.getBancos());
    }

    componentDidMount(){  
        
        if(this.props.id){
            let user = JSON.parse(localStorage.getItem('user'));
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json'},
            };
            var url = apiUrl + `api/recursoshumanos/v1/cartaingresos/obtener/${this.props.id}?`;
            var params = {token: user.access_token};
            let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');

            return fetch(url+query, requestOptions).then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        if (response.status === 401) {
                            // auto logout if 401 response returned from api
                            return response.message;
                        }
                
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    return data;
                });
            })
            .then(response => {
                if(response.carta.tramite_id !== undefined){
                    for (const result of this.props.tramites) {
                        if(response.carta.tramite_id === result.value){
                            response.carta.tramite_id = result;
                        }
                    }
                }
                if(response.carta.banco_id !== undefined){
                    var newCadena = response.carta.tramite_id.value;
                    var tramite = this.props.tramites.filter(function(e){ return e.value === newCadena;});
                    if(tramite[0] !== undefined){
                        this.setState({bancos: tramite[0].childs})
                    }
                    for (const result of this.state.bancos) {
                        if(response.carta.banco_id === result.value){
                            response.carta.banco_id = result;
                        }
                    }
                }
                this.setState({carta: response.carta});
            });
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.carta}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    values.tramite_id = values.tramite_id.value;
                    values.banco_id = values.banco_id.value;
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(cartaingresosActions.guardar(values));
                    }else{
                        dispatch(cartaingresosActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tramite_id">Tramite</label>

                                <MySelect
                                    value={values.tramite_id||''}
                                    onChange={(field, value)=>{
                                        const newCadena = value.value;
                                        const shoulReset = newCadena !== this.state.prevTramite;
                                        this.setState({ prevTramite: newCadena }, () => {
                                            setFieldValue(field, value);
                                            var tramite = this.props.tramites.filter(function(e){ return e.value === newCadena;});
                                            if(tramite[0] !== undefined){
                                                this.setState({bancos: tramite[0].childs})
                                            }
                                            if (shoulReset) {
                                                setFieldValue('banco_id',{});
                                            }
                                        });
                                    }}
                                    onBlur={setFieldTouched}
                                    error={errors.tramite_id}
                                    touched={touched.tramite_id}
                                    field={'tramite_id'}
                                    options={this.props.tramites}
                                />
                                <ErrorMessage component="div" name="tramite_id" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tramite_id">Banco</label>

                                <MySelect
                                    value={values.banco_id||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.banco_id}
                                    touched={touched.banco_id}
                                    field={'banco_id'}
                                    options={this.state.bancos}
                                />
                                <ErrorMessage component="div" name="banco_id" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert, cartaingresos } = state;
    return {
        alert,
        tramites: cartaingresos.tramites,
        bancos: cartaingresos.bancos,
    };
}


export default connect(mapStateToProps)(CartaIngresosForm);