import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {components} from './compoments.reducer';
import {data} from './data.reducer';
import {viaticos} from './viaticos.reducer';
import {vacaciones} from './vacaciones.reducer';
import {cartaingresos} from './cartaingresos.reducer';
import {certificadoigss} from './certificadoigss.reducer';
import {solicitudirtra} from './solicitudirtra.reducer';
import {herramientanueva} from './herramientanueva.reducer';
import {solicituduniforme} from './solicituduniforme.reducer';
import {seguromedico} from './seguromedico.reducer';
import {reposicionherramienta} from './reposicionherramienta.reducer';
import {actividades} from './actividades.reducer';
import {descargas} from './descargas.reducer';
import { exhibiciones } from './exhibiciones.reducer';
import { monitoreos } from './monitoreos.reducer';
import { btl } from './btl.reducer';
import { competencia } from './competencia.reducer'
import { venta_promocion } from './venta_promocion.reducer'
import { promocional_material } from './promocional_material.reducer';
import { capacitacion } from './capacitacion.reducer';
import { perfil } from './perfil.reducer';
import {salarios} from './salario.reducer';
import {beneficios} from './beneficios.reducer';
import {encuestas} from './encuestas.reducer';
const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  data,
  viaticos,
  vacaciones,
  cartaingresos,
  certificadoigss,
  solicitudirtra,
  herramientanueva,
  solicituduniforme,
  seguromedico,
  reposicionherramienta,
  actividades,
  descargas,
  exhibiciones,
  monitoreos,
  btl,
  competencia,
  venta_promocion,
  promocional_material,
  capacitacion,
  perfil,
  salarios,
  beneficios,
  encuestas,
  components
});

export default rootReducer;